import { Checkbox } from "../checkbox/Checkbox";
import CustomDropDown from "../custom-drop-down/CustomDropDown";
import { Radio } from "../radio/Radio";

export const AnswerOptionsTypes = ({ data, type }) => {
  const { answerOptions, rejectedReason } = data ? data : {};
  const options = answerOptions?.map((_, index) => index + 1 + ".");
  const checked = answerOptions?.map((option) => option?.correctValue);
  const customDropDownContent = rejectedReason
    ? "custom-drop-down-reject-content "
    : "custom-drop-down-content ";

  switch (type) {
    case "RB":
      return (
        <Radio
          options={options}
          checked={checked}
          labelClassName={"question-option"}
          classes="mb-4"
          className="preview_radio_btn"
          isAnswerOptions={false}
          radioOptionClass="align-items-start"
          showNumber={true}
        />
      );
    case "CB":
      return (
        <div>
          <Checkbox
            options={options}
            checked={checked}
            className="mb-4 px-0 preview-checkbox"
            labelClassName={"question-option"}
            inputClass="preview_radio_btn"
            titleClass="d-none"
            onChangeHandler={() => {}}
            isAnswerOptions={false}
            showNumber={true}
          />
        </div>
      );
    case "DD":
      return (
        <div className="preview_modal_dropdown px-3 pb-2">
          <CustomDropDown
            placeholder="select"
            options={options}
            className={"preview_modal_custom_dropdown"}
            customDropDownContent={customDropDownContent}
            isAnswerOptions={false}
            showNumber={true}
            checkedOptions={checked}
          />
        </div>
      );
    case "MD":
      return (
        <div className="preview_modal_dropdown px-3 pb-2 w-100">
          <CustomDropDown
            placeholder="select"
            options={options}
            className={"preview_modal_custom_dropdown"}
            multiple={true}
            titleClass="d-none"
            customDropDownContent={customDropDownContent}
            isAnswerOptions={false}
            showNumber={true}
            checkedOptions={checked}
          />
        </div>
      );
    case "TB":
      return (
        <div className="mb-2 text-start preview_modal_input px-2">
          <div
            className="preview_modal_input_component"
            dangerouslySetInnerHTML={{
              __html: answerOptions ? answerOptions[0]?.optionName : "",
            }}
          />
        </div>
      );
    default:
      return null;
  }
};

export default AnswerOptionsTypes;
