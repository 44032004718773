import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { profileAction } from "./profile-actions";
import {
  deleteFirebaseToken,
  fetchLoggedInUserInfo,
  fetchPinSetDataApi,
  fireBaseTokenAfterLogin,
  getInstances,
  getNotificationListApi,
  getUserProfile,
  readAllNoficationApi,
  readSingleNoficationApi,
  updateUserProfile,
} from "redux/profile/profile-api";
import AppConstants from "utils/constants/app-constants";
import {
  getStorageItem,
  hasAddEditAccess,
  hasApproveAccess,
  hasListingAccess,
  hasReviewAccess,
  hasTranslationAccess,
  hasTranslationReviewAccess,
  saveItemToStorage,
} from "utils/utility";
import { ROUTES_CONSTANTS } from "routes/route-constants";
export const profilesAdapter = createEntityAdapter();

export const setFlyoutMode = createAction(profileAction.OPEN_CLOSE_FLYOUT_MODE);

export const setClearNotification = createAction(
  profileAction.CLEAR_NOTIFICATION
);

export const setNotificationData = createAction(
  profileAction.SET_NOTIFICATION_DATA
);

export const resetNotificationData = createAction(
  profileAction.RESET_NOTIFICATION_DATA
);

export const fetchUserProfile = createAsyncThunk(
  profileAction.GET_PROFILE,
  async (options) => {
    const response = await getUserProfile(options);
    return response;
  }
);

export const editUserProfile = createAsyncThunk(
  profileAction.UPDATE_PROFILE,
  async (options) => {
    const response = await updateUserProfile(options);
    return response;
  }
);

export const fetchLoggedInUserDetails = createAsyncThunk(
  profileAction.FETCH_LOGGED,
  async () => {
    const response = await fetchLoggedInUserInfo();
    return response;
  }
);

export const fetchAllInstances = createAsyncThunk(
  profileAction.FETCH_INSTANCES,
  async (options) => {
    const response = await getInstances(options);
    return response;
  }
);

export const fetchPinSetData = createAsyncThunk(
  profileAction.FETCH_PIN_SET,
  async (options) => {
    const response = await fetchPinSetDataApi(options);
    return response;
  }
);

export const sendFireBaseToken = createAsyncThunk(
  profileAction.SEND_FIREBASE_TOKEN,
  async (options) => {
    const response = await fireBaseTokenAfterLogin(options);
    return response;
  }
);

export const removeFireBaseToken = createAsyncThunk(
  profileAction.SEND_FIREBASE_TOKEN,
  async (options) => {
    const response = await deleteFirebaseToken(options);
    return response;
  }
);

export const readSingleNofication = createAsyncThunk(
  profileAction.UPDATE_NOTIFICATION,
  async (options) => {
    const response = await readSingleNoficationApi(options);
    return response;
  }
);

export const readAllNotification = createAsyncThunk(
  profileAction.UPDATE_ALL_NOTIFICATION,
  async (options) => {
    const response = await readAllNoficationApi(options);
    return response;
  }
);

export const fetchNotification = createAsyncThunk(
  profileAction.FETCH_NOTIFICATION,
  async (options) => {
    const response = await getNotificationListApi(options);
    return response;
  }
);

function getMenuItems(ele) {
  let initialData = ele;
  let menuItems = [];

  const moduleAccessList =
    initialData?.moduleAccess || initialData?.roleDetails || [];
  // let moduleAccessList = [];
  if (moduleAccessList) {
    moduleAccessList?.forEach((ele) => {
      // menuItems.push({
      //   _id: ele?.masterModuleId,
      //   name: ele?.name,
      //   description: ele?.description,
      //   path: ele?.path,
      //   access: ele?.access,
      // });

      saveItemToStorage("accessList", ele?.access);
    });

    // setTimeout(() => {
    if (hasListingAccess()) {
      menuItems.push({
        _id: 1,
        name: "Manage Questions",
        description: "Questions Listing",
        path: ROUTES_CONSTANTS.questionListing,
      });
    }

    if (hasAddEditAccess()) {
      menuItems.push({
        _id: 2,
        name: "Add Question",
        description: "Add Question",
        path: ROUTES_CONSTANTS.addQuestion,
      });
    }

    if (hasReviewAccess()) {
      menuItems.push({
        _id: 3,
        name: "Review Questions",
        description: "Questions for Review",
        path: ROUTES_CONSTANTS.reviewQuestions,
      });
    }

    if (hasTranslationAccess()) {
      menuItems.push({
        _id: 4,
        name: "Translate Questions",
        description: "Questions for Translation",
        path: ROUTES_CONSTANTS.translateQuestions,
      });
    }

    if (hasTranslationReviewAccess()) {
      menuItems.push({
        _id: 5,
        name: "Translation Review Questions",
        description: "Questions for Translation Review",
        path: ROUTES_CONSTANTS.translationReviewQuestions,
      });
    }

    if (hasApproveAccess()) {
      menuItems.push({
        _id: 6,
        name: "Approve Questions",
        description: "Questions for Approval",
        path: ROUTES_CONSTANTS.approveQuestions,
      });
    }
    // }, 500);
  }
  return menuItems;
}

export const initialProfileState = {
  flyoutMode: "",
  profileDetails: {},
  loggedInUserData: null,
  isDisplay: false,
  isLoading: false,
  globalInstanceData: getStorageItem("globalInstanceData")
    ? getStorageItem("globalInstanceData")
    : {},
  instances: [],
  instancesNames: getStorageItem("globalInstanceList")
    ? getStorageItem("globalInstanceList")
    : [],
  roleDetails: getStorageItem("roleDetails")
    ? getStorageItem("roleDetails")
    : [],
  isPinset: false,
  notificationList: {
    totalCount: 0,
    unRead: [],
    all: [],
    clearNotification: true,
  },
};

function setLoggedInUserData(data) {
  let newData = data;
  // newData["roleDetails"] = undefined;
  newData = JSON.parse(JSON.stringify(newData));
  if (newData) {
    saveItemToStorage("loggedInUserData", newData);
  }
}

export const profileSlice = createSlice({
  name: profileAction.PROFILE_MAIN,
  initialState: initialProfileState,
  reducers: {
    add: profilesAdapter.addOne,
    remove: profilesAdapter.removeOne,
    openDrawer: (state = initialProfileState) => {
      state.isDisplay = true;
    },
    closeDrawer: (state = initialProfileState) => {
      state.isDisplay = false;
    },
    setInstances: (state = initialProfileState, action) => {
      state.instances = action?.payload;
    },
    setInstanceNames: (state = initialProfileState, action) => {
      state.instancesNames = action?.payload;
      if (action.payload) {
        saveItemToStorage(
          "globalInstanceList",
          action?.payload
        );
      }
    },
    setGlobalInstanceData: (state = initialProfileState, action) => {
      state.globalInstanceData = action?.payload;
      if (action.payload) {
        saveItemToStorage(
          "globalInstanceData",
          action?.payload
        );
      }
    },
  },
  extraReducers: (profile) => {
    profile
      .addCase(setFlyoutMode, (state, action) => {
        state.flyoutMode = action.payload?.mode;
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.profileDetails = action?.payload?.data;
        if (action?.payload?.data) {
          saveItemToStorage(
            "loggedInUserData",
            action?.payload?.data
          );
        }
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        const options = action?.payload?.options;
        const data = action?.payload?.data;
        if (!options && options !== undefined) {
          state.notificationList.totalCount = action?.payload?.totalData;
          state.notificationList.clearNotification = true;
        } else if (data?.length > 0) {
          const unReadData = data?.filter((ele) => !ele?.isRead);
          if (state.notificationList.clearNotification) {
            state.notificationList.all = data;
            state.notificationList.unRead = unReadData;
            state.notificationList.clearNotification = false;
          } else {
            state.notificationList.all = [
              ...state.notificationList.all,
              ...data,
            ];
            state.notificationList.unRead = [
              ...state.notificationList.unRead,
              ...unReadData,
            ];
          }
        }
      })
      .addCase(fetchNotification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setClearNotification, (state, action) => {
        state.notificationList.clearNotification = action?.payload;
      })
      .addCase(resetNotificationData, (state, action) => {
        state.notificationList.all = [];
        state.notificationList.unRead = [];
      })
      .addCase(editUserProfile.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(editUserProfile.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.profileDetails = action.payload;
      })
      .addCase(editUserProfile.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchLoggedInUserDetails.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchLoggedInUserDetails.fulfilled, (state, action) => {
        state.loggedInUserData = action?.payload?.data;
        setLoggedInUserData(action?.payload?.data);
        let isInstanceUser = action?.payload?.data.isInstanceUser;
        let isSuperAdmin = action?.payload?.data.isSuperAdmin;
        if (isInstanceUser) {
          saveItemToStorage("isInstanceUser", isInstanceUser);
        }
        if (isSuperAdmin) {
          saveItemToStorage("isSuperAdmin", isSuperAdmin);
        }
        let menuItems = [];
        const roleDetails = action?.payload?.data?.roleDetails || [];
        if (roleDetails?.[0]) {
          let initialData = roleDetails?.[0];
          menuItems = getMenuItems(
            isInstanceUser || isSuperAdmin ? initialData : action?.payload?.data
          );
        } else {
          saveItemToStorage("globalInstanceData", "");
        }
        saveItemToStorage("roleDetails", roleDetails);
        saveItemToStorage("menuItems", menuItems);
      })
      .addCase(fetchLoggedInUserDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchAllInstances.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllInstances.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        let instances = [];
        let instancesNames = [];
        if (data && data.length > 0) {
          instances = data;
          data?.forEach((ele) => {
            if (ele && ele?.instanceId) {
              instances.push({
                _id: ele?.instanceId ? ele?.instanceId : ele?._id,
                name: ele?.name,
                clientId: ele?.clientId,
              });
              instancesNames.push(ele.name);
            }
          });
          if (instances) {
            state.instances = instances;
            state.instancesNames = instancesNames;
            saveItemToStorage(
              "globalInstanceList",
              instancesNames
            );
            const newGlobadlInstance = {
              _id: instances?.[0]?.instanceId
                ? instances?.[0]?.instanceId
                : instances?.[0]?._id,
              name: instances?.[0]?.name,
              clientId: instances?.[0]?.clientId,
            };
            if (!getStorageItem("globalInstanceData")) {
              state.globalInstanceData = newGlobadlInstance;
              saveItemToStorage(
                "globalInstanceData",
                newGlobadlInstance
              );
            }
          }
        }
      })
      .addCase(fetchAllInstances.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchPinSetData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPinSetData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPinset = action?.payload?.data?.pinSet;
      })
      .addCase(fetchPinSetData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setNotificationData, (state, action) => {
        const { all, unRead, totalCount, clearNotification } = action.payload;
        state.notificationList = { all, unRead, totalCount, clearNotification };
      });
  },
});

export const profileReducer = profileSlice.reducer;
export const profileActions = profileSlice.actions;
export const {
  openDrawer,
  closeDrawer,
  setInstances,
  setInstanceNames,
  setGlobalInstanceData,
} = profileActions;

export const getProfileState = (rootState) =>
  rootState[profileAction.PROFILE_MAIN];

export const getFlyoutMode = createSelector(
  getProfileState,
  (state) => state.flyoutMode
);

export const userProfile = createSelector(
  getProfileState,
  (state) => state.profileDetails
);

export const loggedInUser = createSelector(
  getProfileState,
  (state) => state.loggedInUserData
);

export const isDrawerOpen = createSelector(
  getProfileState,
  (state) => state.isDisplay
);

export const allInstances = createSelector(
  getProfileState,
  (state) => state?.instances
);

export const allInstancesNames = createSelector(
  getProfileState,
  (state) => state?.instancesNames
);

export const globalInstanceData = createSelector(
  getProfileState,
  (state) => state?.globalInstanceData
);

export const getPinSetState = createSelector(
  getProfileState,
  (state) => state?.isPinset
);

export const getNotificationListData = createSelector(
  getProfileState,
  (state) => state?.notificationList
);

export const getDrawerLoadingData = createSelector(
  getProfileState,
  (state) => state?.isLoading
);
