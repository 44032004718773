import { useEffect, useState } from "react";
import "./CustomDropDown.css";
import { IconCloseFlyout, IconSelectArrow } from "assets/svgs/Svgs";
import Image from "components/widgets/image/Image";
import { Checkbox } from "../checkbox/Checkbox";
import _ from "lodash";

export const CustomDropDown = ({
  options = [],
  className,
  multiple = false,
  titleClass,
  customDropDownContent = "",
  inputEditor = false,
  themeBlue = false,
  disabled = false,
  isAnswerOptions = false,
  isOptionDropDown = false,
  showNumber = false,
  checkedOptions = [],
}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("Select Answer");
  const [selectedValues, setSelectedValues] = useState([]);
  const [checked, setChecked] = useState(
    showNumber
      ? checkedOptions
      : isAnswerOptions
      ? options?.map((option) => option?.correctValue)
      : Array(options?.text?.length).fill(false)
  );

  useEffect(() => {
    if (isAnswerOptions && !multiple) {
      setSelectedValues(options?.map((option) => option?.optionName));
    }
    if (multiple) {
      setSelectedValues([]);
    }
    if (multiple) setValue("Select Answer");
    setIsActive(false);
    if (!isAnswerOptions) setChecked([]);
    if (showNumber && !multiple) {
      setSelectedValues(
        options
          ?.filter((option, index) => checked[index])
          .map((option) => option[0])
      );
    }
    if (showNumber) {
      setChecked(checkedOptions);
    }
  }, [multiple]);

  const onChangeHandler = (option, index) => {
    checked[index] = !checked[index];
    setSelectedValues(
      selectedValues?.includes(option)
        ? [...selectedValues.filter((value) => value !== option)]
        : [...selectedValues, option]
    );
  };

  const onCloseHandler = (option, index) => {
    setSelectedValues([...selectedValues.filter((value) => value !== option)]);
    checked[index] = !checked[index];
  };

  const RenderDropdownField = ({ option }) => {
    return inputEditor || isAnswerOptions ? (
      <span
        className="html-editor-preview custom-dropdown-input-editor"
        dangerouslySetInnerHTML={{ __html: option }}
      />
    ) : (
      option
    );
  };

  return (
    <div className="d-flex flex-row w-100">
      <div className={`custom-dropdown  question-slide-left ${className}`}>
        <div className="dropdown-width">
          <div
            className="dropdown-btn"
            onClick={() => !disabled && setIsActive(!isActive)}
          >
            {multiple ? (
              selectedValues?.join(", ") || "Select Answer"
            ) : (
              <RenderDropdownField
                option={
                  isAnswerOptions || showNumber
                    ? selectedValues?.join(", ")
                    : value
                }
              />
            )}
          </div>
          {isActive ? (
            <div className={`dropdown-content ${customDropDownContent}`}>
              {options?.length > 0
                ? options?.map((option, index) => (
                    <div
                      className="dropdown-item w-100"
                      onClick={() =>
                        !isAnswerOptions && !multiple && setValue(option)
                      }
                    >
                      {multiple && option ? (
                        <Checkbox
                          optionLabel={
                            isOptionDropDown || showNumber
                              ? option
                              : option?.optionName
                          }
                          titleClass={titleClass}
                          className="d-flex custom-gap-2"
                          inputClass={
                            themeBlue ? "theme-blue-clr" : "preview_radio_btn"
                          }
                          onChangeHandler={
                            isAnswerOptions
                              ? () => {}
                              : () => {
                                  onChangeHandler(option, index);
                                }
                          }
                          checked={checked[index]}
                          disabled={true}
                          themeBlue={themeBlue}
                          isAnswerOptions={isAnswerOptions}
                          showNumber={showNumber}
                        />
                      ) : (
                        <RenderDropdownField
                          option={
                            isOptionDropDown || showNumber
                              ? option
                              : option?.optionName
                          }
                        />
                      )}
                      {option?.image ? (
                        <Image
                          url={option?.image?.value}
                          width="100px"
                          urlOnly={option?.image?.value?.startsWith(
                            "data:image"
                          )}
                        />
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
          ) : null}
          <div className="svgBg">
            {<IconSelectArrow className="iconSelectArrow" />}
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        {multiple && selectedValues?.length > 0
          ? selectedValues?.map((option, index) => (
              <div className="show-selected-value">
                {option}
                <a
                  className="close ml-3 cursor-pointer"
                  onClick={() => {
                    onCloseHandler(option, index);
                  }}
                >
                  <IconCloseFlyout
                    classNameColor={themeBlue ? "" : "icon-close-cls-2"}
                  />
                </a>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default CustomDropDown;
