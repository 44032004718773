import "./MenuItemContainer.css";

export function MenuItemContainer(props) {
  return (
    <>
      <p className="drawer-title">{props.title}</p>
      {props.children}
    </>
  );
}
