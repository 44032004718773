import Image from "../image/Image";
import "./Checkbox.css";

export function Checkbox(props) {
  const {
    optionLabel,
    data,
    onChangeHandler,
    className = "",
    labelClass = "",
    key = "",
    checked,
    disabled = false,
    options,
    isReadOnly,
    title,
    inputClass,
    titleClass = "",
    isAnswerOptions = false,
    showNumber = false,
  } = props;

  const checkboxOptions = [...new Set(options)];

  return (
    <div className={`field-check ${className}`} key={key}>
      <label className={`field-group_label my-3 ${titleClass}`}>
        <span>{title}</span>
      </label>
      <div
        className={`field-check checkbox-group d-flex ${
          isAnswerOptions
            ? "checkbox-is-answer-options"
            : "justify-content-between"
        } ${className}`}
        key={key}
      >
        {isReadOnly ? (
          <label className={`preview-text`}>{checked}</label>
        ) : checkboxOptions?.length > 0 ? (
          checkboxOptions?.map((option, index) => {
            return (
              <div
                className={`d-flex my-2 ${showNumber ? "custom-radio" : ""}`}
              >
                <input
                  id={data?.id}
                  className={`checkbox-input clr-primary-border w-10 ${
                    inputClass ? inputClass : ""
                  }`}
                  type="checkbox"
                  value={data?.value}
                  checked={checked[index] ? "checked" : ""}
                  onChange={(e) => onChangeHandler(option, index)}
                  disabled={disabled}
                />
                <label
                  title={option}
                  className={`clr-grey-darkest ${
                    labelClass ? labelClass : ""
                  } `}
                >
                  {isAnswerOptions ? (
                    option?.image?.value ? (
                      <div className="d-flex flex-col justify-content-start text-align-left mx-1 answer-option-radio">
                        <span
                          className="radio_span_input_editor word-break-all"
                          dangerouslySetInnerHTML={{
                            __html: option?.optionName,
                          }}
                        />
                        <Image
                          urlOnly={true}
                          url={option?.image?.value}
                          width="100px"
                        />
                      </div>
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: option,
                        }}
                      />
                    )
                  ) : (
                    option
                  )}
                </label>
              </div>
            );
          })
        ) : (
          <div
            className={`field-check ${className} ${
              showNumber ? "custom-radio" : ""
            }`}
            key={key}
          >
            <input
              id={data?.id}
              className={`check clr-primary-border w-10 ${
                inputClass ? inputClass : ""
              }`}
              type="checkbox"
              value={data?.value}
              checked={checked}
              onChange={onChangeHandler}
              disabled={disabled}
            />
            <label
              title={optionLabel}
              className={`clr-grey-darkest ${labelClass ? labelClass : ""}`}
            >
              {isAnswerOptions ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: optionLabel,
                  }}
                />
              ) : (
                optionLabel
              )}
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
