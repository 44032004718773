import { IconSelectArrow } from "assets/svgs/Svgs";
import "./InputSelect.css";

export function InputSelect(props) {
  const {
    title = null,
    options,
    defaultValue,
    onChangeHandler,
    name,
    value,
    disabled = false,
    customOptions,
    required = false,
    hasError = false,
    errorMessage = "",
    style = {},
  } = props;

  const handleChange = (e) => {
    if (e?.target?.value !== "default") {
      onChangeHandler(e);
    }
  };
  return (
    <>
      {title ? (
        <label className={`field-group_label ${required && "required"}`}>
          <span>
            {title}
            {required && <span className="required-asterisk">*</span>}
          </span>
        </label>
      ) : null}
      <div
        className={`single-select ${props?.className}`}
        title={value || defaultValue}
      >
        <select
          onChange={(e) => handleChange(e)}
          name={name}
          value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          className="text-wrap-ellipsis pr-5"
        >
          {defaultValue && <option value="default">{defaultValue}</option>}
          {options &&
            options?.length > 0 ?
            options?.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            }) : null}
          {customOptions &&
            customOptions?.length > 0 ?
            customOptions?.map((option) => {
              return (
                <option key={option?.name} value={option?.value}>
                  {option?.name}
                </option>
              )
            }) : null}
        </select>
        <div className="svgBg">
          <IconSelectArrow />
        </div>
      </div>
      {hasError && (
        <div
          className="error-msg "
          style={style && { color: style["error-color"] }}
        >
          {errorMessage}
        </div>
      )}
    </>
  );
}
