import { regex } from "utils/constants/commonRegex";

const FIELD_ERROR_DECIMAL = "Please Enter only numbers or decimal values";

export const questionState = {
  subject: {
    value: "",
    error: "",
    required: true,
  },
  category: {
    value: "",
    error: "",
    required: true,
  },
  type: {
    value: "",
    error: "",
    required: true,
  },
  level: {
    value: "",
    error: "",
    required: true,
  },
  marks: {
    value: "",
    error: "",
    validator: {
      func: (value) => regex.DECIMAL_NUMBER?.test(value),
      error: FIELD_ERROR_DECIMAL,
    },
    required: true,
  },
  negativeMark: {
    value: "0",
    error: "",
    validator: {
      func: (value) => regex.DECIMAL_NUMBER?.test(value),
      error: FIELD_ERROR_DECIMAL,
    },
  },
  duration: {
    value: "",
    error: "",
    validator: {
      func: (value) => regex.DECIMAL_NUMBER?.test(value),
      error: FIELD_ERROR_DECIMAL,
    },
  },
  language: {
    value: "",
    error: "",
    required: true,
  },
  groupQuestion: {
    value: null,
    error: "",
  },
  questionDescription: {
    value: "",
    error: "",
  },
  questionImage: {
    value: "",
    error: "",
  },
  questionImageName: {
    value: "",
    error: "",
  },
  optionType: {
    value: "Text",
    error: "",
  },
  answer: {
    value: [""],
    error: "",
  },
  optionFile: {
    value: [],
    error: "",
  },
};
