import { IconEduTest } from "assets/svgs/Svgs";
import "./DrawerFooter.css";

export function DrawerFooter(props) {
  return (
    <div className="drawerFooterArea">
      {<IconEduTest />}
      <div className="drawerFooterInfo">
        <span>Powered By</span>
        <h3>EDUTEST</h3>
        <span>EduTest &#169; 2021</span>
      </div>
      <div className="drawerFooterversion">V 1.2</div>
    </div>
  );
}
