import "./TextButton.css";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

export default function TextButton(props) {
  const {
    innerRef = null,
    title,
    clickHandler,
    size = "medium",
    rounded = true,
    isPrimary = true,
    loading = false,
    shouldDisable = false,
    loaderConfig = {},
    className = "",
    tooltip = false,
    style = null,
    fontSizerStyle = null,
  } = props;

  let { loadingTitle = "" } = props;
  if (loadingTitle.length === 0) loadingTitle = title;

  const override = css`
    display: block;
    margin-right: 6px;
  `;

  let btnSize = "btn--sm";
  if (size === "large") btnSize = "btn--lg";
  else if (size === "medium") btnSize = "btn--md";

  const handleButtonClick = (e) => {
    if (shouldDisable) return;
    if (loading) return;
    if (clickHandler) clickHandler(e);
  };

  let bStyle = { fontSize: "16px" };
  if (style) bStyle = { ...bStyle, ...style };
  if (fontSizerStyle) {
    bStyle = { ...fontSizerStyle };
  }

  return (
    <button
      ref={innerRef}
      className={`btn ${btnSize ? btnSize : " "} ${
        rounded ? "btn--rounded" : " "
      } ${!isPrimary ? " btn--alt" : "button-primary"} ${
        shouldDisable ? " disabled" : " "
      } ${className} ${title === "Clear" && "et-button-clear-title"}`}
      style={bStyle}
      onClick={handleButtonClick}
    >
      {loading ? (
        <ClipLoader
          color={loaderConfig.color ? loaderConfig.color : "#fff"}
          loading={true}
          css={override}
          size={loaderConfig.size ? loaderConfig.size : 20}
        />
      ) : null}
      <span title={tooltip && title}>{loading ? loadingTitle : title}</span>
    </button>
  );
}
