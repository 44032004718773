export const questionFilterState = {
  subject: {
    value: "",
    error: "",
  },
  category: {
    value: "",
    error: "",
  },
  language: {
    value: "",
    error: "",
  },
  type: {
    value: "",
    error: "",
  },
  level: {
    value: "",
    error: "",
  },
  status: {
    value: "",
    error: "",
  },
  isGroupPassage: {
    value: undefined,
    error: "",
  },
};
