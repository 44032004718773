import AppConstants from "utils/constants/app-constants";
import { ApiCaller } from "utils/network/Network";
import { saveItemToStorage } from "utils/utility";

export async function fetchPublickKeyApi() {
  return ApiCaller.get(AppConstants.login.GET_PUBLIC_KEY)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchPinSetApi() {
  return ApiCaller.get(AppConstants.login.GET_PIN_SET)
    .then((response) => {
      ApiCaller.get(AppConstants.login.GET_PIN).then((res) => {
        if (res?.data?.statusCode == 200) {
          if (res?.data?.data?.pin) {
            saveItemToStorage("loginPin", res?.data?.data?.pin);
          }
        }
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createPinApi(options) {
  return ApiCaller.post(AppConstants.login.CREATE_PIN, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function updatePinApi(options) {
  return ApiCaller.patch(AppConstants.login.UPDATE_PIN, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function userLoginApiCall() {
  return ApiCaller.post(AppConstants.login.USER_LOGIN)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function userLogoutApiCall() {
  return ApiCaller.post(AppConstants.login.USER_LOGOUT)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function userLoginRefreshCall() {
  return ApiCaller.post(AppConstants.login.USER_LOGIN)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}
