import { BackMenu } from "./back-menu/BackMenu";
import { HamburgerMenu } from "./hamburger-menu/HamburgerMenu";
import { NavHeader } from "./nav-header/NavHeader";
import { NavActions } from "./nav-actions/NavActions";
import { actionDropDownOptions } from "utils/constants/constants";
import { getStorageItem } from "utils/utility";

export function NavBar(props) {
  const {
    isBack,
    isPinset,
    title,
    mode,
    mapView,
    onBackHandler,
    dirtyFieldData,
    sticky = true,
    shouldDisplayInstanceSelection,
    classes = "",
    tabs = [],
  } = props;
  let showNoModuleMapError = false;

  if (getStorageItem("menuItems")) {
    const menuData = getStorageItem("menuItems");
    if (menuData?.length === 0) {
      showNoModuleMapError = true;
    }
  }

  return (
    <div
      className={`${
        sticky ? "topRowHolder sticky" : "topRowHolder"
      } ${classes}`}
    >
      <div className="headerLevelTwo">
        <div className="header4Cols ">
          {!isBack && isPinset ? (
            <>{!showNoModuleMapError ? <HamburgerMenu /> : null}</>
          ) : (
            <BackMenu
              onBackHandler={onBackHandler}
              dirtyFieldData={dirtyFieldData}
              mapView={mapView}
              mode={mode}
            />
          )}
          <NavHeader
            title={title}
            showNoModuleMapError={showNoModuleMapError}
            shouldDisplayInstanceSelection={shouldDisplayInstanceSelection}
            tabs={tabs}
          />
          <NavActions
            isUnread={true}
            isActionDropdown={true}
            actionDropDownOptions={actionDropDownOptions}
          />
        </div>
      </div>
    </div>
  );
}
