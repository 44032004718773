import { useRef } from "react";
import "./InputDateField.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import { IconCalendar } from "assets/svgs/Svgs";

export function InputDateField(props) {
  const ref = useRef();
  const {
    fieldName,
    title,
    clearText,
    placeholder,
    data,
    hasError = false,
    errorMessage = "",
    onChangeHandler,
    onclearHandler,
    isDisabled = false,
    customClass,
    hasIcon = false,
    icon = null,
    restrictFutureDates,
    restrictPastDates,
    required = false,
    style = {},
    dateFormat,
    className = "",
    position = "auto",
    currentDate = new Date(),
    disabledMinDate = "",
  } = props;

  return (
    <div
      className={`field-group ${hasError ? "has-error" : ""} ${className}`}
      id="et-date-field"
    >
      <label
        className={`field-group_label ${required ? "required" : ""} ${
          title ? "" : "d-none"
        }`}
        style={style && { color: style["label-color"] }}
      >
        <span>
          {title}
          {required && <span className="required-asterisk">*</span>}
        </span>
      </label>
      <div className={`${hasIcon ? "has-icon" : ""}`}>
        <Flatpickr
          ref={ref}
          id={fieldName}
          name={fieldName}
          className={
            customClass
              ? "field-group_field flatpickr-background " + customClass
              : "field-group_field flatpickr-background "
          }
          onChange={([date]) => {
            const event = {
              target: {
                name: fieldName,
                value: date,
              },
            };
            onChangeHandler(event);
          }}
          placeholder={placeholder || "dd/mm/yyyy"}
          options={{
            dateFormat: dateFormat ? dateFormat : "d/m/Y",
            mode: "single",
            position: position ? position : "auto",
            minDate: restrictPastDates ? currentDate : disabledMinDate,
            maxDate: restrictFutureDates ? currentDate : null,
            static: true,
          }}
          value={data}
          disabled={isDisabled}
        />
        {hasIcon && (
          <span className="icon pointer-events-none">
            {icon ? icon : <IconCalendar fillBlueColor={true} />}
          </span>
        )}
      </div>
      {hasError && <p className="error-msg ">{errorMessage} </p>}
      {clearText && data && (
        <a
          className="field--clear CE-ClearField"
          onClick={() => onclearHandler(fieldName)}
        >
          {clearText}
        </a>
      )}
    </div>
  );
}
