export const profileAction = {
  PROFILE_MAIN: "profile",
  OPEN_CLOSE_FLYOUT_MODE: "profile/flyout/mode",
  PROFILE_FEATURE_KEY: "profile",
  GET_PROFILE: "profile/get",
  UPDATE_PROFILE: "profile/update",
  FETCH_LOGGED: "fetch/logged",
  FETCH_INSTANCES: "fetch/instances",
  FETCH_PIN_SET: "fetch/pin/set",
  SEND_FIREBASE_TOKEN: "user/firebasetoken/send",
  CLEAR_NOTIFICATION: "notification/list/clear",
  SET_NOTIFICATION_DATA: "notification/set/latestdata",
  FETCH_NOTIFICATION: "fetch/notifications/list",
  UPDATE_ALL_NOTIFICATION: "updateAll/notification/read",
  UPDATE_NOTIFICATION: "update/notification/read",
  RESET_NOTIFICATION_DATA: "reset/notification/data",
};
