import { EyeCloseIcon, IconEye } from "assets/svgs/Svgs";
import InputField from "components/widgets/input-field/InputField";
import Modal from "components/widgets/modal/Modal";
import TextButton from "components/widgets/text-Button/TextButton";
import React, { useEffect, useRef, useState } from "react";
import "./VerifySecretKey.css";
import { useForm } from "utils/customeHooks/UseForm";
import { Strings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import { BtnTitles } from "utils/constants/constants";

const VerifySecretKey = (props) => {
  const { examKeyPopupVisible, examKeyPopupClose, submitHandler } = props;
  const [toggleVisibility, setToggleVisibility] = useState({
    showKey: false,
  });
  const submitBtnRef = useRef(null);

  const handleVisibility = (name) => {
    setToggleVisibility({
      ...toggleVisibility,
      [name]: !toggleVisibility[name],
    });
  };

  const keyState = {
    secretKey: {
      value: "",
      error: "",
      validator: {
        func: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/g.test(value),
        error: Strings?.FIELD_INVALID_SECRET_KEY,
      },
      required: true,
    },
  };

  useEffect(() => {
    clearStateSchema();
    setErrors((prev) => ({ ...prev, secretKey: "" }));
  }, [examKeyPopupVisible]);

  useEffect(() => {
    const listener = (event) => {
      if (event?.code === "Enter" || event?.code === "NumpadEnter") {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document?.addEventListener("keydown", listener);
    return () => {
      document?.removeEventListener("keydown", listener);
    };
  }, []);

  const onSubmit = (state, error) => {
    if (!error) submitHandler(values?.secretKey);
  };

  const {
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    clearStateSchema,
    setErrors,
  } = useForm(keyState, onSubmit);

  return (
    <Modal show={examKeyPopupVisible} closeHandler={examKeyPopupClose}>
      <div className="my-4">
        <InputField
          fieldName="secretKey"
          data={values.secretKey}
          onChangeHandler={handleOnChange}
          title={Strings.FIELD_TITLE_ENTER_KEY}
          required={true}
          type={toggleVisibility.showKey ? "text" : "password"}
          icon={toggleVisibility.showKey ? <IconEye /> : <EyeCloseIcon />}
          iconClickHandler={() => handleVisibility("showKey")}
          classes="verify_key_input"
          hasError={errors.secretKey}
          errorMessage={errors.secretKey}
          autoComplete={"new-password"}
        />
      </div>
      <div className="d-flex justify-content-between">
        <TextButton
          isPrimary={false}
          title={BtnTitles.CANCEL}
          rounded={false}
          clickHandler={() => examKeyPopupClose()}
        />
        <TextButton
          innerRef={submitBtnRef}
          title={BtnTitles.SUBMIT}
          rounded={false}
          clickHandler={handleOnSubmit}
        />
      </div>
    </Modal>
  );
};

export default VerifySecretKey;
