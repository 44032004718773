export const initialState = {
  instanceId: "",
  page: 1,
  perPage: 10,
};

export const reqParams = {
  search: "",
  sort: "ASC",
  instanceId: "",
  page: 0,
  perPage: 0,
};
