export const renderQuestionCreationStatus = (status) => {
  switch (status) {
    case 1:
    case "DECLINED":
      return "Rejected";
    case 2:
    case "PENDING":
      return "Pending";
    case 3:
    case "APPROVED":
      return "Approved";
    case 4:
    case "IN_REVIEW":
      return "In Review";
    case 5:
    case "LANGUAGE_TRANSLATOR":
      return "Reviewed";
    case 6:
    case "TRANSLATOR_REVIEW":
      return "In Translation Review";
    case 7:
    case "LANGUAGE_REJECTED":
      return "Lanugage Translation Rejected";
    case 8:
    case "PUBLISHED":
      return "Published";
    case 9:
    case "REJECTED":
      return "Rejected";
    case 10:
    case "PUBLISHER":
      return "Approval Pending";
    case 11:
    case "CREATED":
      return "Created";
    case 12:
    case "UPDATED":
      return "Updated";
    case 13:
    case "DECLINED":
      return "Discarded";
    default:
      return null;
  }
};
