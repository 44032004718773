import Footer from "components/laylouts/footer/Footer";
import FlyoutHeader from "components/widgets/flyout-header/FlyoutHeader";
import InputEditor from "components/widgets/input-editor/InputEditor";
import InputField from "components/widgets/input-field/InputField";
import Modal from "components/widgets/modal/Modal";
import TextButton from "components/widgets/text-Button/TextButton";
import "./AddEditPassage.css";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import { useForm } from "utils/customeHooks/UseForm";
import { defaultImgState, passageState } from "./passageState";
import _ from "lodash";
import {
  BtnTitles,
  FieldTypes,
  Strings,
  passageStrings,
} from "utils/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addPassage,
  editPassage,
  fetchAllPassage,
  getPassageDetail,
  setPassageFlyoutMode,
  setSelectedPassage,
} from "redux/passage/passage-slice";
import { regex } from "utils/constants/commonRegex";
import {
  convertBase64,
  countWord,
  filterEmptyValues,
  notifyError,
  notifySuccess,
} from "utils/utility";
import { useEffect, useState } from "react";
import { getSelectedLanguage } from "redux/add-question/add-question-slice";
import Compressor from "compressorjs";

const RenderkeyValuePair = ({ title, value }) => {
  return (
    <>
      <span className="field-group_label">{title}</span>
      <span className="text-align-left">{value}</span>
    </>
  );
};

export const AddEditPassage = ({ heading, isLangTranslator, mode }) => {
  const [wordCount, setWordCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const selectedPassage = useSelector(getPassageDetail);
  const selectedLanguage = useSelector(getSelectedLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLangTranslator) {
      setValues((prev) => ({
        ...prev,
        language: selectedLanguage,
        image: defaultImgState,
      }));
    }
    if (mode === "Edit") {
      setFirstLoad(true);
      const {
        title = "",
        noOfQuestion = "",
        passage = "",
        image = "",
      } = selectedPassage;
      setValues((prev) => ({
        ...prev,
        title: title,
        noOfQuestion: noOfQuestion,
        passage: passage,
        image: image,
      }));
    }
  }, [mode]);

  const onSubmit = (state, error) => {
    if (!error) {
      if (!values.passage && !values.image?.value) {
        notifyError(passageStrings.ADD_EITHER_DETAIL_OR_PHOTO);
        return;
      }
      const options = {
        language: values?.language,
        title: values?.title,
        noOfQuestion: Number(values?.noOfQuestion),
        passage: values?.passage,
        image: values?.image,
      };
      const api =
        mode === "Add"
          ? addPassage(filterEmptyValues(options))
          : editPassage({
              passageId: selectedPassage?._id,
              data: filterEmptyValues(options),
            });

      dispatch(api).then((res) => {
        if (res?.payload?.data?._id) {
          notifySuccess(res?.payload?.message);
          dispatch(fetchAllPassage({ language: values?.language }));
          if (mode === "Edit") {
            dispatch(
              setSelectedPassage({
                _id: selectedPassage?._id,
                ...options,
              })
            );
          }
          closeHandler();
        } else {
          notifyError(res?.payload?.data?.message);
        }
      });
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    clearStateSchema,
    setValues,
    handleOnClearImageUpload,
    setNewErrorAndDirty,
  } = useForm(passageState, onSubmit);

  const closeHandler = () => {
    dispatch(setPassageFlyoutMode(""));
  };

  const uploadImage = async (e) => {
    const fileSelected = e;
    if (!fileSelected?.name?.match(regex?.JPG_PNG_JPEG_GIF)) {
      notifyError(Strings?.INVALID_FILE_FORMAT);
      return;
    }
    try {
      const compressedResult = await new Promise((resolve, reject) => {
        new Compressor(fileSelected, {
          quality: 0.8,
          success: resolve,
          error: reject,
        });
      });

      const response = await convertBase64(compressedResult);
      if (response) {
        setValues((prevState) => ({
          ...prevState,
          image: { value: response, name: fileSelected?.name },
        }));
        setNewErrorAndDirty("image", "");
      }
    } catch (error) {
      notifyError(error?.message);
    }
  };

  const tinyEditorHandler = (e) => {
    if (firstLoad) {
      setFirstLoad(false);
    }
    let content = e?.getContent();
    let words = countWord(e);
    if (words > 1000) {
      return;
    }
    setValues((prev) => ({
      ...prev,
      passage: content,
    }));
    setWordCount(words);
  };

  const onChangeNoOfQuestion = (e) => {
    if (e?.target?.value !== "-" && e?.target?.value !== ".") {
      handleOnChange(e);
    }
  };

  const handleClearStateSchema = () => {
    clearStateSchema();
    setValues((prev) => ({
      ...prev,
      language: selectedLanguage,
      image: defaultImgState,
    }));
  };

  return (
    <Modal show={true} modalContentClassName="passage-modal">
      <FlyoutHeader header={heading} closeHandler={closeHandler} />
      <div className="passage-modal-container">
        <div className="d-flex flex-col justify-content-start">
          {isLangTranslator ? (
            <RenderkeyValuePair title="TITLE" value={heading} />
          ) : (
            <InputField
              type={FieldTypes.TYPE_TEXT}
              fieldName="title"
              title={Strings.FIELD_TITLE_TITLE}
              className="w-100"
              classes="w-100"
              required={true}
              disabled={isLangTranslator}
              data={values?.title}
              onChangeHandler={handleOnChange}
              hasError={errors?.title && dirty?.title}
              errorMessage={errors?.title}
            />
          )}
        </div>
        <div className="mt-3 d-flex flex-col">
          {isLangTranslator ? (
            <RenderkeyValuePair title="NO. OF QUESTIONS" value="12" />
          ) : (
            <InputField
              type={FieldTypes.TYPE_TEXT}
              fieldName="noOfQuestion"
              title={Strings.PASSAGE_TITLE_NO_OF_QUES}
              className="w-100"
              classes="w-100"
              required={true}
              disabled={isLangTranslator}
              data={values?.noOfQuestion}
              onChangeHandler={onChangeNoOfQuestion}
              hasError={errors?.noOfQuestion && dirty?.noOfQuestion}
              errorMessage={errors?.noOfQuestion}
              min={0}
              max={20}
              disableAutoFocus={true}
            />
          )}
        </div>
        <div className={isLangTranslator ? "" : "mt-3"}>
          <InputEditor
            height={350}
            style={{ height: "350px" }}
            title={isLangTranslator ? "" : "Passage"}
            onChange={(e) => tinyEditorHandler(e)}
            className="question-title"
            data={values?.passage}
            key={"passage1"}
            allowResize={false}
          />
          <p className="field-group_label justify-content-end">
            Total Words Count: {wordCount}/1000
          </p>
        </div>
        <div className="mt-3">
          <FileInputField
            className="add-question-file"
            accepts={"image/png, image/jpeg, image/jpg"}
            fieldName={"image"}
            hasError={errors?.image && dirty?.image}
            errorMessage={errors?.image}
            onChangeHandler={(e) => uploadImage(e)}
            hasPreview={values?.image?.value}
            preview={values?.image?.value}
            shouldDisplayCrossIcon={true}
            onImageClearHandler={handleOnClearImageUpload}
            urlOnly={true}
            name={values?.image?.name}
            descriptionOnBox={Strings?.FILE_FORMAT_PHOTO}
          />
        </div>
      </div>
      <Footer
        isPagination={true}
        class="justify-content-between passage-modal-footer"
      >
        <div>
          <TextButton
            title={BtnTitles.CANCEL}
            isPrimary={true}
            rounded={false}
            size="small"
            clickHandler={closeHandler}
          />
        </div>
        <div className="add-question-right-footer d-flex justify-content-between">
          <TextButton
            title={BtnTitles.RESET_SMALL}
            isPrimary={false}
            rounded={false}
            size="small"
            clickHandler={
              isLangTranslator ? clearStateSchema : handleClearStateSchema
            }
          />
          <TextButton
            title={BtnTitles.SUBMIT}
            isPrimary={true}
            rounded={false}
            size="small"
            clickHandler={handleOnSubmit}
          />
        </div>
      </Footer>
    </Modal>
  );
};

export default AddEditPassage;
