import { useEffect, useState } from "react";
import "./Pagination.css";

export function Pagination(props) {
  const {
    currentPage = 0,
    totalRecords,
    perPage,
    pageChangeHandler,
    className = "",
  } = props;
  const [pageNumberSelect, setPageNumberSelect] = useState(
    parseInt(currentPage, 10)
  );
  const totalPages = Math.ceil(totalRecords / perPage);
  let previous = parseInt(pageNumberSelect, 10) - 1;
  let next = parseInt(pageNumberSelect, 10) + 1;
  if (previous === 0) previous = 1;
  if (next === totalPages + 1) next = totalPages;
  const start = (pageNumberSelect - 1) * perPage + 1;
  const end =
    totalRecords < start + parseInt(perPage, 10) - 1
      ? totalRecords
      : start + parseInt(perPage, 10) - 1;
  let pageRecords = [];

  useEffect(() => {
    setPageNumberSelect(currentPage);
  }, [currentPage]);

  if (totalPages > 6) {
    if (pageNumberSelect < 5) {
      pageRecords = [1, 2, 3, 4, 5, "...", totalPages];
    } else {
      if (next === totalPages - 2) {
        pageRecords = [
          1,
          "...",
          previous,
          pageNumberSelect,
          next,
          next + 1,
          totalPages,
        ];
      } else if (next === totalPages - 1) {
        pageRecords = [
          1,
          "...",
          previous - 1,
          previous,
          pageNumberSelect,
          next,
          totalPages,
        ];
      } else if (next === totalPages && pageNumberSelect !== totalPages) {
        pageRecords = [
          1,
          "...",
          previous - 2,
          previous - 1,
          previous,
          pageNumberSelect,
          next,
        ];
      } else if (next === totalPages && pageNumberSelect === totalPages) {
        pageRecords = [
          1,
          "...",
          pageNumberSelect - 4,
          pageNumberSelect - 3,
          pageNumberSelect - 2,
          pageNumberSelect - 1,
          pageNumberSelect,
        ];
      } else {
        pageRecords = [
          1,
          "...",
          previous,
          pageNumberSelect,
          next,
          "...",
          totalPages,
        ];
      }
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      pageRecords.push(i);
    }
  }
  const pageClickHandler = (e) => {
    const clickedPage = e.target.getAttribute("data");
    if (clickedPage === "...") return;
    const isPageChanged = clickedPage !== pageNumberSelect;
    setPageNumberSelect(Number(e.target.getAttribute("data")));

    if (isPageChanged) {
      pageChangeHandler(e.target.getAttribute("data"));
    }
  };

  return (
    <div className={`pagination ${className}`}>
      <div className="page__nav">
        <ul className="page__numbers">
          {pageRecords?.length > 0
            ? pageRecords?.map((pageNumber, index) => {
                return (
                  <li key={index} onClick={pageClickHandler}>
                    <span
                      href="##"
                      data={pageNumber}
                      className={`page__number cursor-pointer ${
                        pageNumber === pageNumberSelect
                          ? "page--current"
                          : pageNumber === "..."
                          ? "blank"
                          : ""
                      }`}
                      onClick={(e) => e?.preventDefault()}
                    >
                      {pageNumber}
                    </span>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      {totalRecords > 0 ? (
        <span className="page__count">
          Showing records {start} to {end} of {totalRecords}
        </span>
      ) : null}
    </div>
  );
}
