import { useState } from "react";
import "./ConfirmationModal.css";
import TextButton from "../text-Button/TextButton";
import { DropDownField } from "../drop-down-field/DropDownField";

export function ConfirmationModal(props) {
  const {
    title = "",
    onCancel,
    confirmHandler,
    reasons = false,
    loading,
    show,
    btnText = "",
    btnLoadingText = "",
    cancelText = "Cancel",
  } = props;

  const [reason, setReason] = useState("");

  const handleOnSelection = (e) => {
    setReason(e.target.value);
  };

  return (
    <div className={`popup-confirm ${show ? "show" : ""}`}>
      <div className="popup-confirm-content">
        <h2>{title}</h2>
        {reasons && (
          <div className="field-group">
            <div className="single-select">
              <DropDownField
                options={reasons}
                fieldName={"reason"}
                defaultValue={"Select Reason"}
                onChangeHandler={handleOnSelection}
              />
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between mt-2">
          <TextButton
            isPrimary={btnText === "Delete"}
            title={btnText}
            loadingTitle={btnLoadingText}
            rounded={false}
            loading={loading}
            clickHandler={(e) => {
              confirmHandler(reason, e);
            }}
          />
          <TextButton
            isPrimary={!(btnText === "Delete")}
            title={cancelText}
            rounded={false}
            clickHandler={onCancel}
          />
        </div>
      </div>
    </div>
  );
}
