import { useRef, useState } from "react";
import "./FileInputField.css";
import { IconFieldHelp } from "assets/svgs/Svgs";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import Image from "components/widgets/image/Image";

export function FileInputField(props) {
  const {
    fieldName,
    title,
    description = "",
    hasError = false,
    errorMessage = "",
    onChangeHandler,
    accepts,
    multiple,
    disabled = false,
    required = false,
    className = "",
    fontSizerStyle = null,
    isReadOnly = false,
    isDragAndDropRequired,
    hasPreview = false,
    onImageClearHandler,
    preview = "",
    shouldDisplayCrossIcon = false,
    urlOnly = false,
    name = "",
    infoIcon = true,
    descriptionOnBox = "",
  } = props;

  const [showHint, setShowHint] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [index, setIndex] = useState(0);

  const inputRef = useRef(null);
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const cancelDeleteClickHandler = () => {
    setConfirmationModal(false);
  };

  const confirmClickHandler = () => {
    clearHandler(); //called when user clicks on confirm button of delete modal
    setConfirmationModal(false);
  };

  const clearHandler = () => {
    if (!disabled) {
      onImageClearHandler(fieldName, "");
      document.getElementById(fieldName).value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();

    if (!disabled) {
      if (!multiple) {
        if (event?.dataTransfer?.files[0])
          onChangeHandler(event.dataTransfer.files[0]);
      } else {
        if (event?.dataTransfer?.files)
          onChangeHandler(event.dataTransfer.files);
      }
    }
  };

  const onImageChange = (e) => {
    const files = [...e.target.files];
    if (!multiple && files[0]) {
      onChangeHandler(files[0]);
    } else {
      if (files) onChangeHandler(files);
    }
  };

  const handleOnDeleteHandler = () => {
    setConfirmationModal(true);
  };

  const upload = () => {
    inputRef?.current?.click();
  };

  const hintHoverHandler = () => {
    setShowHint(!showHint);
  };

  let fileBoxStyle = {
    label: {},
    discription: {},
    dragBox: {},
  };

  if (fontSizerStyle) {
    let defaultFontSize = fontSizerStyle?.fontSize;
    fileBoxStyle = {
      label: { fontSize: defaultFontSize },
      discription: { fontSize: defaultFontSize },
      dragBox: { fontSize: defaultFontSize - 2 },
    };
  }

  return (
    <div
      className={`field-group ${hasError ? "has-error" : ""} ${className}`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <label className="field-group_label">
        <span style={fileBoxStyle?.label}>{title}</span>
        {!isReadOnly ? (
          required ? (
            <span className="required-asterisk">*</span>
          ) : null
        ) : null}
        {!isReadOnly ? (
          description.length > 0 ? (
            <span
              className={`hint ${showHint && "show"}`}
              onMouseOver={hintHoverHandler}
              onMouseOut={hintHoverHandler}
            >
              {infoIcon ? <IconFieldHelp /> : null}
              <span className="hint-info" style={fileBoxStyle?.discription}>
                {description.length > 0
                  ? description
                  : "Only jpg or png file is allowed, suggested resolution Min 600px width"}
              </span>
            </span>
          ) : null
        ) : null}
      </label>
      {description.length > 0 && (
        <span className="field__text" style={fileBoxStyle?.dragBox}>
          {description}
        </span>
      )}
      <div className="dropArea">
        <input
          ref={inputRef}
          accept={accepts}
          multiple={multiple}
          type="file"
          autoComplete="off"
          tabIndex="-1"
          style={{ display: "none" }}
          onChange={onImageChange}
          name={fieldName}
          id={fieldName}
          disabled={disabled}
          value=""
        />
        {isDragAndDropRequired === true ? (
          <p>Drag 'n' drop some {multiple ? "files" : "file"} here</p>
        ) : (
          <p>Upload file</p>
        )}
        {descriptionOnBox ? (
          <span className="description-on-box">{descriptionOnBox}</span>
        ) : null}
        <button type="button" disabled={disabled} onClick={upload}>
          Choose File
        </button>
      </div>
      {hasError && <p className="error-msg ">{errorMessage} </p>}
      {hasPreview && (
        <aside className="previewArea">
          <div
            className={`eachFile ${!preview && "preview_display_none"} ${
              hasError ? "showError" : ""
            } `}
          >
            <div className="thumb-image-holder">
              <Image url={preview} urlOnly={urlOnly} />
            </div>
            {preview.length > 0 && shouldDisplayCrossIcon ? (
              <div
                className={"removeFile"}
                onClick={(e) => {
                  if (!disabled) return handleOnDeleteHandler();
                }}
              >
                x
              </div>
            ) : null}
          </div>
        </aside>
      )}

      <ConfirmationModal
        show={confirmationModal}
        onCancel={cancelDeleteClickHandler}
        confirmHandler={confirmClickHandler}
        title={`Are you sure you want to delete this ${
          name ? name : multiple && preview ? preview[index] : preview
        }?`}
        btnText={"Delete"}
        btnLoadingText={"Deleting"}
      />
    </div>
  );
}
