import { useEffect, useState } from "react";
import { DrawerHeader } from "components/widgets/drawer-header/DrawerHeader";
import { DrawerFooter } from "components/widgets/drawer-footer/DrawerFooter";
import { MenuItemContainer } from "components/laylouts/menu-item-container/MenuItemContainer";
import { MenuItem } from "components/laylouts/menu-item/MenuItem";
import { Strings } from "utils/constants/constants";
import "./Drawer.css";
import { getStorageItem } from "utils/utility";

export function Drawer(props) {
  const { open } = props;
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (getStorageItem("menuItems")) {
      const menuData = getStorageItem("menuItems");
      if (menuData?.length > 0) setListData(menuData);
    }
  }, [localStorage.getItem("menuItems")]);

  return (
    <div className={`drawer hide  ${open ? "open" : ""}`}>
      <div className="drawerHeader">
        <DrawerHeader heading="ET-CBT" name="core" />
      </div>

      <MenuItemContainer title={Strings.DRAWER_TITLE}>
        {listData &&
          listData.length > 0 &&
          listData?.map((ele) => {
            return <MenuItem data={ele} />;
          })}
      </MenuItemContainer>
      <div className="drawerFooter">
        <DrawerFooter />
      </div>
    </div>
  );
}

export default Drawer;
