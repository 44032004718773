import { IconDelete } from "assets/svgs/Svgs";
import Footer from "components/laylouts/footer/Footer";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import FlyoutHeader from "components/widgets/flyout-header/FlyoutHeader";
import IconButton from "components/widgets/icon-button/IconButton";
import Image from "components/widgets/image/Image";
import Modal from "components/widgets/modal/Modal";
import TextButton from "components/widgets/text-Button/TextButton";
import { useEffect, useState } from "react";
import "./ViewPassage.css";
import { getUserDetails } from "redux/login/login-slice";
import {
  BtnTitles,
  roleTypeNameStrings,
  Strings,
} from "utils/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePassage,
  fetchAllPassage,
  getPassageDetail,
  getSelectedPassageId,
  passageDetail,
  setPassageFlyoutMode,
  setSelectedPassage,
  setSelectedPassageId,
} from "redux/passage/passage-slice";
import { notifyError, notifySuccess } from "utils/utility";
import { getSelectedLanguage } from "redux/add-question/add-question-slice";
import Loader from "components/widgets/loader/Loader";
import { successStatusCodes } from "utils/constants/common";

export const ViewPassage = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const userData = useSelector(getUserDetails);
  let selectedPassage = useSelector(getPassageDetail);
  const selectedLanguage = useSelector(getSelectedLanguage);
  const selectedPassageId = useSelector(getSelectedPassageId);
  selectedPassage = selectedPassage ? selectedPassage : {};
  const dispatch = useDispatch();
  const { roles } = userData;
  const { roleName } = roles;
  const isLangTranslator =
    roleName === roleTypeNameStrings.USER_ROLE_LAUNGUAGE_TRANSLATOR;
  const {
    title = "",
    noOfQuestion = "",
    passage = "",
    image = "",
  } = selectedPassage;

  useEffect(() => {
    if (props?.show) {
      dispatch(passageDetail({ passageId: selectedPassageId }))
        .then((res) => {
          if (successStatusCodes.includes(res?.payload?.statusCode)) {
            notifySuccess(res?.payload?.message);
          } else notifyError(res?.payload?.data?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props?.show]);

  const closeHandler = () => {
    dispatch(setPassageFlyoutMode(""));
  };

  const onEditClickHandler = () => {
    closeHandler();
    dispatch(setPassageFlyoutMode("Edit"));
  };

  const clearPassage = () => {
    dispatch(setSelectedPassage(null));
    dispatch(setSelectedPassageId(""));
  };

  const confirmDeleteHandler = async () => {
    dispatch(deletePassage({ passageId: selectedPassage?._id })).then((res) => {
      if (successStatusCodes.includes(res?.payload?.statusCode)) {
        notifySuccess(res?.payload?.message);
        dispatch(fetchAllPassage({ language: selectedLanguage }));
        clearPassage();
        setShowConfirmationModal(false);
        closeHandler();
      } else notifyError(res?.payload?.data?.message);
    });
  };

  return (
    <Modal
      show={true}
      modalContentClassName={`passage-modal ${
        showConfirmationModal && "z-index-unset"
      }`}
      overlay={"view-passage-overlay"}
    >
      <FlyoutHeader
        header={Strings.FLYOUT_HEADING_VIEW_PASSAGE}
        closeHandler={closeHandler}
      />
      {!loader ? (
        <div className={`passage-modal-container justify-content-start `}>
          <div className="mt-3">
            <div className={`question-key d-flex `}>
              {Strings.PASSAGE_TITLE_TITLE}
            </div>
            <div className="d-flex justify-content-start">{title}</div>
          </div>
          <div className={"mt-3"}>
            <div className={`question-key d-flex `}>
              {Strings.PASSAGE_TITLE_NO_OF_QUES}
            </div>
            <div className="d-flex justify-content-start">{noOfQuestion}</div>
          </div>
          <div className={"mt-3"}>
            <div className={`question-key d-flex `}>
              {Strings.PASSAGE_TITLE_PASSAGE}
            </div>
            <div
              className="view-passage-text"
              dangerouslySetInnerHTML={{ __html: passage }}
            />
            {image?.value ? (
              <Image
                className="d-flex justify-content-start mt-2 mb-3"
                url={image?.value}
                alt="photo"
                width="100%"
                urlOnly={true}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Footer
        isPagination={true}
        class="d-flex justify-content-between passage-modal-footer"
        className="w-100"
      >
        <div className="add-question-right-footer d-flex justify-content-between w-100">
          <TextButton
            title={
              isLangTranslator ? BtnTitles.ADD_TRANSLATION : BtnTitles.EDIT
            }
            rounded={false}
            clickHandler={() => onEditClickHandler()}
          />
          <IconButton
            icon={<IconDelete />}
            isPrimary={false}
            rounded={false}
            size="small"
            extraClasses="ml-3"
            clickHandler={() => setShowConfirmationModal(true)}
            classes="view-passage-delete-icon"
            shouldDisable={isLangTranslator}
          />
        </div>
      </Footer>
      <ConfirmationModal
        title={Strings.DELETE_PASSAGE_CONFIRMATION}
        show={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        btnText={BtnTitles.DELETE}
        btnLoadingText={Strings.DELETE_LOADING_TITLE}
        confirmHandler={confirmDeleteHandler}
      />
    </Modal>
  );
};

export default ViewPassage;
