import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import "./InputField.css";
import { FieldTypes } from "utils/constants/constants";

const InputField = React.forwardRef((props, forwardRef) => {
  let ref = useRef();
  if (forwardRef) ref = forwardRef;

  const {
    type,
    fieldName,
    title,
    placeholder,
    data = "",
    description = "",
    hasError = false,
    errorMessage = "",
    onChangeHandler,
    disabled = false,
    pattern = "",
    style = { "label-color": "#707070" },
    maxLength = "",
    minLength = "",
    icon = null,
    iconClickHandler,
    keyDownHandler = () => null,
    classes = "",
    iconClasses = "",
    required = false,
    min,
    max,
    addonBefore = "",
    addonAfter = "",
    readOnly = false,
    disableAutoFocus = false,
    hideError = false,
    label = null,
    labelClass = "",
    fontSizerStyle = null,
    onKeyPress,
    className,
    autoComplete = "",
    descriptionClass = "",
  } = props;

  let lStyle = style ? { color: style["label-color"] } : null;
  if (fontSizerStyle) {
    lStyle = { ...lStyle, ...fontSizerStyle };
  }

  const [fieldIcon, setFieldIcon] = useState(icon);

  useEffect(() => {
    if (icon) setFieldIcon(icon);
  }, [icon]);

  let fieldStyle = {};
  if (style && !hasError) {
    try {
      const id = document.getElementById(fieldName);
      if (id) {
        id.style.cssText += `
          border: 1px solid ${style["field-border-color"]} !important;
          color: ${style["text-color"]}
      `;
      }
    } catch (e) {}
  }

  const handleOnInputChange = (e, copyPastedFlag = false) => {
    let rangeSelected = false;
    if (
      e.target.selectionStart === 0 &&
      e.target.selectionEnd === e.target.value.length
    )
      rangeSelected = true;
    if (onChangeHandler) {
      if (copyPastedFlag) {
        let prevValue = data;
        const copyPastedValue = e.clipboardData.getData("Text");
        e.preventDefault();
        if (type === FieldTypes.TYPE_NUMBER) {
          let isValidNumber = new RegExp(/^\d+$/).test(copyPastedValue);
          if (isValidNumber) {
            const event = {
              target: {
                name: e.target.name,
                value: rangeSelected
                  ? copyPastedValue
                  : prevValue !== null
                  ? prevValue + copyPastedValue
                  : copyPastedValue,
              },
            };
            onChangeHandler(event);
          } else {
            const event = {
              target: {
                name: e.target.name,
                value: prevValue,
              },
            };
            onChangeHandler(event);
          }
        } else {
          const event = {
            target: {
              name: e.target.name,
              value: rangeSelected ? copyPastedValue : e.target.value,
            },
          };
          onChangeHandler(event);
        }
      } else onChangeHandler(e);
    }
  };

  useImperativeHandle(forwardRef, (passwordIcon) => ({
    updateIcon(passwordIcon) {
      setFieldIcon("");
      setFieldIcon(passwordIcon);
    },
  }));

  const renderInput = () => {
    return (
      <input
        id={fieldName}
        className={`field-group_field ${className ? className : ""} `}
        autoFocus={!disableAutoFocus}
        type={type}
        placeholder={placeholder}
        name={fieldName}
        value={data}
        onChange={handleOnInputChange}
        onPaste={(e) => handleOnInputChange(e, true)}
        ref={ref}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        style={{ ...fieldStyle }}
        onKeyDown={keyDownHandler}
        min={min}
        max={max}
        readOnly={readOnly}
        onKeyPress={onKeyPress}
        autoComplete={autoComplete}
      />
    );
  };

  return (
    <div
      className={`field-group ${hasError ? "has-error " : ""} ${classes}`}
      style={style && { borderColor: style["field-border-color"] }}
    >
      <label
        className={`field-group_label ${required ? "required" : ""} ${
          title ? "" : "d-none"
        } `}
        style={lStyle}
      >
        <span>
          {title}
          {required && <span className="required-asterisk">*</span>}
        </span>
      </label>
      {description.length > 0 && (
        <span className={`field__text ${descriptionClass}`}>{description}</span>
      )}
      {fieldIcon ? (
        <div className={`has-icon ${iconClasses}`}>
          {renderInput()}
          {fieldIcon ? (
            <span className="icon" onClick={iconClickHandler}>
              {fieldIcon}
            </span>
          ) : null}
        </div>
      ) : (
        <div
          className={addonBefore || addonAfter ? " input-group " : ""}
          style={{ flexWrap: "nowrap" }}
        >
          {addonBefore && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                {addonBefore}
              </span>
            </div>
          )}
          {renderInput()}
          {label ? (
            <label
              title={label}
              htmlFor={fieldName}
              className={`${labelClass}`}
            >
              {label}
            </label>
          ) : null}
          {addonAfter && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                {addonAfter}
              </span>
            </div>
          )}
        </div>
      )}
      {hasError ? (
        <div
          className="error-msg "
          style={style && { color: style["error-color"] }}
        >
          {errorMessage}
        </div>
      ) : hideError ? null : (
        <div
          className="d-none"
          style={style && { color: style["error-color"], height: "16px" }}
        />
      )}
    </div>
  );
});
export default InputField;
