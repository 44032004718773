import React, { useCallback, useEffect, useState } from "react";
import ManageQuestionsListing from "./manage-questions-listing/ManageQuestionsListing";
import { NavBar } from "components/laylouts/nav-bar/NavBar";
import Footer from "components/laylouts/footer/Footer";
import { Pagination } from "components/widgets/pagination/Pagination";
import TextButton from "components/widgets/text-Button/TextButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAuthorsList,
  fetchQuestionList,
  fetchRequestListing,
  getFlyoutMode,
  getQuestionsCount,
  getSelectedGroupQuestionId,
  setFlyoutMode,
  setSelectedActions,
  verifySecretKey,
} from "redux/manage-question/manage-question-slice";
import ManageQuestionAddImport from "./manage-add-question-import/ManageQuestionAddImport";
import Flyout from "components/widgets/flyout/Flyout";
import { Strings } from "redux/manage-question/manage-question-static";
import "./ManageQuestions.css";
import VerifySecretKey from "components/laylouts/manage-secret-key/verify-secret-key/Verifysecretkey";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import {
  getSecretKeyPopupMode,
  setPopupMode,
} from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-slice";
import { Strings as SecretKeyPopupStrings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import {
  closeDrawer,
  globalInstanceData,
  isDrawerOpen,
} from "redux/profile/profile-slice";
import Loader from "components/widgets/loader/Loader";
import {
  fetchAllMastersList,
  fetchLanguages,
  getQuestionLoader,
} from "redux/add-question/add-question-slice";
import {
  getActionsList,
  getStorageItem,
  handleError,
  hasAddEditAccess,
  hasImportAccess,
  notifyError,
  notifySuccess,
  saveItemToStorage,
} from "utils/utility";
import _ from "lodash";
import Drawer from "components/widgets/drawer/Drawer";
import { BackDrop } from "components/widgets/back-drop/BackDrop";
import { reqParams } from "./question-initial-state";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { accessRoleMapView, successStatusCodes } from "utils/constants/common";
import AppConstants from "utils/constants/app-constants";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import { BtnTitles } from "utils/constants/constants";

const ManageQuestions = () => {
  const flyoutMode = useSelector(getFlyoutMode);
  const totalData = useSelector(getQuestionsCount);
  const drawerOpen = useSelector(isDrawerOpen);
  const selectedId = useSelector(getSelectedGroupQuestionId);
  const selectedInstanceData = useSelector(globalInstanceData);
  const loadingStatus = useSelector(getQuestionLoader);
  const popupMode = useSelector(getSecretKeyPopupMode);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("Manage Questions");
  const [searchText, setSearchText] = useState("");
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(null);
  const [showRequestList, setShowRequestList] = useState("");
  const [showQuestionList, setShowQuestionList] = useState(false);
  const [page, setPage] = useState(1);
  const [filterVisible, setFilterVisible] = useState(false);
  const [importFile, setImportFile] = useState("");
  const [isInstanceUser] = useState(
    getStorageItem("isInstanceUser")
      ? getStorageItem("isInstanceUser")
      : false
  );
  const [questionParams, setQuestionParams] = useState({
    instanceId: isInstanceUser ? selectedInstanceData?._id : undefined,
    page: 1,
    perPage: 10,
    isInitialCall: true,
    search: searchText,
  });
  const [showSingleListing, setShowSingleListing] = useState(false);
  const questionListRef = React.createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isPinset = getStorageItem("pinSet");
  const loggedInUserData = getStorageItem("loggedInUserData");
  let isCalledOnce = false;

  const onChangeParams = useCallback((updatedParam) => {
    setQuestionParams((prevState) => ({
      ...prevState,
      ...updatedParam,
    }));
  });

  const handleAuthorizationFailed = (res) => {
    if (
      res?.payload?.statusCode === 403 ||
      res?.payload === Strings.NO_CURRENT_USER
    ) {
      history.push(ROUTES_CONSTANTS.login);
    }
  };

  useEffect(() => {
    dispatch(fetchLanguages(reqParams)).then((res) => {
      handleAuthorizationFailed(res);
      handleError(res, 201);
    });
    dispatch(fetchAllMastersList()).then((res) => {
      handleAuthorizationFailed(res);
      handleError(res, 200);
    });
    dispatch(
      fetchAuthorsList({
        access: accessRoleMapView.Web,
        instanceId: questionParams?.instanceId,
      })
    ).then((res) => {
      handleAuthorizationFailed(res);
      handleError(res, 201);
    });
    fetchQuestionsListBasedOnLocation();
  }, [location?.pathname, selectedInstanceData]);

  useEffect(() => {
    if (!questionParams?.isInitialCall && !isCalledOnce) {
      if (selectedInstanceData) {
        if (
          (showRequestList && showQuestionList) ||
          location?.pathname === ROUTES_CONSTANTS.questionListing ||
          location?.pathname === ROUTES_CONSTANTS.home
        ) {
          getQuestionsListing();
        } else {
          getRequestList(location?.state?.showRequestList || showRequestList);
        }
      }
    }
    if (isCalledOnce) {
      isCalledOnce = false;
    }
  }, [questionParams]);

  const fetchQuestionsListBasedOnLocation = () => {
    switch (location?.pathname) {
      case ROUTES_CONSTANTS.home:
      case ROUTES_CONSTANTS.questionListing:
        setShowRequestList("");
        setShowQuestionList(true);
        dispatch(setSelectedActions(getActionsList("")));
        getQuestionsListing("", 1);
        setTitle("Manage Questions");
        isCalledOnce = true;
        break;
      case ROUTES_CONSTANTS.reviewQuestions:
        setShowRequestList(Strings.REQUEST_FOR_REVIEWER);
        dispatch(
          setSelectedActions(getActionsList(Strings.REQUEST_FOR_REVIEWER))
        );
        setShowQuestionList(false);
        getRequestList(Strings.REQUEST_FOR_REVIEWER, "", 1);
        setTitle("Review Questions");
        isCalledOnce = true;
        break;
      case ROUTES_CONSTANTS.translateQuestions:
        setRequestListAndCallQuestionList(Strings.REQUEST_FOR_TRANSLATOR);
        dispatch(
          setSelectedActions(getActionsList(Strings.REQUEST_FOR_TRANSLATOR))
        );
        getRequestList(Strings.REQUEST_FOR_TRANSLATOR, "", 1);
        setTitle("Translate Questions");
        setShowQuestionList(false);
        isCalledOnce = true;
        break;
      case ROUTES_CONSTANTS.translationReviewQuestions:
        setRequestListAndCallQuestionList(
          Strings.REQUEST_FOR_TRANSLATOR_APPROVER
        );
        dispatch(
          setSelectedActions(
            getActionsList(Strings.REQUEST_FOR_TRANSLATOR_APPROVER)
          )
        );
        getRequestList(Strings.REQUEST_FOR_TRANSLATOR_APPROVER, "", 1);
        setTitle("Translation Review Questions");
        setShowQuestionList(false);
        isCalledOnce = true;
        break;
      case ROUTES_CONSTANTS.approveQuestions:
        setRequestListAndCallQuestionList(Strings.REQUEST_FOR_PUBLISHER);
        dispatch(
          setSelectedActions(getActionsList(Strings.REQUEST_FOR_PUBLISHER))
        );
        getRequestList(Strings.REQUEST_FOR_PUBLISHER, "", 1);
        setTitle("Approve Questions");
        setShowQuestionList(false);
        isCalledOnce = true;
        break;
      case ROUTES_CONSTANTS.reviewQuestionsList:
      case ROUTES_CONSTANTS.translateQuestionsList:
      case ROUTES_CONSTANTS.translationReviewQuestionsList:
      case ROUTES_CONSTANTS.approveQuestionsList:
        getQuestionsListing("", 1);
        isCalledOnce = true;
        break;
      default:
        setRequestListAndCallQuestionList(false);
        setShowQuestionList(true);
        setTitle("Manage Questions");
        break;
    }
  };

  const setRequestListAndCallQuestionList = (str) => {
    setShowRequestList(str);
  };

  const getRequestList = (requestListValue, search, sentPage) => {
    let requestData = _.cloneDeep(questionParams);
    if (requestListValue) {
      requestData[requestListValue] = true;
      setShowRequestList(requestListValue);
    }
    requestData["search"] = search !== undefined ? search : searchText;
    questionParams.isInitialCall = undefined;
    requestData.instanceId = selectedInstanceData?._id;
    if (sentPage || questionParams?.page === 1) {
      requestData["page"] = 1;
      setPage(1);
    }
    setLoader(true);
    dispatch(fetchRequestListing(requestData))
      .then((res) => {
        if (res?.payload?.statusCode !== 201) {
          notifyError(res?.payload?.data?.message);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getQuestionsListing = (search, sentPage) => {
    let requestData = _.cloneDeep(questionParams);
    requestData["search"] = search !== undefined ? search : searchText;
    if (getStorageItem("questionSecureRequestId")) {
      requestData["questionSecureRequestId"] = getStorageItem(
        "questionSecureRequestId"
      );
    }
    let value = getStorageItem("showRequestList") || showRequestList;
    if (
      location.pathname === ROUTES_CONSTANTS.questionListing ||
      location.pathname === ROUTES_CONSTANTS.home
    ) {
      value = "";
    }
    setShowRequestList(value);
    if (value && location.pathname !== ROUTES_CONSTANTS.questionListing) {
      requestData[value] = true;
      setShowSingleListing(true);
      if (getStorageItem("title")) {
        setTitle(getStorageItem("title") + " List");
      } else if (!title.includes("List")) {
        setTitle(title + " List");
      }
    }
    questionParams.isInitialCall = undefined;
    if (
      location?.pathname === ROUTES_CONSTANTS.questionListing ||
      location?.pathname === ROUTES_CONSTANTS.home
    ) {
      requestData["questionSecureRequestId"] = undefined;
    }
    requestData.instanceId = selectedInstanceData?._id;
    if (sentPage || questionParams?.page === 1) {
      requestData["page"] = 1;
      setPage(1);
    }
    setLoader(true);
    dispatch(fetchQuestionList(requestData))
      .then((res) => {
        handleError(res, 201);
        handleAuthorizationFailed(res);
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          setShowQuestionList(true);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const submitSecretKeyHandler = (secretKey) => {
    const options = {
      questionSecureRequestId: selectedId,
      instanceId: selectedInstanceData?._id,
      secureKey: secretKey,
    };
    dispatch(verifySecretKey(options)).then((res) => {
      if (successStatusCodes.includes(res?.payload?.statusCode)) {
        onVerifySecretKey();
        notifySuccess(res?.payload?.data?.message);
      } else {
        notifyError(res?.payload?.data?.message);
      }
    });
  };

  const onVerifySecretKey = () => {
    if (showQuestionList) {
      setShowRequestList(!showRequestList);
    }
    if (!showQuestionList) {
      setShowQuestionList(!showQuestionList);
    }
    closeSecretKeyPopup();
    history.push(location?.pathname + "/questions");
    saveItemToStorage("questionSecureRequestId", selectedId);
    saveItemToStorage("showRequestList", showRequestList);
    saveItemToStorage("title", title);
  };

  const onPageChangeHandler = (pageNumber) => {
    setPage(Number(pageNumber));
    let cloneParams = _.cloneDeep(questionParams);
    cloneParams = {
      ...cloneParams,
      page: Number(pageNumber),
    };
    onChangeParams(cloneParams);
  };

  const addCloseFlyout = (e, val) => {
    e?.preventDefault();
    dispatch(setFlyoutMode({ mode: val }));
  };

  const closeSecretKeyPopup = () => {
    dispatch(setPopupMode(""));
  };

  const addQuestionClickHandler = () => {
    dispatch(setFlyoutMode({ mode: "Add", id: "" }));
    history.push({
      pathname: ROUTES_CONSTANTS.addQuestion,
      state: { mode: "Add", id: "" },
    });
  };

  const onViewGroupQuestions = () => {
    setShowQuestionList(true);
  };

  const onClickImportQuestion = (e) => {
    addCloseFlyout(e, Strings.FLYOUT_MODE_IMPORT);
  };

  const renderAddManageQuestionButtons = () => {
    return (
      <div className="d-flex table_manage_question_footer">
        {hasImportAccess() ? (
          <div className="footer_buttons">
            <TextButton
              title={BtnTitles.IMPORT_QUESTION}
              rounded={false}
              clickHandler={(e) => onClickImportQuestion(e)}
            />
          </div>
        ) : null}
        {hasAddEditAccess() ? (
          <div className="footer_buttons">
            <TextButton
              title={BtnTitles.ADD_QUESTION}
              rounded={false}
              clickHandler={addQuestionClickHandler}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const onDrawerClose = (e) => {
    e?.preventDefault();
    dispatch(closeDrawer());
  };

  const onBackHandler = () => {
    if (showSingleListing) {
      setShowSingleListing(false);
      const updatedLocation = location?.pathname.substring(
        0,
        location?.pathname.length - 10
      );
      history.push(updatedLocation);
    }
  };

  return (
    <div className="pgStructureOne">
      {loggedInUserData &&
      loadingStatus !== AppConstants.LOADING_STATUS.SYNC_INPROGRESS ? (
        <>
          <NavBar
            title={title}
            classes={isPreviewModalOpen ? "manage-question-navbar" : ""}
            shouldDisplayInstanceSelection={
              isInstanceUser && !location.pathname.includes("questions")
                ? true
                : false
            }
            isPinset={isPinset}
            isBack={showSingleListing}
            dirtyFieldData={{ question: true }}
            onBackHandler={onBackHandler}
          />
          <Drawer open={drawerOpen} />
          {drawerOpen ? <BackDrop clickHandler={onDrawerClose} /> : null}
          {flyoutMode === Strings.FLYOUT_MODE_IMPORT ? (
            <BackDrop
              clickHandler={(e) =>
                addCloseFlyout(
                  e,
                  importFile ? Strings?.CONFIRM_CLOSE_IMPORT : ""
                )
              }
            />
          ) : null}
          {filterVisible ? (
            <BackDrop
              clickHandler={() => {
                setFilterVisible(false);
              }}
            />
          ) : null}
          <ManageQuestionsListing
            ref={questionListRef}
            onChangeParams={onChangeParams}
            questionParams={questionParams}
            setQuestionParams={setQuestionParams}
            loader={loader}
            setLoader={setLoader}
            isPreviewModalOpen={isPreviewModalOpen}
            setPreviewModalOpen={setPreviewModalOpen}
            showRequestList={showRequestList}
            setShowRequestList={setShowRequestList}
            showQuestionList={showQuestionList}
            onViewGroupQuestions={onViewGroupQuestions}
            getQuestionsListing={getQuestionsListing}
            getRequestList={getRequestList}
            searchText={searchText}
            setSearchText={setSearchText}
            filterVisible={filterVisible}
            setFilterVisible={setFilterVisible}
            totalRecords={totalData}
            title={title}
          />
          {flyoutMode === Strings.FLYOUT_MODE_IMPORT ||
          flyoutMode === Strings?.CONFIRM_CLOSE_IMPORT ? (
            <Flyout open={true}>
              <ManageQuestionAddImport
                closeFlyoutDocument={addCloseFlyout}
                selectedInstanceData={selectedInstanceData}
                setImportFile={setImportFile}
              />
            </Flyout>
          ) : null}
          <Footer
            class={`manage-question-footer ${
              isPreviewModalOpen ? "manage-question-footer-z-index" : ""
            }`}
          >
            <div>
              <Pagination
                currentPage={page}
                totalRecords={totalData}
                perPage={questionParams?.perPage ?? 10}
                pageChangeHandler={onPageChangeHandler}
              />
            </div>
            {hasAddEditAccess() && !showRequestList
              ? renderAddManageQuestionButtons()
              : null}
          </Footer>
        </>
      ) : (
        <Loader
          textForLoader={
            loadingStatus === AppConstants.LOADING_STATUS.SYNC_INPROGRESS
              ? AppConstants.LOADING_STATUS.SYNC_INPROGRESS
              : undefined
          }
        />
      )}
      <VerifySecretKey
        examKeyPopupVisible={
          popupMode === SecretKeyPopupStrings.POPUP_MODE_VERIFY
        }
        examKeyPopupClose={closeSecretKeyPopup}
        setShowRequestList={setShowRequestList}
        showQuestionList={showQuestionList}
        showRequestList={showRequestList}
        setShowQuestionList={setShowQuestionList}
        closeSecretKeyPopup={closeSecretKeyPopup}
        submitHandler={submitSecretKeyHandler}
      />
      <ConfirmationModal
        show={flyoutMode === Strings?.CONFIRM_CLOSE_IMPORT}
        onCancel={(e) => addCloseFlyout(null, Strings?.FLYOUT_MODE_IMPORT)}
        confirmHandler={(e) => addCloseFlyout(null, "")}
        title={Strings.CONFIRMATION_TITLE}
        btnText={BtnTitles.CONFIRM}
      />
    </div>
  );
};

export default ManageQuestions;
