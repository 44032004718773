import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import "./MenuItem.css";
import { IconProfileMenuArrow } from "assets/svgs/Svgs";
import { closeDrawer } from "redux/profile/profile-slice";

export function MenuItem(props) {
  const { data, allowMultilineDescription = false } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(closeDrawer());
    setTimeout(() => {
      history.push(data?.path);
    }, 500);
  };

  return (
    <div
      key={"menu_item_" + data?._id}
      className="drawer-menu"
      onClick={() => clickHandler()}
    >
      <div className="drawer-menu-item">
        <h3 className="drawer-menu-title">
          {data?.name === "Question Paper Masters"
            ? "Global Question Bank"
            : data?.name}
        </h3>
        <span className="drawer-arrow-icon">
          <IconProfileMenuArrow className="arrow-icon" />
        </span>
      </div>
      <div
        className={` drawer-menu-details ${
          !allowMultilineDescription && "one-line"
        } `}
      >
        {data?.description}
      </div>
    </div>
  );
}
