export const PASSWORD_STATE = {
  NOT_STATED: "Not Stated",
  PASSWORD_MATCH: "Password Match",
  PASSWORD_NOT_MATCH: "Password Not Match",
};

export const END_POINTS_STATICS = {
  ADMIN_KEY: "admin",
  CANDIDATE_KEY: "candidate",
};

export const MANAGE_QUESTIONS_STRINGS = {
  PUBLISHED: "PUBLISHED",
  IN_REVIEW: "IN_REVIEW",
  LANGUAGE_TRANSLATOR: "LANGUAGE_TRANSLATOR",
  TRANSLATOR_REVIEW: "TRANSLATOR_REVIEW",
};

export const accessRoleMapView = {
  Desktop: "Desktop",
  Web: "Web",
};

export const isDesktopView = false;

export const CONFIRMATION_MODES = {
  deleteAnswers: "deleteAnswers",
};

export const notificationDefaultParams = {
  search: "",
  page: 1,
  perPage: 10,
  sort: "DESC",
  sortField: "",
  filterBy: {
    isRead: false,
  },
};

export const NOTIFICATION_TABS = [
  {
    name: "Unread",
    id: 1,
  },
  {
    name: "All",
    id: 2,
  },
];

export const notificationStaticLabels = {
  notifications: "Notifications",
  markAllRead: "Mark All As Read",
  noUnreadLabel: "No unread notifications found",
  noNotificationFound: "No notifications found.",
};

export const successStatusCodes = [200, 201, 202, "200", "201", "202"];
