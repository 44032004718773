import { useHistory } from "react-router";
import { IconBack } from "assets/svgs/Svgs";

export function BackMenu(props) {
  const { dirtyFieldData = {}, onBackHandler } = props;
  const history = useHistory();

  const backMenuClickHandler = () => {
    if (Object.keys(dirtyFieldData).every((k) => !dirtyFieldData[k]) === true) {
      history.goBack();
    } else {
      onBackHandler("fromBackMenu");
    }
  };

  return (
    <div className="backCol" onClick={backMenuClickHandler}>
      <a
        href="##"
        className="linkBack"
        onClick={(e) => {
          e?.preventDefault();
        }}
      >
        {<IconBack />}
      </a>
    </div>
  );
}
