import AppConstants from "utils/constants/app-constants";
import { ApiCaller } from "utils/network/Network";

export async function fetchQuestionListApi(options) {
  return ApiCaller.post(
    AppConstants.manageQuestion.FETCH_QUESTION_LIST,
    options
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchQuestionDetailApi(options) {
  return ApiCaller.post(
    `${AppConstants.manageQuestion.QUESTION_DETAIL}${options?.id}`,
    options?.data
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchQuestionPreviewApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.QUESTION_PREVIEW, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteManyQuestionsApi(options) {
  return ApiCaller.post(
    AppConstants.manageQuestion.DELETE_MANY_QUESTIONS,
    options
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteQuestionApi(options) {
  let url = `${AppConstants.manageQuestion.DELETE_QUESTION}${options?.id}`;
  if (options?.instanceId) {
    url += `?instanceId=${options?.instanceId}`;
  }
  return ApiCaller.delete(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchQuestionDetailDataByInstance(options) {
  const urlToSend = `${
    AppConstants.manageQuestion.QUESTION_DETAIL + `${options?.id}`
  }`;
  return ApiCaller.post(urlToSend, options)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateQuestionStatus(options) {
  const urlToSend = `${AppConstants.manageQuestion.UPDATE_STATUS}`;
  return ApiCaller.patch(urlToSend, options)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchRequestListingApi(options) {
  const urlToSend = `${AppConstants.manageQuestion.GROUP_WISE_LISTING}`;
  return ApiCaller.post(urlToSend, options)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function verifySecretKeyApi(options) {
  return ApiCaller.post(
    AppConstants.manageQuestion.VERIFY_SECRET_KEY_API,
    options
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function importQuestionApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.IMPORT_QUESTION, options)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchAuthorsListApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.GET_AUTHORS_LIST, options)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}
