export const changePasswordInitialState = {
  currentPassword: {
    value: "",
    error: "",
    required: true,
  },
  password: {
    value: "",
    error: "",
    required: true,
    validator: {
      func: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/g.test(value),
      error: "Invalid Password",
    },
  },
  confirmPassword: {
    value: "",
    error: "",
    required: true,
  },
};
