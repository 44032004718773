import React from "react";

export function FormGroup(props) {
  return (
    <div
      className={
        props.className ? props.className : "etrow etrow-md-etcols-2 pt-4"
      }
    >
      {props.children}
    </div>
  );
}
export default FormGroup;
