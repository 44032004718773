import { ApiCaller } from "utils/network/Network";
import AppConstants from "utils/constants/app-constants";

export async function getUserProfile() {
  return ApiCaller.get(`${AppConstants.profile.GET_PROFILE}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateUserProfile(options) {
  return ApiCaller.patch(`${AppConstants.profile.UPDATE_PROFILE}`, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchLoggedInUserInfo() {
  return ApiCaller.get(AppConstants.profile.FETCH_ME)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getInstances(options) {
  return ApiCaller.post(`${AppConstants.instances.GET_ALL_INSTANCES}`, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchPinSetDataApi() {
  return ApiCaller.get(AppConstants.login.GET_PIN_SET)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fireBaseTokenAfterLogin(options) {
  return ApiCaller.post(AppConstants.login.FIREBASE_TOKEN, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteFirebaseToken(options) {
  const { firebaseToken } = options;
  const urlToSend = `${
    AppConstants.login.FIREBASE_TOKEN + `/${firebaseToken}`
  }`;
  return ApiCaller.delete(urlToSend)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getNotificationListApi(options) {
  return ApiCaller.post(`${AppConstants.login.LIST}`, options)
    .then((response) => {
      const data = {
        options: options?.filterBy?.isRead,
        ...response?.data,
      };
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export async function readSingleNoficationApi(id) {
  return ApiCaller.patch(
    `${AppConstants.login.READ_SINGLE_NOTIFICATION}/${id}`,
    id
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function readAllNoficationApi(id) {
  return ApiCaller.patch(`${AppConstants.login.READ_ALL}`, id)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}
