import { NavBar } from "components/laylouts/nav-bar/NavBar";
import InputField from "components/widgets/input-field/InputField";
import { useForm } from "utils/customeHooks/UseForm";
import { EyeCloseIcon, EyeOpenIcon } from "assets/svgs/Svgs";
import { useEffect, useRef, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import TextButton from "components/widgets/text-Button/TextButton";
import MesssagToaster from "components/widgets/msg-toaster/MessageToaster";
import { Auth } from "aws-amplify";
import "./ChangePassword.css";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "utils/utility";
import { doLogout } from "redux/login/login-slice";
import { AUTH_BASE_URL } from "utils/constants/env-constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { changePasswordInitialState } from "./ChangePasswordInitialState";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import { BtnTitles, Strings } from "utils/constants/constants";

export function ChangePassword() {
  const [confirmPasswordMatched, setConfirmPasswordMatched] = useState(true);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [pwdVisibility, setPwdVisibility] = useState({
    currentPassword: true,
    password: true,
    confirmPassword: true,
  });
  const [loading, setLoading] = useState(false);
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const changePasswordHandler = (params = null, error = null) => {
    if (showConfirmationPopup) {
      setLoading(true);
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            values?.currentPassword,
            values?.password
          );
        })
        .then(() => {
          notifySuccess("Password changed successfully");
          dispatch(doLogout({ baseUrlAuth: AUTH_BASE_URL }));
          history.push(ROUTES_CONSTANTS.login);
        })
        .catch((err) => {
          notifyError(err.message);
        })
        .finally(() => {
          setLoading(false);
          setShowConfirmationPopup(false);
        });
    } else {
      if (params !== null) {
        if (!error && confirmPasswordMatched) {
          setShowConfirmationPopup(true);
        }
      }
    }
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit } = useForm(
    changePasswordInitialState,
    changePasswordHandler
  );

  useEffect(() => {
    if (
      values.password !== "" &&
      values.confirmPassword !== "" &&
      values.confirmPassword
    ) {
      setConfirmPasswordMatched(values.password === values.confirmPassword);
    }
  }, [values]);

  const onIconClickHandler = (origin) => {
    setPwdVisibility((prev) => ({
      ...prev,
      [origin]: !prev[origin],
    }));
  };

  return (
    <div className="pgStructureOne">
      <NavBar
        title={Strings.NAVBAR_TITLE_CHANGE_PWD}
        isNotificationShow={true}
        showHome={false}
        isBack={true}
      />
      <div className="contentRowHolder container-fluid cards card center-align-card change-password-card">
        <div className="card__body change-password-card-size">
          <h2 className="change-pass">
            <b>{Strings.CHANGE_PWD_HEADING}</b>
          </h2>
          <p className="card-pswd-text card-pwd-text">
            {Strings.CHANGE_PWD_DESCRIPTION}
          </p>
          <div className="w-100">
            <div className="">
              <InputField
                type={pwdVisibility.currentPassword ? "password" : "text"}
                fieldName={"currentPassword"}
                data={values.currentPassword}
                placeholder={Strings.FIELD_PLACEHOLDER_CURRENT_PWD}
                hasError={errors.currentPassword && dirty.currentPassword}
                errorMessage={errors.currentPassword}
                onChangeHandler={handleOnChange}
                onEnterHandler={handleOnSubmit}
                hasIcon={pwdVisibility.currentPassword}
                icon={
                  pwdVisibility.currentPassword ? (
                    <EyeCloseIcon />
                  ) : (
                    <EyeOpenIcon />
                  )
                }
                iconClickHandler={() => onIconClickHandler("currentPassword")}
                isSearch={false}
                required={true}
                classes={"password-styles"}
              />
            </div>
            <div className="mt-4">
              <InputField
                type={pwdVisibility.password ? "password" : "text"}
                fieldName={"password"}
                placeholder={Strings.FIELD_PLACEHOLDER_PWD}
                data={values.password}
                hasError={errors.password && dirty.password}
                errorMessage={errors.password}
                onChangeHandler={handleOnChange}
                onEnterHandler={changePasswordHandler}
                icon={
                  pwdVisibility.password ? <EyeCloseIcon /> : <EyeOpenIcon />
                }
                isSearch={false}
                iconClickHandler={() => onIconClickHandler("password")}
                required={true}
                classes={"password-styles"}
              />
              <PasswordStrengthBar
                password={values.password}
                className={`password-strength-bar ${
                  errors.password && dirty.password
                    ? "change-password-strength-bar"
                    : "change-password-strength-bar-no-error"
                }`}
                scoreWordClassName="password-strength-bar-score "
              />
            </div>
            <div className="confirm-password-section">
              <InputField
                type={pwdVisibility.confirmPassword ? "password" : "text"}
                fieldName={"confirmPassword"}
                placeholder={Strings.FIELD_PLACEHOLDER_CONFIRM_PWD}
                data={values.confirmPassword}
                hasError={errors.confirmPassword && dirty.confirmPassword}
                errorMessage={errors.confirmPassword}
                onChangeHandler={handleOnChange}
                onEnterHandler={handleOnSubmit}
                icon={
                  pwdVisibility.confirmPassword ? (
                    <EyeCloseIcon />
                  ) : (
                    <EyeOpenIcon />
                  )
                }
                isSearch={false}
                iconClickHandler={() => onIconClickHandler("confirmPassword")}
                required={true}
                classes={"password-styles"}
              />
            </div>
            <MesssagToaster
              showIcon
              IconLabel="error"
              showToaster={!confirmPasswordMatched}
              title={Strings.PASSOWORD_MISMATCH_TITLE}
              className="password-mismatch"
            />
            <div className="mt-4 mb-2">
              <TextButton
                innerRef={submitBtnRef}
                title={BtnTitles.SUBMIT}
                rounded={false}
                clickHandler={handleOnSubmit}
                shouldDisable={loading}
                loading={loading}
                className="change-password-submit"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={showConfirmationPopup}
        title={Strings.PASSWORD_CONFIRMATION_TITLE}
        btnText={BtnTitles.SAVE}
        confirmHandler={changePasswordHandler}
        onCancel={() => {
          setShowConfirmationPopup(false);
        }}
      />
    </div>
  );
}

export default ChangePassword;
