export function FieldGroup(props) {
  const { title, shouldEnclose = true, colSpan = "3", classes } = props;

  return (
    <>
      {shouldEnclose && (
        <div className={`etcol-${colSpan} ${classes}`}>
          <h2 className="header-title">{title}</h2>
          {props.children}
        </div>
      )}
      {!shouldEnclose && (
        <>
          <h2 className="header-title">{title}</h2>
          {props.children}
        </>
      )}
    </>
  );
}
