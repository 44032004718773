import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { getStorageItem, saveItemToStorage } from "utils/utility";
import { userLogoutAPI } from "redux/login/login-slice";

export function Logout(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    signOut();
  }, [dispatch]); // eslint-disable-line

  async function signOut() {
    try {
      dispatch(userLogoutAPI());
      await Auth.signOut().then(() => {
        for (const key in localStorage) {
          if (key !== "firebaseToken") {
            localStorage.removeItem(key);
          }
        }
        const firebaseToken = getStorageItem("firebaseToken");
        saveItemToStorage("firebaseToken", firebaseToken);
        props.history.push({
          pathname: ROUTES_CONSTANTS.login,
          isFromLogoutButton:
            props?.location?.state?.isFromLogoutButton ?? false,
        });
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return <></>;
}
export default Logout;
