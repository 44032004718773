export const ROUTES_CONSTANTS = {
  home: "/",
  myProfile: "/profile",
  login: "/login",
  addQuestion: "/add-question",
  translateQuestion: "/translate-question",
  questionListing: "/question-listing",
  reviewQuestions: "/question-listing/review",
  reviewQuestionsList: "/question-listing/review/questions",
  translateQuestions: "/question-listing/translate",
  translateQuestionsList: "/question-listing/translate/questions",
  translationReviewQuestions: "/question-listing/translate-review",
  translationReviewQuestionsList:
    "/question-listing/translate-review/questions",
  approveQuestions: "/question-listing/approve",
  approveQuestionsList: "/question-listing/approve/questions",
  questions: "/questions",
  manageQuestions: "/manage-questions",
  questionsDetail: "/question-detail",
  changePassword: "/change-password",
  logout: "/logout",
};
