import React, { useEffect, useState } from "react";
import "./Flyout.css";

function Flyout(props) {
  const { open, classes } = props;
  const [openFlyout, setOpenFlyout] = useState("");

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setOpenFlyout("openFlyout");
      }, 30);
    } else {
      setOpenFlyout("");
    }
    return () => {
      clearTimeout(timer);
      setOpenFlyout("");
    };
  }, [open]);

  let className = `flyoutRight ${classes ? classes : ""} ${openFlyout} ${
    open ? "" : "hide"
  }`;
  if (props.className) className += ` ${props.className}`;

  return <div className={className}>{props.children}</div>;
}

export default Flyout;
