export const addQuestionActions = {
  FEATURE_KEY: "add-questions",
  LANGUAGE: "language",
  FETCH_ALL_LANGUAGE: "fetch/language/all",
  FETCH_ALL_MASTERS_LIST: "fetch/masters/list/all",
  FETCH_ALL_SUBJECT: "fetch/subject/all",
  FETCH_ALL_CATEGORY: "fetch/category/all",
  FETCH_ALL_TYPE: "fetch/type/all",
  FETCH_ALL_DIFFICULTY_LEVEL: "fetch/difficulty/level/all",
  ADD_QUESTION: "question/add",
  EDIT_QUESTION: "question/edit",
  FETCH_HISTORY_QUESTION: "question/fetch/history",
  FETCH_AUTHOR_LIST: "fetch/author/list",
  FETCH_QUESTIONSTATUS_LIST: "fetch/question-creation/status/list",
  LANGUAGE_TRANSLATION: "language/translate",
};
