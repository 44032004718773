import FlyoutHeader from "components/widgets/flyout-header/FlyoutHeader";
import "./EditProfileFlyout.css";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import { FlyoutFooter } from "components/laylouts/flyout-footer/FlyoutFooter";
import TextButton from "components/widgets/text-Button/TextButton";
import InputField from "components/widgets/input-field/InputField";
import { useForm } from "utils/customeHooks/UseForm";
import { InputDateField } from "components/widgets/date-field/InputDateField";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess, uploadFile } from "utils/utility";
import { useEffect, useRef } from "react";
import { editUserProfile } from "redux/profile/profile-slice";
import { regex } from "utils/constants/commonRegex";
import { BtnTitles, Strings } from "utils/constants/constants";
import { successStatusCodes } from "utils/constants/common";

export function EditProfileFlyout(props) {
  const { closeFlyoutDocument, profileData } = props;
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (event) => {
      if (event?.code === "Enter" || event?.code === "NumpadEnter") {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const saveButtonClickHandler = (state, error) => {
    if (!error) {
      const bodyData = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        jobTitle: values?.jobTitle,
        DOB: values?.DOB,
        avatar: values?.avatar,
      };
      dispatch(editUserProfile(bodyData)).then((response) => {
        if (successStatusCodes.includes(response?.payload?.statusCode)) {
          notifySuccess("User profile updated successfully.");
          closeFlyoutDocument();
          props?.fetchProfileInfo();
        } else {
          notifyError(response?.payload?.message);
        }
      });
    }
  };

  const profileState = {
    avatar: {
      value: profileData ? profileData?.avatar : "",
      error: "",
      required: false,
      validator: {
        func: (value) => /^.*\.(jpg|jpeg|png)$/.test(value),
        error: "Please select Image",
      },
    },
    firstName: {
      value: profileData ? profileData?.firstName : "",
      error: "",
      required: true,
      validator: {
        func: (value) => /^[^-\s][a-zA-Z .]{2,100}$/g.test(value),
        error: "Invalid first name",
      },
    },
    lastName: {
      value: profileData ? profileData?.lastName : "",
      error: "",
      required: true,
      validator: {
        func: (value) => /^[^-\s][a-zA-Z .]{2,100}$/g.test(value),
        error: "Invalid last name",
      },
    },
    jobTitle: {
      value: profileData ? profileData?.jobTitle : "",
      error: "",
      required: true,
      validator: {
        func: (value) => /^[^-\s][a-zA-Z .]{2,100}$/g.test(value),
        error: "Invalid job title",
      },
    },
    DOB: {
      value: profileData ? profileData?.DOB : "",
      error: "",
      required: true,
    },
  };

  const {
    values,
    errors,
    dirty,
    setValues,
    handleOnChange,
    handleOnSubmit,
    setNewErrorAndDirty,
  } = useForm(profileState, saveButtonClickHandler);

  const callUploadApi = async (e) => {
    const fileSelected = e;
    if (!fileSelected?.name?.match(regex.JPG_PNG_JPEG_GIF)) {
      notifyError(Strings.INVALID_FILE_FORMAT);
      return;
    }
    const size = parseFloat(fileSelected?.size / (1024 * 1024)).toFixed(2);
    let reader = new FileReader();
    await reader?.readAsDataURL(fileSelected);
    reader.onload = function (e) {
      let image = new Image();
      image.src = e?.target?.result;
      image.onload = function async() {
        image.crossOrigin = "";
        imageTest(image.width);
        image.onload = null;
      };

      function imageTest(imageWidthParams) {
        if (imageWidthParams > 600) {
          notifyError("Image width must not exceed 600px.");
          return;
        } else if (size > 2) {
          notifyError("Please select image size less than 2 MB");
          return;
        } else {
          uploadFile(fileSelected, "user")
            .then((response) => {
              if (response?.url)
                setValues((prevState) => ({
                  ...prevState,
                  avatar: response?.url,
                }));
              setNewErrorAndDirty("avatar", "");
            })
            .catch((error) => {
              notifyError(error.message);
            });
          return;
        }
      }
    };
  };

  const handleImgClear = () => {
    setValues((prev) => ({ ...prev, avatar: "" }));
    setNewErrorAndDirty("avatar", "");
  };

  return (
    <>
      <FlyoutHeader
        header={Strings.FLYOUT_HEADING_EDIT_USER_DETAILS}
        closeHandler={closeFlyoutDocument}
      />
      <div className="edit-profile-container">
        <div className="flyoutRight-block">
          <FileInputField
            fieldName={"avatar"}
            title={Strings.FIELD_TITLE_ATTACH_PHOTO}
            description={Strings.IMAGE_DESCRIPTION}
            preview={values.avatar}
            hasError={errors.avatar && dirty.avatar}
            errorMessage={errors.avatar}
            hasPreview={values.avatar}
            onChangeHandler={callUploadApi}
            onImageClearHandler={handleImgClear}
            required={true}
            accepts={"image/png, image/jpeg, image/jpg"}
          />
          <div className="field-group mt-3">
            <InputField
              fieldName={"firstName"}
              title={Strings.FIELD_TITLE_FIRST_NAME}
              type="text"
              placeholder={Strings.FIELD_PLACEHOLDER_FIRST_NAME}
              data={values.firstName}
              onChangeHandler={handleOnChange}
              hasError={errors.firstName && dirty.firstName}
              errorMessage={errors.firstName}
              required={true}
            />
          </div>
          <div className="field-group mt-2">
            <InputField
              type="text"
              fieldName={"lastName"}
              title={Strings.FIELD_TITLE_LAST_NAME}
              placeholder={Strings.FIELD_PLACEHOLDER_LAST_NAME}
              data={values?.lastName}
              onChangeHandler={handleOnChange}
              hasError={errors.lastName && dirty.lastName}
              errorMessage={errors.lastName}
              required={true}
            />
          </div>
          <div className="field-group mt-2">
            <InputField
              type="text"
              fieldName={"jobTitle"}
              title={Strings.FIELD_TITLE_JOB}
              placeholder={Strings.FIELD_PLACEHOLDER_JOB}
              data={values?.jobTitle}
              onChangeHandler={handleOnChange}
              hasError={errors.jobTitle && dirty.jobTitle}
              errorMessage={errors.jobTitle}
              required={true}
            />
          </div>
          <div className="field-group">
            <InputDateField
              fieldName={"DOB"}
              title={Strings.FIELD_TITLE_DOB}
              placeholder={"Date"}
              data={values?.DOB === null ? "Start Date" : values.DOB}
              restrictFutureDates={true}
              onChangeHandler={handleOnChange}
              hasError={errors.DOB && dirty.DOB}
              errorMessage={errors.DOB}
              required={true}
              hasIcon={true}
            />
          </div>
        </div>
      </div>
      <div className="footer-section-profile">
        <FlyoutFooter>
          <TextButton
            innerRef={submitBtnRef}
            title={BtnTitles.SAVE}
            clickHandler={handleOnSubmit}
            rounded={false}
            loading={false}
          />
          <TextButton
            title={BtnTitles.CANCEL}
            rounded={false}
            loading={false}
            isPrimary={false}
            clickHandler={closeFlyoutDocument}
          />
        </FlyoutFooter>
      </div>
    </>
  );
}

export default EditProfileFlyout;
