export function IconButton(props) {
  const {
    icon,
    clickHandler,
    size = "medium",
    rounded = true,
    isPrimary = true,
    loading = false,
    shouldDisable = false,
    extraClasses = "",
    innerRef = null,
    classes,
  } = props;

  const btnSize =
    size === "large" ? "btn--lg" : size === "medium" ? "btn--md" : "btn--sm";

  const handleButtonClick = (e) => {
    if (loading) return;

    if (clickHandler) clickHandler(e);
  };

  return (
    <button
      ref={innerRef}
      className={`btn btn--icon ${btnSize ? btnSize : " "} ${
        rounded ? "btn--rounded" : " "
      } ${!isPrimary ? "btn--alt" : " "} ${
        shouldDisable ? "disabled" : " "
      } ${extraClasses}`}
      onClick={handleButtonClick}
      disabled={shouldDisable}
    >
      <span className={"icon " + classes}>
        {loading ? <div className="spinner"></div> : icon}
      </span>
    </button>
  );
}

export default IconButton;
