import {
  IconDelete,
  IconTableCollapseMinus,
  IconTableCollapsePlus,
} from "assets/svgs/Svgs";
import IconButton from "components/widgets/icon-button/IconButton";
import RenderDetailField from "../render-detail-field/RenderDetailField";
import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import InputEditor from "components/widgets/input-editor/InputEditor";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import TextButton from "components/widgets/text-Button/TextButton";
import {
  handleOnAddTranslation,
  onCancelRemoveTranslation,
} from "../add-question-utility/AddQuestionUtility";
import _ from "lodash";
import { useState } from "react";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import { BtnTitles, Strings } from "utils/constants/constants";

export const QuestionTranslationList = (props) => {
  const {
    languageTranslations,
    setLanguageTranslations,
    isLangReviewer,
    getLanguageOptions,
    onLanguageChangeHandler,
    values,
    setValues,
    errors,
    dirty,
    tinyEditorHandler,
    callUpload,
    handleOnClearOptionImageUpload,
    optionCount,
    renderAddAnswerOptionContainer,
    isLangTranslator,
    selectedQuestion,
    selectedLangArray,
    setSelectedLangArray,
    currentPassage,
  } = props;
  const [showTranslation, setShowTranslation] = useState([
    ...Array(languageTranslations?.length).fill(false),
  ]);
  const [showConfirmRemoveTranslation, setShowConfirmRemoveTranslation] =
    useState({ state: false, language: "" });

  const onClickRemoveTranslationHandler = (
    languageTranslation,
    languageTranslationIndex
  ) => {
    if (showConfirmRemoveTranslation?.state) {
      const clonedLanguageTranslations = _.cloneDeep(languageTranslations);
      const selectedIndex =
        showConfirmRemoveTranslation?.languageTranslationIndex;
      const newLanguageTranslations = clonedLanguageTranslations.filter(
        (_, index) => index !== selectedIndex
      );
      setLanguageTranslations(newLanguageTranslations);
      setSelectedLangArray(
        selectedLangArray?.filter((_, index) => index !== selectedIndex)
      );
      onCancelRemoveTranslation(setShowConfirmRemoveTranslation);
      setShowTranslation(
        showTranslation.filter((_, index) => {
          return index !== selectedIndex;
        })
      );
    } else {
      setShowConfirmRemoveTranslation({
        state: true,
        language: languageTranslation?.language,
        languageTranslationIndex: languageTranslationIndex,
      });
    }
  };

  const toggleShowTranslation = (languageIndex) => {
    const clonedTranslations = _.cloneDeep(showTranslation);
    clonedTranslations[languageIndex] = !clonedTranslations[languageIndex];
    setShowTranslation(clonedTranslations);
  };

  return (
    <>
      <div className="add-translation-button-container d-flex flex-row justify-content-end">
        <TextButton
          title={BtnTitles.ADD_TRANSLATION}
          isPrimary={true}
          rounded={false}
          className="mt-3 "
          clickHandler={() =>
            handleOnAddTranslation(
              languageTranslations,
              values,
              selectedLangArray,
              setSelectedLangArray,
              setLanguageTranslations,
              showTranslation,
              setShowTranslation,
              currentPassage
            )
          }
        />
      </div>
      <div className="add-translation-container ">
        {languageTranslations?.length > 0
          ? languageTranslations?.map((language, languageIndex) => {
              return (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center   mt-3">
                    <div className="d-flex">
                      <span
                        className="cursor-pointer "
                        onClick={() => toggleShowTranslation(languageIndex)}
                      >
                        {showTranslation[languageIndex] ? (
                          <IconTableCollapseMinus />
                        ) : (
                          <IconTableCollapsePlus />
                        )}
                      </span>
                      <span className="translation-title">
                        Language: {language?.language}
                      </span>
                    </div>
                    <div
                      className="d-flex remove-translation  align-items-center"
                      title={"Remove Translation"}
                    >
                      <IconButton
                        icon={<IconDelete />}
                        extraClasses={"btn--padded remove-button"}
                        rounded={false}
                        clickHandler={() => {
                          onClickRemoveTranslationHandler(
                            language,
                            languageIndex
                          );
                        }}
                        loading={false}
                        shouldDisable={false}
                        classes={"remove-icon-delete"}
                      />
                    </div>
                  </div>
                  {showTranslation[languageIndex] ? (
                    <>
                      <div className="d-flex flex-row justify-content-between mt-3">
                        <RenderDetailField
                          label={Strings.FIELD_LABEL_LANGUAGE}
                          inputField={
                            <DropDownField
                              fieldName="language"
                              placeholder={
                                Strings.FIELD_PLACEHOLDER_SELECT_LANGUAGE
                              }
                              options={getLanguageOptions(languageIndex)}
                              data={language?.language}
                              onChangeHandler={(e) => {
                                onLanguageChangeHandler(
                                  language,
                                  languageIndex,
                                  e
                                );
                              }}
                              hasError={errors?.language && dirty?.language}
                              errorMessage={errors?.language}
                              disabled={isLangReviewer}
                            />
                          }
                          value={isLangReviewer ? language?.language : null}
                          required={true}
                        />
                      </div>
                      {isLangTranslator && selectedQuestion?.isGroupPassage ? (
                        <div className="d-flex flex-row mt-3">
                          <RenderDetailField
                            label={Strings.FIELD_PLACEHOLDER_PASSAGE_QUESTION}
                            inputField={
                              <>
                                {isLangReviewer ? (
                                  <>
                                    <p>{language?.passageDetails?.passage}</p>
                                    {language?.passageDetails?.image ? (
                                      <img
                                        src={language?.passageDetails?.image}
                                        alt="question"
                                      />
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    <InputEditor
                                      height={350}
                                      style={{ height: "350px" }}
                                      className="question-title"
                                      onChange={(e) =>
                                        tinyEditorHandler(
                                          e,
                                          "passage",
                                          0,
                                          languageIndex
                                        )
                                      }
                                      data={language?.passageDetails?.passage}
                                      key={"add_question_passage_editor"}
                                      allowResize={false}
                                    />
                                    {currentPassage?.image?.value ? (
                                      <FileInputField
                                        accepts={
                                          "image/png, image/jpeg, image/jpg"
                                        }
                                        fieldName={"passageImage"}
                                        onChangeHandler={(e) =>
                                          callUpload(
                                            isLangTranslator,
                                            languageTranslations,
                                            setLanguageTranslations,
                                            values,
                                            setValues,
                                            e,
                                            "passage",
                                            0,
                                            languageIndex
                                          )
                                        }
                                        hasPreview={
                                          language?.passageDetails?.image?.value
                                        }
                                        preview={
                                          language?.passageDetails?.image?.value
                                        }
                                        shouldDisplayCrossIcon={true}
                                        onImageClearHandler={() => {
                                          handleOnClearOptionImageUpload(
                                            0,
                                            languageIndex,
                                            "passage"
                                          );
                                        }}
                                        urlOnly={true}
                                        name={
                                          language?.passageDetails?.image?.name
                                        }
                                        descriptionOnBox={
                                          Strings?.FILE_FORMAT_PHOTO
                                        }
                                      />
                                    ) : null}
                                  </>
                                )}
                              </>
                            }
                            className="w-100"
                            keyClass="w-100"
                            required={true}
                          />
                        </div>
                      ) : null}
                      <div className="d-flex flex-row mt-3">
                        <RenderDetailField
                          label={Strings.FIELD_PLACEHOLDER_QUESTION_LABEL_TITLE}
                          inputField={
                            <>
                              {isLangReviewer ? (
                                <>
                                  <p>{language?.questionDescription}</p>
                                  {language?.questionImage ? (
                                    <img
                                      src={language?.questionImage}
                                      alt="question"
                                    />
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {values?.questionDescription ? (
                                    <InputEditor
                                      height={350}
                                      style={{ height: "350px" }}
                                      className="question-title"
                                      onChange={(e) =>
                                        tinyEditorHandler(
                                          e,
                                          "",
                                          0,
                                          languageIndex
                                        )
                                      }
                                      data={
                                        language?.details?.questionDescription
                                      }
                                      key={"add_question_editor"}
                                      allowResize={false}
                                    />
                                  ) : null}
                                  {values?.questionImage?.value ? (
                                    <FileInputField
                                      accepts={
                                        "image/png, image/jpeg, image/jpg"
                                      }
                                      fieldName={"questionImage"}
                                      hasError={
                                        errors?.questionImage &&
                                        dirty?.questionImage
                                      }
                                      errorMessage={errors?.questionImage}
                                      onChangeHandler={(e) =>
                                        callUpload(
                                          isLangTranslator,
                                          languageTranslations,
                                          setLanguageTranslations,
                                          values,
                                          setValues,
                                          e,
                                          "questionImage",
                                          0,
                                          languageIndex
                                        )
                                      }
                                      hasPreview={
                                        language?.details?.questionImage?.value
                                      }
                                      preview={
                                        language?.details?.questionImage?.value
                                      }
                                      shouldDisplayCrossIcon={true}
                                      onImageClearHandler={() =>
                                        handleOnClearOptionImageUpload(
                                          0,
                                          languageIndex,
                                          "question"
                                        )
                                      }
                                      urlOnly={true}
                                      name={
                                        language?.details?.questionImage?.name
                                      }
                                      descriptionOnBox={
                                        Strings?.FILE_FORMAT_PHOTO
                                      }
                                    />
                                  ) : null}
                                </>
                              )}
                            </>
                          }
                          className="w-100"
                          keyClass="w-100"
                          required={true}
                        />
                      </div>
                      <div className="d-flex flex-wrap add-answer-field">
                        {[...Array(optionCount)].map((_, index) => {
                          return renderAddAnswerOptionContainer(
                            false,
                            index,
                            languageIndex,
                            language
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                  {languageIndex !== languageTranslations.length - 1 ? (
                    <hr />
                  ) : null}
                </>
              );
            })
          : null}
      </div>
      <ConfirmationModal
        show={showConfirmRemoveTranslation?.state}
        title={`Are you sure you want to remove translation for ${showConfirmRemoveTranslation?.language} language?`}
        confirmHandler={onClickRemoveTranslationHandler}
        btnText={BtnTitles.CONFIRM}
        onCancel={() =>
          onCancelRemoveTranslation(setShowConfirmRemoveTranslation)
        }
      />
    </>
  );
};

export default QuestionTranslationList;
