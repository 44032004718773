import InputField from "components/widgets/input-field/InputField";
import Modal from "components/widgets/modal/Modal";
import TextButton from "components/widgets/text-Button/TextButton";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "utils/customeHooks/UseForm";
import { globalInstanceData } from "redux/profile/profile-slice";
import { useDispatch, useSelector } from "react-redux";
import { updateStatus } from "redux/manage-question/manage-question-slice";
import { notifyError, notifySuccess } from "utils/utility";
import { Strings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import "./AddSecretKey.css";
import { BtnTitles } from "utils/constants/constants";

const AddSecretKey = ({
  secretKeyModal,
  secretKeyModalClose,
  closeSecretKeyPopup,
  onChangeParams,
  actionsIds,
  questionParams,
  setActionIds,
  statusToUpdate,
}) => {
  const [confirmPasswordMatched, setConfirmPasswordMatched] = useState(true);
  const selectedInstanceData = useSelector(globalInstanceData);
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  const stateObj = {
    secretKey: "",
    confirmSecretKey: "",
  };

  const secretKeyState = {
    requestName: {
      value: "",
      error: "",
      required: true,
    },
    secretKey: {
      value: "",
      error: "",
      validator: {
        func: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/g.test(value),
        error: Strings?.FIELD_INVALID_SECRET_KEY,
      },
      required: true,
    },
    confirmSecretKey: {
      value: "",
      error: "",
      required: true,
    },
  };

  useEffect(() => {
    const listener = (event) => {
      if (event?.code === "Enter" || event?.code === "NumpadEnter") {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document?.addEventListener("keydown", listener);
    return () => {
      document?.removeEventListener("keydown", listener);
    };
  }, []);

  const onSubmit = (state, error) => {
    if (!error) {
      if (values?.secretKey !== values?.confirmSecretKey) {
        return;
      }
      const optionsToSend = {
        requestName: values?.requestName,
        instanceId: selectedInstanceData?._id,
        secureKey: values?.secretKey,
        questionIds: actionsIds,
        ...statusToUpdate,
      };
      dispatch(updateStatus(optionsToSend))
        .then((res) => {
          if (res?.payload?.statusCode === 200) {
            notifySuccess(res?.payload?.data?.message);
            onChangeParams(questionParams);
            closeSecretKeyPopup();
            clearFields();
            setActionIds([]);
          } else {
            notifyError(res?.payload?.data?.message);
          }
        })
        .catch((error) => notifyError(error.message))
        .finally(() => closeSecretKeyPopup());
    }
  };

  const {
    values,
    setValues,
    handleOnChange,
    handleOnSubmit,
    errors,
    dirty,
    setErrors,
    clearStateSchema,
  } = useForm(secretKeyState, onSubmit);

  useEffect(() => {
    clearStateSchema();
    setErrors((prev) => ({
      ...prev,
      ...stateObj,
      requestName: "",
    }));
  }, [secretKeyModal]);

  useEffect(() => {
    if (values?.secretKey !== "" && values?.confirmSecretKey !== "") {
      setConfirmPasswordMatched(true);
    } else {
      setConfirmPasswordMatched(!confirmPasswordMatched);
    }
  }, [values]);

  const clearErrorForKeys = () => {
    setErrors((prev) => ({
      ...prev,
      ...stateObj,
    }));
  };

  const clearFields = () => {
    setValues((prev) => ({
      ...prev,
      ...stateObj,
    }));
  };

  const onCloseModalClosePopup = () => {
    secretKeyModalClose();
    clearErrorForKeys();
    clearFields();
  };

  return (
    <Modal show={secretKeyModal}>
      <div className="my-1">
        <InputField
          fieldName="requestName"
          data={values?.requestName}
          onChangeHandler={handleOnChange}
          hasError={errors.requestName && dirty.requestName}
          errorMessage={errors.requestName}
          type={"text"}
          title={Strings.FIELD_TITLE_REQUEST_NAME}
          required={true}
          maxLength={20}
          className="verify_key_input"
        />
      </div>
      <div className="my-2">
        <InputField
          fieldName="secretKey"
          data={values?.secretKey}
          onChangeHandler={handleOnChange}
          hasError={errors.secretKey && dirty.secretKey}
          errorMessage={errors.secretKey}
          type="text"
          title={Strings.FIELD_TITLE_SECRET_KEY}
          required={true}
          maxLength={20}
          className="verify_key_input"
          disableAutoFocus={true}
          descriptionClass={"secret-key-description"}
          description={Strings?.SECRET_KEY_PATTERN_DESCRIPTION}
        />
      </div>
      <div className="my-2">
        <InputField
          fieldName="confirmSecretKey"
          data={values?.confirmSecretKey}
          onChangeHandler={handleOnChange}
          hasError={errors.confirmSecretKey && dirty.confirmSecretKey}
          errorMessage={errors.confirmSecretKey}
          type="text"
          title={Strings.FIELD_TITLE_CONFIRM_SECRET_KEY}
          maxLength={20}
          required={true}
          className="verify_key_input"
          disableAutoFocus={true}
        />
      </div>
      {values?.secretKey &&
      values?.confirmSecretKey &&
      values?.secretKey !== values?.confirmSecretKey ? (
        <span className="confirm-key-error-msg ">
          {Strings?.FIELD_ERROR_CONFIRM_SECRET_KEY}
        </span>
      ) : null}

      <div className="d-flex justify-content-between my-3">
        <TextButton
          title={BtnTitles.CANCEL}
          isPrimary={false}
          rounded={false}
          clickHandler={onCloseModalClosePopup}
        />
        <TextButton
          title={BtnTitles.SUBMIT}
          innerRef={submitBtnRef}
          rounded={false}
          clickHandler={() => handleOnSubmit()}
        />
      </div>
    </Modal>
  );
};

export default AddSecretKey;
