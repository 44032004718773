import axios from "axios";
import { Auth } from "aws-amplify";
import { API_URL } from "utils/constants/Routes/api";
import { saveItemToStorage } from "utils/utility";
import CryptoJS from "crypto-js";
import { userLoginRefreshCall } from "redux/login/login-api";
const encryptionKey = "qpa-encryption-admin";

export const decryptLocalData = (encryptedData, key) => {
  if (encryptedData && encryptedData !== "" && encryptedData !== "null") {
    let bytes = CryptoJS.AES.decrypt(encryptedData, key);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
  return encryptedData;
};

export const getStorageItem = (key) => {
  let encryptedData = localStorage.getItem(key);
  let decryptedData = decryptLocalData(encryptedData, encryptionKey);
  return decryptedData;
};

export const ApiCaller = axios.create({
  baseURL: API_URL,
  headers: {
    instanceid: getStorageItem("globalInstanceData")
      ? getStorageItem("globalInstanceData")?._id
      : "",
    idtoken: getStorageItem("idToken") ? getStorageItem("idToken") : "",
    // "ngrok-skip-browser-warning": "true",
  },
});

ApiCaller.interceptors.request.use(
  (config) => {
    const accessToken = getStorageItem("accessToken");
    const idtoken = getStorageItem("idToken");
    const instanceId = getStorageItem("globalInstanceData")
      ? getStorageItem("globalInstanceData")?._id
      : "";
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    if (instanceId) {
      config.headers["instanceid"] = instanceId;
    }
    if (idtoken) {
      config.headers["idtoken"] = idtoken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ApiCaller.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return new Promise((resolve, reject) => {
      const originalRequest = error?.config;
      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !originalRequest?._retry
      ) {
        // tooltip("Sorry you do not have permission to access this feature");
        if (error?.response?.status === 401) {
          // notifyError(
          //   "Sorry you do not have permission to access this feature"
          // );
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 2000);
        } else {
          originalRequest._retry = true;
          getAccessJwtToken().then(
            (res) => {
              let accessToken = res?.getAccessToken()?.getJwtToken();
              let idToken = res?.getIdToken()?.getJwtToken();
              saveItemToStorage("accessToken", accessToken);
              saveItemToStorage("idToken", idToken);
              ApiCaller.defaults.headers["Authorization"] =
                "Bearer " + accessToken;
              ApiCaller.defaults.headers["idtoken"] = idToken;
              userLoginRefreshCall();
              resolve(ApiCaller(originalRequest));
            },
            (err) => reject(err)
          );
        }
      } else {
        reject(error?.response);
      }
    });
  }
);

const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  if (!getStorageItem("accessToken")) {
    if (window.location.href.includes("home")) {
      window.location.href = "/question-listing";
    } else {
      window.location.href = "/login";
    }
    return;
  }
  const session = await Auth.currentSession();
  return session;
};
