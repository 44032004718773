import { useEffect, useState } from "react";
import "./RejectReasonModal.css";
import Modal from "components/widgets/modal/Modal";
import InputField from "components/widgets/input-field/InputField";
import TextButton from "components/widgets/text-Button/TextButton";
import { BtnTitles, Strings } from "utils/constants/constants";

export function RejectReasonModal(props) {
  const {
    title = "",
    reasons = false,
    loading,
    shouldDisable,
    show,
    primaryBtnName = "",
    secondaryBtnName = "",
    confirmBtnPrimary = false,
    cancelBtnPrimary = true,
    closeSecretKeyPopup,
    onSubmit,
  } = props;
  const [reason, setReason] = useState("");

  const handleOnChange = (e) => {
    setReason(e?.target?.value);
  };

  useEffect(() => {
    setReason("");
  }, [show]);

  return (
    <Modal
      show={show}
      closeHandler={closeSecretKeyPopup}
      modalContentClassName="reject-reason-modal"
    >
      <h2 className="popup-confirm-title">{title}</h2>
      {reasons ? (
        <div className="field-group mt-4">
          <div className="single-select">
            <InputField
              fieldName={"reason"}
              defaultValue={"Select Reason"}
              onChangeHandler={handleOnChange}
              data={reason}
              className="w-100"
            />
          </div>
        </div>
      ) : null}
      <div className="d-flex justify-content-between mt-3">
        <TextButton
          isPrimary={confirmBtnPrimary}
          title={primaryBtnName ? primaryBtnName : "Cancel"}
          loading={loading}
          loadingTitle={Strings.CONFIRMING_LOADING_TITLE}
          rounded={false}
          clickHandler={closeSecretKeyPopup}
        />
        <TextButton
          isPrimary={cancelBtnPrimary}
          title={secondaryBtnName ? secondaryBtnName : BtnTitles.CONFIRM}
          shouldDisable={shouldDisable}
          rounded={false}
          clickHandler={() => {
            onSubmit({ rejectedReason: reason });
          }}
        />
      </div>
    </Modal>
  );
}

export default RejectReasonModal;
