import { IconCloseFlyout } from "assets/svgs/Svgs";
import "./FlyoutHeader.css";

function FlyoutHeader(props) {
  const {
    header,
    closeIconPurpleRequired = true,
    className = "",
    closeHandler,
  } = props;

  return (
    <div className={`flyoutRight__head ${className}`}>
      <h2>
        <b>{header}</b>
      </h2>
      <a className="close mr-2" onClick={closeHandler}>
        {<IconCloseFlyout closeIconPurpleRequired={closeIconPurpleRequired} />}
      </a>
    </div>
  );
}

export default FlyoutHeader;
