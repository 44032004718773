import Footer from "components/laylouts/footer/Footer";
import TextButton from "components/widgets/text-Button/TextButton";
import { BtnTitles } from "utils/constants/constants";
import "views/add-question/AddQuestion.css";

export const AddQuestionFooter = (props) => {
  const {
    openPreviewPopup,
    clearQuestionData,
    handleOnSubmit,
    loading,
    isLangTranslator,
    submitBtnRef,
  } = props;

  return (
    <Footer isPagination={true} class="add-question-footer">
      <div>
        <TextButton
          title={BtnTitles.PREVIEW}
          isPrimary={true}
          rounded={false}
          size="small"
          clickHandler={() => openPreviewPopup()}
          shouldDisable={loading}
        />
      </div>
      <div
        className={`add-question-right-footer d-flex gap-1 ${
          !isLangTranslator
            ? " justify-content-between"
            : " justify-content-end"
        }`}
      >
        {!isLangTranslator && (
          <TextButton
            title={BtnTitles.CLEAR}
            isPrimary={false}
            rounded={false}
            size="small"
            clickHandler={clearQuestionData}
          />
        )}
        <TextButton
          title={BtnTitles.SAVE}
          innerRef={submitBtnRef}
          isPrimary={true}
          rounded={false}
          size="small"
          clickHandler={handleOnSubmit}
          loading={loading}
          loadingTitle="Saving"
        />
      </div>
    </Footer>
  );
};

export default AddQuestionFooter;
