export const passageActions = {
  FEATURE_KEY: "passage",
  ADD_PASSAGE: "passage/add/new",
  EDIT_PASSAGE: "passage/edit",
  PASSAGE_DETAIL: "passage/detail",
  FETCH_ALL_PASSAGE: "passage/fetch/all",
  DELETE_PASSAGE: "passage/delete",
  PASSAGE_FLYOUT_MODE: "passage/flyout/mode",
  PASSAGE_SELECTED: "passage/selected",
  PASSAGE_SELECTED_ID: "passage/selected/id",
  SET_FLYOUT_MODE: "passage/delete/answer/confirmation/flyout",
};
