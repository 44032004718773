import { useEffect, useRef, useState } from "react";
import "./table.css";
import _ from "lodash";
import { IconDownArrow, IconRightArrow } from "assets/svgs/Svgs";
import InputField from "components/widgets/input-field/InputField";
import Loader from "components/widgets/loader/Loader";
import { InputSelect } from "components/widgets/input-select/InputSelect";

export function Table(props) {
  const {
    data = null,
    columns = null,
    count,
    onChangeData,
    loader = false,
    width,
    class: className,
    isCollapsibleTable,
    collasedRowDetailsToShow,
    outerTableClass = "",
    childCheckBoxChecked,
    setChildCheckBoxChecked,
    setCheckedState = () => {},
    shouldDisableChildBox = false,
  } = props;

  const ref = useRef();
  const [rowData, setRowData] = useState(data);
  const [page, setPage] = useState(1);

  const [dataToParent, setDataToParent] = useState({
    numberOfEntriesToShow: props?.numberOfEntriesToShow
      ? props?.numberOfEntriesToShow
      : 10,
  });

  const numberOfEntries = [10, 20, 30, 40, 50, 100];

  const handleOnChangeEntries = (e) => {
    const num = parseInt(e?.target?.value, 10);
    if (props?.onNoOfEntriesChange) {
      props?.onNoOfEntriesChange(num);
    }
    setDataToParent((prev) => ({
      ...prev,
      numberOfEntriesToShow: num,
    }));
  };

  useEffect(() => {
    if (count !== ref.current) {
      setPage(1);
    }
    ref.current = count;
    setRowData(data);
  }, [data]);

  const allCommonChangeHandler = (cloneFields, isSetPage, pageToSet) => {
    setDataToParent({ ...cloneFields });
    onChangeData({ ...cloneFields });

    if (isSetPage) {
      setPage(parseInt(pageToSet, 10));
    }
  };

  //this will usefull for pagination
  const onPageChangeHandler = (pageNumber) => {
    let cloneFields = _.cloneDeep(dataToParent);
    cloneFields = {
      ...cloneFields,
      page: pageNumber,
    };
    allCommonChangeHandler(cloneFields, true, pageNumber);
  };

  const rowCollapseChange = (index) => {
    let dataToModify = _.cloneDeep(rowData);
    dataToModify[index].isCollapse = !dataToModify[index].isCollapse;
    setRowData(dataToModify);
  };

  const handleParentCheckBoxChange = (e) => {
    if (e?.target?.checked) {
      setCheckedState(new Array(data?.length).fill(true));
    } else {
      setCheckedState(new Array(data?.length).fill(false));
    }
    setChildCheckBoxChecked(!childCheckBoxChecked);
  };

  return (
    <>
      <div className="h-100 mx-5">
        <div className={`outer_table_class ${outerTableClass} `}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between py-3 bg-white table_upper_div_entries_search w-100">
              <div className="d-flex align-items-center ml-3 ">
                <span className="mx-2 fs-14 mt-1 et_table_show_entries">
                  Show
                </span>
                <span className="w-50 show_entries_input">
                  <InputSelect
                    name="entries"
                    options={numberOfEntries}
                    defaultValue={"Select Entries"}
                    value={dataToParent?.numberOfEntriesToShow}
                    onChangeHandler={handleOnChangeEntries}
                  />
                </span>

                <span className="mx-2 mt-1 fs-14 et_table_show_entries">
                  entries
                </span>
              </div>
              {props?.renderRightSideContent ? (
                <div>{props?.renderRightSideContent()}</div>
              ) : null}
            </div>
          </div>
          {!loader ? (
            rowData?.length > 0 ? (
              <div className="table_cover_div">
                <table
                  className="px-4 custom_table"
                  id="et-table"
                  style={{
                    width: width ? width + "%" : "100%",
                  }}
                >
                  <thead className="table_header">
                    <tr>
                      {columns?.length > 0
                        ? columns.map((head) => {
                            return (
                              <th
                                className="position-relative border-0"
                                style={{
                                  width: head?.customHeaderWidth
                                    ? head?.customHeaderWidth + "%"
                                    : "",
                                }}
                              >
                                <span className="px-2 mx-3">
                                  {head?.actionInField ? (
                                    <div className="action_field_checkbox">
                                      <input
                                        type="checkbox"
                                        checked={childCheckBoxChecked}
                                        onChange={(e) => {
                                          handleParentCheckBoxChange(e);
                                        }}
                                        disabled={shouldDisableChildBox}
                                      />
                                    </div>
                                  ) : (
                                    <div className={`px-2 mx-3`}>
                                      <div
                                        className={`fs-14 column_data ${
                                          head?.field === "actions"
                                            ? "actions_section_container"
                                            : false
                                        }`}
                                      >
                                        {head?.header.toUpperCase()}
                                      </div>
                                    </div>
                                  )}
                                </span>
                              </th>
                            );
                          })
                        : null}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData?.length > 0
                      ? rowData?.map((row, parentIndex) => (
                          <>
                            <tr>
                              {columns?.map((col, index) => (
                                <td
                                  className={`position-relative ${
                                    col?.tdClass ?? ""
                                  }`}
                                >
                                  <span
                                    className={`px-2 ml-3 max_col_width ${
                                      col.upperClass || ""
                                    }`}
                                  >
                                    {col?.shouldRenderElement ? (
                                      col.renderElement(
                                        row[col?.field],
                                        parentIndex,
                                        row
                                      )
                                    ) : col?.actionInField ? (
                                      <div className="action_field_checkbox">
                                        <InputField type={"checkBox"} />
                                      </div>
                                    ) : col?.icons ? (
                                      <div className="d-flex align-items-center">
                                        {col?.icons.map((singleIcon) => (
                                          <span
                                            className={`action_icons ${col.class}`}
                                            onClick={() =>
                                              singleIcon?.onClick(
                                                row?._id,
                                                "",
                                                parentIndex
                                              )
                                            }
                                          >
                                            {singleIcon.icon}
                                          </span>
                                        ))}
                                      </div>
                                    ) : col?.colForCollapse ? (
                                      <div className="d-flex align-items-center">
                                        {col?.colForCollapse.map(
                                          (singleIcon) => (
                                            <span
                                              className={`action_icons ${singleIcon.class}`}
                                              onClick={() =>
                                                rowCollapseChange(parentIndex)
                                              }
                                            >
                                              {!row?.isCollapse ? (
                                                <span className="table_collapse_plus_icon">
                                                  <IconRightArrow />
                                                </span>
                                              ) : (
                                                <span className="table_collapse_minus_icon">
                                                  <IconDownArrow />
                                                </span>
                                              )}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    ) : col?.rating ? (
                                      <span className="d-flex justify-content-start"></span>
                                    ) : col?.status ? (
                                      <span className="fs-14 column_data">
                                        {col?.statusColorsRequired &&
                                        row[col?.field] ? (
                                          <span className="text-success">
                                            {col?.statusSuccessText}
                                          </span>
                                        ) : (
                                          <span className="text-danger">
                                            {col?.statusDangerText}
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        className={`fs-14 column_data  ${
                                          col.class
                                        } ${
                                          col?.customAlignment
                                            ? col?.classesRequiredForCustomAlignment
                                            : ""
                                        }`}
                                        title={row[col.field]}
                                      >
                                        {row[col.field]?.length > 0 ||
                                        row[col.field] >= 0
                                          ? row[col.field]
                                          : "-"}
                                      </span>
                                    )}
                                  </span>
                                </td>
                              ))}
                            </tr>

                            {isCollapsibleTable && row?.isCollapse ? (
                              <tr className="border-0">
                                <td colSpan={9}>
                                  {collasedRowDetailsToShow(row)}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            ) : null
          ) : (
            <Loader />
          )}
          {rowData?.length > 0 ? null : (
            <div className="text-center">No data</div>
          )}
        </div>
      </div>
    </>
  );
}
