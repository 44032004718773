import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { loginAction } from "./login-actions";
import { profileData } from "utils/constants/constants";
import axios from "axios";
import AppConstants from "utils/constants/app-constants";
import {
  createPinApi,
  fetchPinSetApi,
  fetchPublickKeyApi,
  updatePinApi,
  userLoginApiCall,
  userLogoutApiCall,
} from "./login-api";
import { saveItemToStorage } from "utils/utility";

export const loginAdapter = createEntityAdapter();

export const doLogout = createAsyncThunk(
  loginAction.DO_LOGOUT,
  async ({ baseUrlAuth }, { rejectWithValue }) => {
    try {
      const response = await axios.get(baseUrlAuth + AppConstants.LOGOUT);
      if (response.status === 200) {
        return response?.data?.payload;
      } else {
        return rejectWithValue({}, response?.data?.message);
      }
    } catch (err) {
      return rejectWithValue({}, err);
    }
  }
);

export const fetchPublicKey = createAsyncThunk(
  loginAction.FETCH_PUBLIC_KEY,
  async (options) => {
    const response = await fetchPublickKeyApi(options);
    return response;
  }
);

export const fetchPinSet = createAsyncThunk(
  loginAction.FETCH_PIN_SET,
  async (options) => {
    const response = await fetchPinSetApi(options);
    return response;
  }
);

export const createPin = createAsyncThunk(
  loginAction.CREATE_PIN,
  async (options) => {
    const response = await createPinApi(options);
    return response;
  }
);

export const updatePin = createAsyncThunk(
  loginAction.UPDATE_PIN,
  async (options) => {
    const response = await updatePinApi(options);
    return response;
  }
);

export const userLoginAPI = createAsyncThunk(
  loginAction.USER_LOGIN,
  async () => {
    const response = await userLoginApiCall();
    return response;
  }
);

export const userLogoutAPI = createAsyncThunk(
  loginAction.USER_LOGOUT,
  async () => {
    const response = await userLogoutApiCall();
    return response;
  }
);

const loginInitialState = loginAdapter.getInitialState({
  userData: profileData[0],
  profileDetails: null,
  flyout: "",
  publicKey: "",
  pinSet: false,
});

export const loginSlice = createSlice({
  name: loginAction.MAIN,
  initialState: loginInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicKey.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchPublicKey.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.publicKey = action?.payload?.data?.publicKey;
        saveItemToStorage("publicKey", action?.payload?.data?.publicKey);
      })
      .addCase(fetchPublicKey.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchPinSet.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchPinSet.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.pinSet = action?.payload?.data?.pinSet;
        saveItemToStorage("pinSet", state.pinSet);
      })
      .addCase(fetchPinSet.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      });
  },
});

export const loginReducer = loginSlice.reducer;
export const getLoginState = (rootState) => rootState[loginAction.MAIN];

export const getUserDetails = createSelector(
  getLoginState,
  (state) => state?.userData
);

export const getPublicKey = createSelector(
  getLoginState,
  (state) => state?.publicKey
);

export const getPinSet = createSelector(
  getLoginState,
  (state) => state?.pinSet
);
