import { regex } from "utils/constants/commonRegex";
import { Strings, passageStrings } from "utils/constants/constants";

export const passageState = {
  language: {
    value: "",
    error: "",
    required: true,
  },
  title: {
    value: "",
    error: "",
    required: true,
    validator: {
      func: (value) => regex?.ALPHABETS_AND_NUMERIC?.test(value),
      error: Strings?.ALPHA_NUMERIC_ERROR,
    },
  },
  noOfQuestion: {
    value: "",
    error: "",
    required: true,
    validator: {
      func: (value) => /^(([01]?[0-9])|(20))$/?.test(value),
      error: passageStrings?.NO_OF_QUESTIONS,
    },
  },
  passage: {
    value: "",
    error: "",
  },
  image: {
    value: { value: "", name: "" },
    error: "",
  },
};

export const defaultImgState = {
  name: "",
  value: "",
};
