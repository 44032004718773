import InputEditor from "components/widgets/input-editor/InputEditor";
import RenderDetailField from "../render-detail-field/RenderDetailField";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import { IconEye, IconPlus } from "assets/svgs/Svgs";
import {
  BtnTitles,
  Strings,
  roleTypeNameStrings,
} from "utils/constants/constants";
import { Radio } from "components/widgets/radio/Radio";
import AddAnswerOptionContainer from "../add-answer-option-container/AddAnswerOptionContainer";
import TextButton from "components/widgets/text-Button/TextButton";
import InputField from "components/widgets/input-field/InputField";
import { Checkbox } from "components/widgets/checkbox/Checkbox";
import CustomDropDown from "components/widgets/custom-drop-down/CustomDropDown";
import { callUpload } from "../add-question-utility/AddQuestionUtility";
import _ from "lodash";
import QuestionTranslationList from "../question-translation-list/QuestionTranslationList";

export const AddQuestionContainer = ({
  selectedQuestion,
  questionId,
  values,
  setValues,
  errors,
  isLangTranslator,
  handleOnSelection,
  subjectWiseCategoryOptions,
  levelsOptions,
  languageOptions,
  passageOptions,
  handleOnChange,
  tinyEditorHandler,
  roleName,
  handleOnClearImageUpload,
  handleOnRadioChange,
  languageTranslations,
  isLangReviewer,
  getLanguageOptions,
  onLanguageChangeHandler,
  handleOnClearOptionImageUpload,
  optionLabel,
  multiCurrectOption,
  onClickCheckboxHandler,
  answerOptionNames,
  currectOption,
  setCurrectOption,
  onClickMinusIcon,
  dirty,
  optionCount,
  onClickAddAnswer,
  subjectsOptions,
  typesOptions,
  onClickAddPassageHandler,
  onClickViewHandler,
  selectedLangArray,
  setSelectedLangArray,
  setLanguageTranslations,
  isEditMode = false,
  currentPassage,
}) => {
  const renderAddAnswerOptionContainer = (
    showQuestionOptions = false,
    index = 0,
    languageIndex = 0,
    language = {}
  ) => {
    return (
      <AddAnswerOptionContainer
        index={index}
        values={values}
        optionLabel={optionLabel}
        multiCurrectOption={multiCurrectOption}
        currectOption={currectOption}
        setCurrectOption={setCurrectOption}
        onClickMinusIcon={onClickMinusIcon}
        tinyEditorHandler={tinyEditorHandler}
        errors={errors}
        dirty={dirty}
        handleOnClearOptionImageUpload={handleOnClearOptionImageUpload}
        isLangTranslator={isLangTranslator}
        languageIndex={languageIndex}
        language={language}
        optionCount={optionCount}
        showLanguageTranslation={true}
        languageTranslations={languageTranslations}
        setLanguageTranslations={setLanguageTranslations}
        setValues={setValues}
        showQuestionOptions={showQuestionOptions}
      />
    );
  };

  return (
    <div className="add-question-container">
      <span className="question-listing-header ">Question Details</span>
      <div className="fs-14 d-flex flex-column mt-3">
        <div className="d-flex flex-row justify-content-between ">
          <RenderDetailField
            label="ID"
            inputField={
              !isEditMode || selectedQuestion?.isAdd
                ? "-"
                : selectedQuestion?.questionId ?? questionId
            }
          />
          <RenderDetailField
            label={Strings.FIELD_LABEL_SUBJECT}
            inputField={
              <DropDownField
                fieldName="subject"
                placeholder={Strings.FIELD_PLACEHOLDER_SELECT_SUBJECT}
                options={subjectsOptions}
                data={values?.subject}
                onChangeHandler={handleOnSelection}
                hasError={errors?.subject && dirty?.subject}
                errorMessage={errors?.subject}
                disabled={isLangTranslator}
              />
            }
            value={values?.subject}
            required={true}
          />
          <RenderDetailField
            label={Strings.FIELD_LABEL_CATEGORY}
            inputField={
              <DropDownField
                fieldName="category"
                placeholder={Strings.FIELD_PLACEHOLDER_SELECT_CATEGORY}
                options={subjectWiseCategoryOptions}
                data={values?.category}
                onChangeHandler={handleOnSelection}
                hasError={errors?.category && dirty?.category}
                errorMessage={errors?.category}
              />
            }
            value={
              isLangTranslator
                ? values?.category
                  ? values?.category
                  : "-"
                : values?.category
            }
            required={true}
          />
          <RenderDetailField
            label={Strings.FIELD_LABEL_TYPE}
            inputField={
              <DropDownField
                fieldName="type"
                placeholder={Strings.FIELD_PLACEHOLDER_SELECT_TYPE}
                options={typesOptions}
                data={values?.type}
                onChangeHandler={handleOnSelection}
                hasError={errors?.type && dirty?.type}
                errorMessage={errors?.type}
                disabled={isLangTranslator}
              />
            }
            value={values?.type}
            required={true}
          />
        </div>
        <div className="d-flex flex-row mt-3 justify-content-between ">
          <RenderDetailField
            label={Strings.FIELD_LABEL_DIFFICULTY_LEVEL}
            inputField={
              <DropDownField
                fieldName="level"
                placeholder={Strings.FIELD_PLACEHOLDER_DIFFICULTY_LEVEL}
                options={levelsOptions}
                data={values?.level}
                onChangeHandler={handleOnSelection}
                hasError={errors?.level && dirty?.level}
                errorMessage={errors?.level}
                disabled={isLangTranslator}
              />
            }
            value={values?.level}
            required={true}
          />
          <RenderDetailField
            label={Strings.FIELD_LABEL_LANGUAGE}
            inputField={
              <DropDownField
                fieldName="language"
                placeholder={Strings.FIELD_PLACEHOLDER_SELECT_LANGUAGE}
                options={languageOptions}
                data={values?.language}
                onChangeHandler={handleOnSelection}
                hasError={errors?.language && dirty?.language}
                errorMessage={errors?.language}
                disabled={isLangTranslator}
              />
            }
            value={values?.language}
            required={true}
          />
          <RenderDetailField
            label={Strings.FIELD_LABEL_GROUP_QUESTION}
            inputField={
              <div
                className={`d-flex flex-row   ${
                  isLangTranslator ? "w-80 justify-content-between" : "w-100"
                }`}
              >
                {!isLangTranslator ? (
                  <DropDownField
                    fieldName="groupQuestion"
                    placeholder={Strings.FIELD_PLACEHOLDER_SELECT_PASSAGE}
                    options={passageOptions}
                    data={values?.groupQuestion}
                    onChangeHandler={handleOnSelection}
                    hasError={errors?.groupQuestion && dirty?.groupQuestion}
                    errorMessage={errors?.groupQuestion}
                    classes={"group-question"}
                    disabled={isLangTranslator}
                  />
                ) : (
                  <span className="text-wrap-ellipsis w-40">
                    {selectedQuestion?.isGroupPassage ? "Yes" : "No"}
                  </span>
                )}
                {!isLangTranslator &&
                roleName === roleTypeNameStrings.USER_ROLE_AUTHOR ? (
                  <span
                    className={`icon-plus ${
                      !isLangTranslator ? "icon-plus-group" : ""
                    } `}
                    onClick={onClickAddPassageHandler}
                    title={Strings.FIELD_TITLE_ADD_PASSAGE_TRANSLATION}
                  >
                    <IconPlus className="icon-plus-svg" />
                  </span>
                ) : null}
                {!isLangTranslator ? (
                  <span
                    className="icon-eye-group cursor-pointer"
                    onClick={onClickViewHandler}
                  >
                    <IconEye />
                  </span>
                ) : null}
              </div>
            }
            isPlusIcon={true}
            valuesClass={"extend-area"}
          />
          <RenderDetailField
            label={Strings.FIELD_PLACEHOLDER_MARKS}
            inputField={
              <InputField
                fieldName="marks"
                type="text"
                placeholder={Strings.FIELD_PLACEHOLDER_MARKS}
                data={values?.marks}
                onChangeHandler={handleOnChange}
                hasError={errors?.marks && dirty?.marks}
                errorMessage={errors?.marks}
                disabled={isLangTranslator}
                maxLength={3}
              />
            }
            value={"100"}
            required={true}
          />
        </div>
        <div className="d-flex flex-row mt-3 justify-content-between ">
          <RenderDetailField
            label={Strings.FIELD_PLACEHOLDER_NEGATIVE_MARKING}
            inputField={
              <InputField
                type="text"
                fieldName="negativeMark"
                placeholder={Strings.FIELD_PLACEHOLDER_NEGATIVE_MARKING}
                data={values?.negativeMark}
                onChangeHandler={handleOnChange}
                hasError={errors?.negativeMark && dirty?.negativeMark}
                errorMessage={errors?.negativeMark}
                disabled={isLangTranslator}
                maxLength={
                  values?.negativeMark?.toString()?.includes(".") ? 4 : 2
                }
              />
            }
            value={"-5"}
          />
          <RenderDetailField
            label={Strings.FIELD_PLACEHOLDER_DURATION}
            inputField={
              <div
                className={`d-flex flex-row   ${
                  isLangTranslator ? "w-80 justify-content-between" : "w-100"
                }`}
              >
                <InputField
                  type="text"
                  fieldName="duration"
                  data={values?.duration}
                  onChangeHandler={handleOnChange}
                  hasError={errors?.duration && dirty?.duration}
                  placeholder={Strings.FIELD_PLACEHOLDER_DURATION}
                  errorMessage={errors?.duration}
                  disabled={isLangTranslator}
                  classes={"group-question"}
                  maxLength={3}
                />
                <span className="d-flex align-content-center question-mins">
                  (mins)
                </span>
              </div>
            }
            value={"10min"}
            valuesClass={"extend-area"}
          />
          <RenderDetailField />
          <RenderDetailField />
        </div>
        {isLangTranslator && selectedQuestion?.isGroupPassage ? (
          <div className="d-flex flex-row mt-3">
            <RenderDetailField
              label={Strings.FIELD_PLACEHOLDER_PASSAGE_QUESTION}
              valuesClass="passage-container"
              className="w-100"
              keyClass="w-100"
              inputField={
                <>
                  {isLangTranslator ? (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedQuestion?.groupPassage?.passage,
                        }}
                      ></p>
                      {selectedQuestion?.groupPassage?.image?.value ? (
                        <img
                          src={selectedQuestion?.groupPassage?.image?.value}
                          alt="question"
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              }
            />
          </div>
        ) : null}
        <div className="d-flex flex-row mt-3">
          <RenderDetailField
            label={Strings.FIELD_PLACEHOLDER_QUESTION_LABEL_TITLE}
            inputField={
              <>
                {isLangTranslator ? (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedQuestion?.questionDescription,
                      }}
                    ></p>
                    {selectedQuestion?.questionImage?.value ? (
                      <img
                        src={selectedQuestion?.questionImage?.value}
                        alt="question"
                      />
                    ) : null}
                  </>
                ) : null}
                {!isLangTranslator ? (
                  <>
                    <InputEditor
                      height={350}
                      style={{ height: "350px" }}
                      className="question-title"
                      onChange={(e) => tinyEditorHandler(e, "")}
                      data={values?.questionDescription}
                      key={"add_question_editor"}
                      allowResize={false}
                    />

                    <FileInputField
                      accepts={"image/png, image/jpeg, image/jpg"}
                      fieldName={"questionImage"}
                      hasError={errors?.questionImage && dirty?.questionImage}
                      errorMessage={errors?.questionImage}
                      onChangeHandler={(e) =>
                        callUpload(
                          isLangTranslator,
                          languageTranslations,
                          setLanguageTranslations,
                          values,
                          setValues,
                          e,
                          "questionImage"
                        )
                      }
                      hasPreview={values?.questionImage?.value}
                      preview={values?.questionImage?.value}
                      shouldDisplayCrossIcon={true}
                      onImageClearHandler={handleOnClearImageUpload}
                      urlOnly={true}
                      name={values?.questionImage?.name}
                      descriptionOnBox={Strings?.FILE_FORMAT_PHOTO}
                    />
                  </>
                ) : null}
              </>
            }
            className="w-100"
            keyClass="w-100"
            required={true}
          />
        </div>
        {values.type === "Text Box" ? (
          <div className="mt-3">
            {!isLangTranslator ? (
              <InputEditor
                className="answer-option"
                onChange={(e) => tinyEditorHandler(e, "answer")}
                data={values?.answer[0]}
                key={"add_question_editor1"}
                title={Strings.FIELD_TITLE_ADD_CORRECT_ANS}
                allowResize={false}
              />
            ) : null}
          </div>
        ) : (
          <>
            <div className="d-flex flex-row mt-3">
              <RenderDetailField
                label={Strings.FIELD_LABEL_ANS_TYPE_QUE_TYPE}
                inputField={
                  <Radio
                    fieldName="optionType"
                    placeholder={Strings.FIELD_PLACEHOLDER_SELECT_QUE_TYPE}
                    options={["Text", "Text & Image"]}
                    checked={values?.optionType}
                    hasError={errors?.optionType && dirty?.optionType}
                    errorMessage={errors?.optionType}
                    onChangeHandler={handleOnRadioChange}
                    classes={"w-50"}
                    radioOptionLabelClass={"select-option-type"}
                    disabled={isLangTranslator}
                  />
                }
                className="w-100"
                required={true}
              />
            </div>
            <div className="d-flex flex-col select-correct-answer-container">
              <Checkbox
                title={Strings.FIELD_TITLE_SELECT_CORRECT_ANS}
                options={optionLabel}
                checked={multiCurrectOption}
                onChangeHandler={(option, index) => {
                  onClickCheckboxHandler(index);
                }}
                className={"select-correct-answer"}
                disabled={isLangTranslator}
              />
            </div>
            {values.type === "Dropdown" ||
            values.type === "Multiselect Dropdown" ? (
              <CustomDropDown
                options={answerOptionNames}
                className={"mt-3"}
                inputEditor={true}
                disabled={false}
                isOptionDropDown={true}
              />
            ) : null}
            <div className="d-flex flex-wrap add-answer-field">
              {[...Array(optionCount)].map((option, index) => {
                return renderAddAnswerOptionContainer(isLangTranslator, index);
              })}
            </div>
            {!isLangTranslator ? (
              <div className="mt-3 ">
                <TextButton
                  title={BtnTitles.ADD_ANSWER}
                  rounded={false}
                  size="small"
                  clickHandler={onClickAddAnswer}
                  shouldDisable={isLangTranslator}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {isLangTranslator ? (
        <QuestionTranslationList
          languageTranslations={languageTranslations}
          setLanguageTranslations={setLanguageTranslations}
          isLangReviewer={isLangReviewer}
          getLanguageOptions={getLanguageOptions}
          onLanguageChangeHandler={onLanguageChangeHandler}
          values={values}
          setValues={setValues}
          errors={errors}
          dirty={dirty}
          tinyEditorHandler={tinyEditorHandler}
          callUpload={callUpload}
          handleOnClearOptionImageUpload={handleOnClearOptionImageUpload}
          handleOnClearImageUpload={handleOnClearImageUpload}
          optionCount={optionCount}
          renderAddAnswerOptionContainer={renderAddAnswerOptionContainer}
          isLangTranslator={isLangTranslator}
          selectedQuestion={selectedQuestion}
          selectedLangArray={selectedLangArray}
          setSelectedLangArray={setSelectedLangArray}
          currentPassage={currentPassage}
        />
      ) : null}
    </div>
  );
};

export default AddQuestionContainer;
