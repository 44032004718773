import React from "react";
import "../QuestionAnswerPreviewModal.css";
import AnswerOptionsTypes from "components/widgets/answer-options-types/AnswerOptionsTypes";

const PreviewModalFooter = ({ data }) => {
  return (
    <div className="preview_modal_footer">
      <span className="your_question_span your_answer_span">Your Answer</span>
      <div className=" pt-4 preview_modal_footer_container">
        <AnswerOptionsTypes data={data} type={data?.type} />
      </div>
    </div>
  );
};

export default PreviewModalFooter;
