export const Strings = {
  FLYOUT_MODE_ADD: "Add",
  FLYOUT_MODE_EDIT: "Edit",
  FLYOUT_MODE_VIEW: "View",
  FLYOUT_MODE_IMPORT: "import",
  FLYOUT_MODE_DELETE: "Delete",
  CONFIRM_CLOSE_IMPORT: "confirm close import",
  CONFIRM_DELETE: "Are you sure you want to delete this question?",
  CONFIRM_MULTIPLE_DELETE:
    "Are you sure you want to delete selected questions?",
  CONFIRM_APPORVE_QUESTION:
    "Are you sure you want to approve selected questions?",
  NO_CURRENT_USER: "No current user",
  SELECT_AT_LEAST_ONE_QUESTION:
    "Please select atleast one question to update status",
  CANDIDATE_SCREEN_PREVIEW: "CANDIDATE SCREEN PREVIEW",
  DELETE_QUESTIONS: "Delete Questions",
  ADD_COMMENT: "Add Comment",
  QUICK_SEARCH: "Quick Search",
  LANGUAGE_TRANSLATOR: "LANGUAGE_TRANSLATOR",
  ADD_TRANSLATION: "Add Translation",
  REQUEST_NAME_TITLE: "Request Name",
  AUTHOR_NAME_TITLE: "Author Name",
  OPTIONS_TITLE: "options",
  SELECT_TITLE: "select",
  ID: "id",
  QUESTION_TYPE: "question Type",
  LANGUAGE_TITLE: "language",
  SUBJECT_TITLE: "Subject",
  CATEGORY_TITLE: "category",
  LEVEL_TITLE: "level",
  TYPE_TITLE: "Type",
  STATUS_TITLE: "status",
  ACTIONS_TITLE: "Actions",
  NOT_GENERATED: "Not Generated",
  REJECT: "Reject",
  GROUP_QUESTION: "Group Question",
  NORMAL: "Normal",
  CONFIRMATION_TITLE:
    "Are you sure you want to exit without importing the file?",
  REQUEST_FOR_PUBLISHER: "forPublisher",
  REQUEST_FOR_TRANSLATOR_APPROVER: "forTranslatorApprover",
  REQUEST_FOR_TRANSLATOR: "forTranslator",
  REQUEST_FOR_REVIEWER: "forReviewer",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
};
