import { NavBar } from "components/laylouts/nav-bar/NavBar";
import TextButton from "components/widgets/text-Button/TextButton";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDrawer,
  fetchAllInstances,
  fetchLoggedInUserDetails,
  fetchUserProfile,
  getFlyoutMode,
  getPinSetState,
  isDrawerOpen,
  sendFireBaseToken,
  setFlyoutMode,
  userProfile,
} from "redux/profile/profile-slice";
import Flyout from "components/widgets/flyout/Flyout";
import EditProfileFlyout from "./EditProfileFlyout";
import { Strings } from "redux/profile/profile-static";
import { useHistory } from "react-router-dom";
import "./Profile.css";
import { getInitials, getStorageItem, notifyError } from "utils/utility";
import Loader from "components/widgets/loader/Loader";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import Image from "components/widgets/image/Image";
import Drawer from "components/widgets/drawer/Drawer";
import { BackDrop } from "components/widgets/back-drop/BackDrop";
import CreateUpdatePin from "components/laylouts/manage-pin-popup/CreateUpdatePin";
import { fetchPinSet } from "redux/login/login-slice";
import { accessRoleMapView, successStatusCodes } from "utils/constants/common";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { BtnTitles } from "utils/constants/constants";

export function Profile() {
  const [loader, setLoader] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const flyoutMode = useSelector(getFlyoutMode);
  const profileData = useSelector(userProfile);
  const drawerOpen = useSelector(isDrawerOpen);
  const isPinset = useSelector(getPinSetState);
  const dispatch = useDispatch();
  const history = useHistory();
  const firebaseToken = getStorageItem("firebaseToken");
  const localProfileData = getStorageItem("loggedInUserData")
    ? getStorageItem("loggedInUserData")
    : null;

  useEffect(() => {
    fetchProfileInfo();
    getPinsetDetail();
    getAllInstances();
    if (!getStorageItem("menuItems")) {
      dispatch(fetchLoggedInUserDetails());
    }
  }, []);

  const getPinsetDetail = () => {
    dispatch(fetchPinSet()).then((res) => {
      if (successStatusCodes.includes(res?.payload?.statusCode)) {
        if (!res?.payload?.data?.pinSet) {
          setShowChangePinModal(true);
        }
      } else {
        notifyError(res?.payload?.data?.message);
      }
    });
  };

  const getAllInstances = () => {
    const optionsToSend = {
      access: accessRoleMapView.Web,
    };
    dispatch(fetchAllInstances(optionsToSend))
      .then(() => {})
      .catch((error) => error.message);
  };

  const onDrawerClose = (e) => {
    e?.preventDefault();
    dispatch(closeDrawer());
  };

  const openCloseFlyout = (e, val) => {
    e?.preventDefault();
    if (val === Strings.FLYOUT_MODE_EDIT || val === "") {
      dispatch(setFlyoutMode({ mode: val }));
    }
  };

  const fetchProfileInfo = () => {
    setLoader(true);
    if (firebaseToken !== "null" && firebaseToken !== null) {
      dispatch(sendFireBaseToken({ token: firebaseToken }));
    }
    dispatch(fetchUserProfile())
      .then((response) => response)
      .catch((error) => notifyError(error.message))
      .finally(() => setLoader(false));
  };

  return (
    <div className="pgStructureOne">
      <NavBar
        title={Strings.NAVBAR_TITLE_PROFILE}
        isBack={false}
        isPinset={isPinset}
      />
      <Drawer open={drawerOpen} />
      {drawerOpen ? <BackDrop clickHandler={onDrawerClose} /> : null}
      {flyoutMode === Strings.FLYOUT_MODE_EDIT ? (
        <Flyout open={flyoutMode}>
          <EditProfileFlyout
            profileData={profileData}
            closeFlyoutDocument={(e) => openCloseFlyout(e, "")}
            fetchProfileInfo={fetchProfileInfo}
          ></EditProfileFlyout>
        </Flyout>
      ) : null}
      {!loader ? (
        <div className="container-fluid profile-container d-flex justify-content-center">
          <div className="profile-card-body">
            <div className="d-flex align-items-end mb-4">
              {profileData?.avatar?.length > 0 && profileData?.avatar ? (
                <Image
                  alt="online-img"
                  url={profileData?.avatar || localProfileData?.avatar}
                  className="profile-img"
                  width="130px"
                />
              ) : (
                <span className="profile-img-initials">
                  {getInitials(profileData || localProfileData)}
                </span>
              )}
            </div>
            <div className="mb-2">
              <div>
                <span className="profile-name-content">{`${
                  profileData?.firstName
                    ? profileData?.firstName
                    : localProfileData?.firstName
                    ? localProfileData?.firstName
                    : "Not Available"
                } ${
                  profileData?.lastName
                    ? profileData?.lastName
                    : localProfileData?.lastName
                    ? localProfileData?.lastName
                    : ""
                }`}</span>
              </div>
              <div>
                <span className="profile-role-content">
                  {profileData?.jobTitle ?? localProfileData?.jobTitle}
                </span>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div>
                <span className="profile-lbl">Email:</span>
              </div>
              <div className="profile-email-direction">
                <span className="profile-content-title">
                  {profileData?.email
                    ? profileData?.email
                    : localProfileData?.email
                    ? localProfileData?.email
                    : "Not Available"}
                </span>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div>
                <span className="profile-lbl">Mobile:</span>
              </div>
              <div className="profile-mobile-direction">
                <span className="profile-content-title">{`${
                  profileData?.mobileNumber
                    ? profileData?.mobileNumber?.slice(0, 3)
                    : localProfileData?.mobileNumber
                    ? localProfileData?.mobileNumber?.slice(0, 3)
                    : "Not Available"
                } ${
                  profileData?.mobileNumber
                    ? profileData?.mobileNumber?.slice(3)
                    : localProfileData?.mobileNumber
                    ? localProfileData?.mobileNumber?.slice(3)
                    : ""
                }`}</span>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <span className="profile-lbl">DOB:</span>
              </div>
              <div className="profile-dob-direction">
                <span className="profile-content-dob">
                  {profileData?.DOB
                    ? moment(profileData?.DOB).format("DD MMMM YYYY")
                    : localProfileData?.DOB
                    ? moment(localProfileData?.DOB).format("DD MMMM YYYY")
                    : "Not Available"}
                </span>
              </div>
            </div>
            <div className="d-flex mt-4 gap-2">
              <TextButton
                title={BtnTitles.EDIT}
                className="profile-btn"
                rounded={false}
                clickHandler={(e) =>
                  openCloseFlyout(e, Strings.FLYOUT_MODE_EDIT)
                }
              />
              <TextButton
                title={BtnTitles.CHANGE_PWD}
                className="profile-btn"
                rounded={false}
                clickHandler={() =>
                  history.push(ROUTES_CONSTANTS.changePassword)
                }
              />
            </div>
            <div className="mt-3">
              <TextButton
                title={isPinset ? BtnTitles.CHANGE_PIN : BtnTitles.CREATE_PIN}
                className="profile-btn"
                rounded={false}
                isPrimary={false}
                clickHandler={() => {
                  setShowChangePinModal(true);
                }}
              />
            </div>
          </div>
          <CreateUpdatePin
            show={showChangePinModal}
            edit={true}
            mode={isPinset ? "edit" : "add"}
            onClose={() => {
              setShowChangePinModal(false);
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Profile;
