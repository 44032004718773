import { IconDelete } from "assets/svgs/Svgs";
import { Checkbox } from "components/widgets/checkbox/Checkbox";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import InputEditor from "components/widgets/input-editor/InputEditor";
import { Radio } from "components/widgets/radio/Radio";
import { callUpload } from "../add-question-utility/AddQuestionUtility";
import Image from "components/widgets/image/Image";
import { Strings } from "utils/constants/constants";

export const AddAnswerOptionContainer = ({
  showQuestionOptions,
  index,
  values,
  optionLabel,
  multiCurrectOption,
  setCurrectOption,
  onClickMinusIcon,
  tinyEditorHandler,
  errors,
  dirty,
  handleOnClearOptionImageUpload,
  isLangTranslator,
  languageIndex = 0,
  language,
  showLanguageTranslation,
  languageTranslations,
  setLanguageTranslations,
  setValues,
  optionCount,
}) => {
  const optionName = language?.details?.answerOptions[index]?.optionName;
  const ansOption = language?.details?.answerOptions[index];
  const optionFile = values?.optionFile[index];
  const answers = values?.answer[index] ? values?.answer[index] + "" : "";
  return (
    <div
      className={`d-flex ${
        showQuestionOptions ? "flex-row mt-3" : "flex-col"
      }  question-w-45`}
    >
      <div
        className={
          showQuestionOptions
            ? "show-question-options-width"
            : "d-flex flex-row justify-content-between mt-3"
        }
      >
        {values.type === "Checkbox" || values.type === "Multi Dropdown" ? (
          <Checkbox
            optionLabel={showQuestionOptions ? null : optionLabel[index]}
            data={{
              id: index,
              value: multiCurrectOption[index],
            }}
            key={`checkbox-${index}`}
            onChangeHandler={() => {}}
            checked={multiCurrectOption[index]}
            className="radio-option-label align-items-center question-checkbox mb-1"
            labelClass=" checkbox-label-input"
            inputClass="checkbox-label-input"
            titleClass="d-none"
            disabled={isLangTranslator}
            isAnswerOptions={showQuestionOptions}
          />
        ) : (
          <Radio
            options={[showQuestionOptions ? null : optionLabel[index]]}
            checked={multiCurrectOption[index] ? "Option " + (index + 1) : ""}
            onChangeHandler={(e) => {
              setCurrectOption(e.target.id);
            }}
            radioOptionClass={"flex-col align-items-start"}
            radioOptionLabelClass={"radio-option-label"}
            classes={"mb-2"}
            disabled={isLangTranslator}
            isAnswerOptions={showQuestionOptions}
          />
        )}
        {!isLangTranslator && optionCount > 2 ? (
          <span
            onClick={() => onClickMinusIcon(index)}
            className="cursor-pointer"
          >
            <IconDelete />
          </span>
        ) : null}
      </div>
      <div className="d-flex flex-col ">
        {(!isLangTranslator || showLanguageTranslation) && values ? (
          <div className="d-flex flex-col w-100 ">
            {!isLangTranslator || values?.answer[index] ? (
              showQuestionOptions ? (
                <div
                  className="show-question-answer"
                  dangerouslySetInnerHTML={{
                    __html: values?.answer[index],
                  }}
                />
              ) : (
                <InputEditor
                  className="answer-option"
                  onChange={(e) => {
                    tinyEditorHandler(e, "answer", index, languageIndex);
                  }}
                  data={
                    isLangTranslator
                      ? showQuestionOptions
                        ? answers
                        : optionName
                        ? optionName + ""
                        : ""
                      : answers
                  }
                  key={"add_question_editor1"}
                  disabled={showQuestionOptions}
                  allowResize={false}
                />
              )
            ) : null}
            {values.optionType === "Text & Image" &&
            (!isLangTranslator || optionFile) ? (
              showQuestionOptions ? (
                <Image urlOnly={true} url={optionFile?.value} />
              ) : (
                <FileInputField
                  accepts={"image/png, image/jpeg, image/jpg"}
                  fieldName={"optionFile"}
                  hasError={errors.optionFile[index] && dirty.optionFile[index]}
                  errorMessage={errors.optionFile[index]}
                  onChangeHandler={(e) =>
                    !showQuestionOptions &&
                    callUpload(
                      isLangTranslator,
                      languageTranslations,
                      setLanguageTranslations,
                      values,
                      setValues,
                      e,
                      "optionFile",
                      index,
                      languageIndex
                    )
                  }
                  hasPreview={
                    isLangTranslator
                      ? showQuestionOptions
                        ? optionFile?.value
                        : ansOption?.image?.value
                      : optionFile?.value
                  }
                  preview={
                    isLangTranslator
                      ? showQuestionOptions
                        ? optionFile?.value
                        : ansOption?.image?.value
                      : optionFile?.value
                  }
                  name={
                    isLangTranslator
                      ? showQuestionOptions
                        ? optionFile?.name
                        : ansOption?.image?.name
                      : optionFile?.name
                  }
                  shouldDisplayCrossIcon={!showQuestionOptions}
                  onImageClearHandler={() =>
                    handleOnClearOptionImageUpload(index, languageIndex)
                  }
                  urlOnly={true}
                  descriptionOnBox={Strings?.FILE_FORMAT_PHOTO}
                />
              )
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddAnswerOptionContainer;
