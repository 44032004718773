import { useEffect, useRef, useState } from "react";

import { Checkbox } from "../checkbox/Checkbox";
import "./DropDownField.css";
import _ from "lodash";
import { IconSelectArrow } from "assets/svgs/Svgs";
import { getActionsList } from "utils/utility";

export function DropDownField(props) {
  const {
    fieldName,
    title,
    placeholder,
    options,
    data,
    hasError = false,
    errorMessage = "",
    onChangeHandler,
    onChangeMultipleHandler,
    disabled = false,
    grouped = false,
    style = {},
    classes = "",
    onClickOutside,
    required = false,
    multiSelectionClass = "",
    isRolesSelection = false,
    selectionKey = "",
    readOnly = false,
    cleanSearchCounter,
    fontSizerStyle = null,
    disablePlaceholder = false,
    className,
    labelClass = "",
    showRequestList = "",
    showActionsList = false,
  } = props;

  const searchRef = useRef();
  const optionsRef = useRef(null);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [optionsLocal, setOptionsLocal] = useState(options);

  useEffect(() => {
    if (options) if (searchValue?.length < 1) setOptionsLocal(options);
  }, [options]);

  useEffect(() => {
    setSearchValue("");
  }, [cleanSearchCounter]);

  useEffect(() => {
    if (showActionsList) setOptionsLocal(getActionsList(showRequestList));
  }, [showRequestList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsRef?.current &&
        !optionsRef?.current?.contains(event?.target)
      ) {
        onClickOutside && onClickOutside();
        setOptionsVisible(!optionsVisible);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside, optionsVisible]);

  function isObject(objValue) {
    return (
      objValue &&
      typeof objValue === "object" &&
      objValue.constructor === Object
    );
  }

  const simpleOptions = Array.isArray(optionsLocal)
    ? optionsLocal.map((option, index) => {
        return (
          <option
            title={isObject(option) ? option.field : option}
            key={index}
            value={isObject(option) ? option.field : option}
            style={fontSizerStyle ? fontSizerStyle : null}
          >
            {isObject(option) ? option.label : option}
          </option>
        );
      })
    : [];

  const checkIfValueExists = (option) => {
    if (isRolesSelection && data && Array.isArray(data) && data.length) {
      let obj = data.find((x) => x.name === option);
      if (obj) return true;
      return false;
    }
    if (selectionKey && data && Array.isArray(data) && data.length) {
      if (selectionKey === "none") {
        let obj = data?.find((x) => x === option);
        if (obj) return true;
        return false;
      } else {
        let obj = data?.find((x) => x[selectionKey] === option);
        if (obj) return true;
        return false;
      }
    }
    return false;
  };

  const multipleOptions = Array.isArray(optionsLocal)
    ? optionsLocal.map((option, index) => {
        return (
          <Checkbox
            key={fieldName + "_checkbox_" + index.toString()}
            className="multi-select-single-option"
            optionLabel={option}
            data={{
              id: fieldName + "_" + index,
              value: option,
              checked: checkIfValueExists(option),
            }}
            labelClass={`multi-select-label text-wrap-ellipsis`}
            checked={checkIfValueExists(option)}
            disabled={disabled}
            onChangeHandler={(e) => {
              const checkedVal = e.target.checked;
              if (isRolesSelection) {
                checkedVal
                  ? onChangeMultipleHandler(option, "add")
                  : onChangeMultipleHandler(option, "remove");
              } else if (selectionKey) {
                checkedVal
                  ? onChangeMultipleHandler(option, "add")
                  : onChangeMultipleHandler(option, "remove");
              } else {
                if (checkedVal) {
                  onChangeMultipleHandler(fieldName, [...data, option]);
                } else {
                  let clonedData = _.cloneDeep(data);
                  clonedData = clonedData.filter((ele) => ele !== option);
                  onChangeMultipleHandler(fieldName, [...clonedData]);
                }
              }
            }}
          />
        );
      })
    : [];

  const groupedOptions = Array.isArray(optionsLocal)
    ? optionsLocal?.map((option, index) => {
        return (
          <optgroup key={index} label={option?.name}>
            {option?.values &&
              option?.values?.length > 0 &&
              option?.values?.map((value, indx) => {
                return (
                  <option
                    title={value?.name}
                    key={indx + fieldName}
                    id={value?.id}
                    value={value?.name}
                  >
                    {value?.name}
                  </option>
                );
              })}
          </optgroup>
        );
      })
    : [];

  const onClickIconSearch = () => {
    if (!disabled) {
      searchRef?.current?.focus();
      setOptionsVisible(!optionsVisible);
    }
  };

  const onChangeSearchValueHandler = (e) => {
    const searchVal = e.target.value;
    setSearchValue(searchVal);
    if (searchVal) {
      setOptionsVisible(true);
      let clonedOptions = _.cloneDeep(options);
      let newOptions = clonedOptions.filter((ele) =>
        ele.toLowerCase().includes(searchVal.toLowerCase())
      );
      setOptionsLocal(newOptions);
    } else setOptionsLocal(options);
  };

  const onClearSearchHandler = () => {
    setSearchValue("");
    setOptionsLocal(options);
  };

  const onClickUpDownHandler = (e) => {
    if (!disabled)
      if (e.currentTarget !== e.target) {
        e.stopPropagation();
        e.preventDefault();
        setOptionsVisible(!optionsVisible);
      }
  };

  let lStyle = style ? { color: style["label-color"] } : null;
  let selectStyle = { color: style["text-color"] };
  if (fontSizerStyle) {
    lStyle = { ...lStyle, ...fontSizerStyle };
    selectStyle = { ...selectStyle, ...fontSizerStyle };
  }

  return (
    <div className={`field-group ${hasError ? "has-error " : ""} ${classes}`}>
      <label
        className={`field-group_label ${required && "required"} ${labelClass}`}
        style={lStyle}
      >
        <span>
          {title}
          {required && <span className="required-asterisk">*</span>}
        </span>
      </label>

      <div
        className={`single-select single-select-container  ${className}`}
        style={style && { border: `1px solid ${style["field-border-color"]}` }}
        title={data || placeholder}
      >
        <select
          id={fieldName}
          name={fieldName}
          className={`text-wrap-ellipsis pr-5 select__wrapper so ${
            props.class ? props.class : ""
          }`}
          value={data}
          onChange={onChangeHandler}
          disabled={disabled}
          style={selectStyle}
          readOnly={readOnly}
          placeholder={placeholder}
        >
          <option
            value=""
            key={"default"}
            defaultValue
            style={fontSizerStyle ? fontSizerStyle : null}
            disabled={disablePlaceholder}
          >
            {placeholder}
          </option>
          {grouped ? groupedOptions : simpleOptions}
        </select>
        <div className={"svgBg"}>
          {<IconSelectArrow className="iconSelectArrow" />}
        </div>
      </div>

      {optionsVisible ? (
        <div
          id="checkboxes"
          className={`multi-select-options ${multiSelectionClass}`}
          ref={optionsRef}
        >
          {optionsVisible ? multipleOptions : ""}
        </div>
      ) : null}

      {hasError ? (
        <div
          className="error-msg "
          style={style && { color: style["error-color"] }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          className="d-none"
          style={style && { color: style["error-color"], height: "16px" }}
        ></div>
      )}
    </div>
  );
}
