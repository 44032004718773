import Image from "../image/Image";
import "./Radio.css";
export function Radio(props) {
  const {
    title,
    options,
    help = undefined,
    hasError = false,
    errorMessage = "",
    checked = "",
    fieldName,
    onChangeHandler,
    style = {},
    classes = "",
    required = false,
    readOnly = false,
    disabled = false,
    customWiderWidthForIndividualRadio = false,
    requiredCustomWidth = "",
    labelClass = "",
    fontSizerStyle = null,
    isReadOnly = false,
    radioOptionClass = "",
    radioOptionLabelClass = "",
    className,
    isAnswerOptions = false,
    showNumber = false,
  } = props;

  const radioOptions = [...new Set(options)];

  let lStyle = style ? { color: style["label-color"] } : null;
  if (fontSizerStyle) {
    lStyle = { ...lStyle, ...fontSizerStyle };
  }

  return (
    <div
      className={`field-group  ${classes} ${hasError ? "has-error" : ""} `}
      key={fieldName}
      id={fieldName}
    >
      <label
        className={`field-group_label ${required && "required"}`}
        style={lStyle}
      >
        <span>
          {title}
          {!isReadOnly ? (
            required ? (
              <span className="required-asterisk">*</span>
            ) : null
          ) : null}
        </span>
      </label>
      {help && <span className="field__text">{help}</span>}
      <div className={`field-radio-group ${radioOptionClass}`}>
        {isReadOnly ? (
          <label className={`preview-text`}>{checked}</label>
        ) : (
          radioOptions &&
          radioOptions.map((option, index) => {
            return (
              <label
                key={fieldName + index}
                htmlFor={`radio${index + 1} `}
                style={{
                  width: customWiderWidthForIndividualRadio
                    ? `${requiredCustomWidth}%`
                    : "",
                }}
                className={`ml-3 d-flex  ${radioOptionLabelClass} ${
                  showNumber ? "custom-radio" : "align-items-start"
                }`}
              >
                <input
                  id={option}
                  key={fieldName + index}
                  name={fieldName}
                  type="radio"
                  className={`radio radio_input_border ${
                    className ? className : ""
                  } ${showNumber ? "show-number-z-index" : ""}`}
                  checked={
                    Array?.isArray(checked) && checked?.length > 0
                      ? checked[index]
                      : checked === option
                      ? "checked"
                      : ""
                  }
                  onChange={onChangeHandler}
                  readOnly={readOnly}
                  disabled={disabled}
                />
                {isAnswerOptions ? (
                  <div className="d-flex flex-col justify-content-start text-align-left answer-option-checkbox">
                    {option?.optionName ? (
                      <span
                        className="word-break-all"
                        dangerouslySetInnerHTML={{
                          __html: option?.optionName,
                        }}
                      />
                    ) : null}

                    {option?.image?.value ? (
                      <Image
                        urlOnly={true}
                        url={option?.image?.value}
                        width="100px"
                      />
                    ) : null}
                  </div>
                ) : (
                  <span
                    className={`${labelClass}`}
                    style={style && { color: style["text-color"] }}
                  >
                    {option}
                  </span>
                )}
              </label>
            );
          })
        )}
      </div>
      {hasError ? (
        required ? (
          <p className="error-msg ">{errorMessage} </p>
        ) : null
      ) : null}
    </div>
  );
}
