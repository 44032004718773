import AppConstants from "utils/constants/app-constants";
import { ApiCaller } from "utils/network/Network";

export async function fetchLanguagesApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.GET_LANGUAGES, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchAllMastersListApi() {
  return ApiCaller.get(AppConstants.manageQuestion.GET_ALL_MASTERS_LIST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchSubjectsApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.GET_SUBJECTS, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchCategoriesApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.GET_CATEGORIES, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchTypesApi() {
  return ApiCaller.get(AppConstants.manageQuestion.GET_TYPES)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchDifficultyLevelsApi() {
  return ApiCaller.get(AppConstants.manageQuestion.GET_DIFFICULTY_LEVELS)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function addQuestionApi(options) {
  return ApiCaller.post(AppConstants.manageQuestion.CREATE_QUESTION, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function editQuestionApi(options) {
  return ApiCaller.patch(
    `${AppConstants.manageQuestion.UPDATE_QUESTION}${options?.id}`,
    options?.data
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuestionHistoryById(options) {
  return ApiCaller.post(
    `${AppConstants.manageQuestion.FETCH_QUESTION_HISTROY}${options?.id}`,
    options
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getAuhorListById(options) {
  return ApiCaller.post(
    `${AppConstants.manageQuestion.FETCH_AUTHOR_LIST}`,
    options
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuestionCreationStatusListData(options) {
  return ApiCaller.get(
    `${AppConstants.manageQuestion.FETCH_QUESTION_CREATION_STATUS_LIST}`,
    options
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function languageTranslationApi(options) {
  return ApiCaller.patch(
    `${AppConstants.manageQuestion.LANGUAGE_TRANSLATE}${options?.id}`,
    options?.data
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}
