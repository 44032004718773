import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { manageQuestionActions } from "./manage-question-actions";
import AppConstants from "utils/constants/app-constants";
import {
  deleteManyQuestionsApi,
  deleteQuestionApi,
  fetchAuthorsListApi,
  fetchQuestionDetailApi,
  fetchQuestionDetailDataByInstance,
  fetchQuestionListApi,
  fetchQuestionPreviewApi,
  fetchRequestListingApi,
  importQuestionApi,
  updateQuestionStatus,
  verifySecretKeyApi,
} from "./manage-question-api";
import { saveItemToStorage } from "utils/utility";

export const manageQuestionAdapter = createEntityAdapter();

export const setFlyoutMode = createAction(
  manageQuestionActions.MANAGE_QUESTION_FLYOUT_MODE
);

export const setSelectedId = createAction(
  manageQuestionActions.SET_SELECTED_ID
);

export const setSelectedActions = createAction(
  manageQuestionActions.SET_SELECTED_ACTIONS
);

export const setSelectedQuestion = createAction(
  manageQuestionActions.SELECTED_QUESTION
);

export const setCheckBoxState = createAction(
  manageQuestionActions.SET_CHECK_BOX_STATE
);

export const fetchQuestionList = createAsyncThunk(
  manageQuestionActions.FETCH_QUESTION_LIST,
  async (options) => {
    const response = await fetchQuestionListApi(options);
    return response;
  }
);

export const fetchQuestionDetail = createAsyncThunk(
  manageQuestionActions.FETCH_QUESTION,
  async (options) => {
    const response = await fetchQuestionDetailApi(options);
    return response;
  }
);

export const deleteQuestion = createAsyncThunk(
  manageQuestionActions.DELETE_QUESTION,
  async (options) => {
    const response = await deleteQuestionApi(options);
    return response;
  }
);

export const deleteManyQuestions = createAsyncThunk(
  manageQuestionActions.DELETE_MANY_QUESTIONS,
  async (options) => {
    const response = await deleteManyQuestionsApi(options);
    return response;
  }
);

export const fetchQuestionDetailByInstance = createAsyncThunk(
  manageQuestionActions.FETCH_QUESTION_DETAIL_INSTANCE,
  async (options) => {
    const response = await fetchQuestionDetailDataByInstance(options);
    return response;
  }
);

export const fetchQuestionPreview = createAsyncThunk(
  manageQuestionActions.PREVIEW_QUESTION,
  async (options) => {
    const response = await fetchQuestionPreviewApi(options);
    return response;
  }
);

export const updateStatus = createAsyncThunk(
  manageQuestionActions.UPDATE_QUESTION_STATUS,
  async (options) => {
    const response = await updateQuestionStatus(options);
    return response;
  }
);

export const fetchRequestListing = createAsyncThunk(
  manageQuestionActions.FETCH_REQUEST_LISTING,
  async (options) => {
    const response = await fetchRequestListingApi(options);
    return response;
  }
);

export const fetchAuthorsList = createAsyncThunk(
  manageQuestionActions.FETCH_AUTHORS_LISTING,
  async (options) => {
    const response = await fetchAuthorsListApi(options);
    return response;
  }
);

export const verifySecretKey = createAsyncThunk(
  manageQuestionActions.VERIFY_SECRET_KEY,
  async (options) => {
    const response = await verifySecretKeyApi(options);
    return response;
  }
);

export const importQuestion = createAsyncThunk(
  manageQuestionActions.IMPORT_QUESTION,
  async (options) => {
    const response = await importQuestionApi(options);
    return response;
  }
);

export const initialManageQuestionState = manageQuestionAdapter.getInitialState(
  {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
    flyoutMode: "",
    selectedQuestion: {},
    questionList: [],
    previewQuestionList: [],
    selectedGroupQuestionId: "",
    questionsCount: 0,
    authorsList: [],
    selectedActions: [],
    checkBoxState: false,
  }
);

export const manageQuestionSlice = createSlice({
  name: manageQuestionActions.FEATURE_KEY,
  initialState: initialManageQuestionState,
  reducers: {
    add: manageQuestionAdapter.addOne,
    remove: manageQuestionAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFlyoutMode, (state, action) => {
        state.flyoutMode = action.payload?.mode;
        state.selectedId = action?.payload?.id;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(setSelectedId, (state, action) => {
        state.selectedGroupQuestionId = action?.payload;
        saveItemToStorage("questionSecureRequestId", action?.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(setSelectedActions, (state, action) => {
        state.selectedActions = action?.payload;
        saveItemToStorage(
          "selectedActions",
          action?.payload
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchQuestionList.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchQuestionList.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.questionList = action?.payload?.data;
        if (action?.payload?.totalData !== undefined)
          state.questionsCount = action?.payload?.totalData;
      })
      .addCase(fetchQuestionList.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchQuestionDetail.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchQuestionDetail.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.selectedQuestion = action?.payload?.data;
      })
      .addCase(fetchQuestionDetail.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchQuestionDetailByInstance.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchQuestionDetailByInstance.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.selectedQuestion = action?.payload?.data;
      })
      .addCase(fetchQuestionDetailByInstance.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(deleteQuestion.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(deleteManyQuestions.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(deleteManyQuestions.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(deleteManyQuestions.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchQuestionPreview.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchQuestionPreview.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.previewQuestionList = action?.payload?.data;
      })
      .addCase(fetchQuestionPreview.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(updateStatus.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
        state.isLoading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.isLoading = false;
      })
      .addCase(updateStatus.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
        state.isLoading = false;
      })
      .addCase(fetchRequestListing.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchRequestListing.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.questionList = action?.payload?.data;
        if (action?.payload?.totalData !== undefined)
          state.questionsCount = action?.payload?.totalData;
      })
      .addCase(fetchRequestListing.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(verifySecretKey.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(verifySecretKey.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(verifySecretKey.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchAuthorsList.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchAuthorsList.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.authorsList = action?.payload?.data;
      })
      .addCase(fetchAuthorsList.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(importQuestion.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(importQuestion.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(importQuestion.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(setSelectedQuestion, (state, action) => {
        state.selectedQuestion = action?.payload;
      })
      .addCase(setCheckBoxState, (state, action) => {
        state.checkBoxState = action?.payload;
      });
  },
});

export const manageQuestionReducer = manageQuestionSlice.reducer;
export const manageQuestionAction = manageQuestionSlice.actions;

export const getmanageQuestionState = (rootState) =>
  rootState[manageQuestionActions.FEATURE_KEY];

export const getQuestionList = createSelector(
  getmanageQuestionState,
  (state) => state.questionList
);

export const getPreviewQuestionList = createSelector(
  getmanageQuestionState,
  (state) => state.previewQuestionList
);

export const getFlyoutMode = createSelector(
  getmanageQuestionState,
  (state) => state.flyoutMode
);

export const getAuthorsList = createSelector(
  getmanageQuestionState,
  (state) => state.authorsList
);

export const getSelectedQuestion = createSelector(
  getmanageQuestionState,
  (state) => state.selectedQuestion
);

export const getSelectedId = createSelector(
  getmanageQuestionState,
  (state) => state.selectedId
);

export const getSelectedGroupQuestionId = createSelector(
  getmanageQuestionState,
  (state) => state.selectedGroupQuestionId
);

export const getQuestionsCount = createSelector(
  getmanageQuestionState,
  (state) => state.questionsCount
);

export const getCheckBoxState = createSelector(
  getmanageQuestionState,
  (state) => state.checkBoxState
);
