import {
  IconCheckCircle,
  IconCircleChangePassword,
  IconCircleExclamation,
} from "assets/svgs/Svgs";
import "./MessageToaster.css";

export function MesssagToaster(props) {
  const {
    title,
    showToaster = false,
    showIcon = false,
    IconLabel = "",
    className = "",
    changePassword = false,
    style = {},
    titleClass,
  } = props;

  return (
    <div
      className={`${showToaster ? "" : "visibility-hidden"} ${className}`}
      style={style}
    >
      <div className={`mt-2 pwsd-${IconLabel}`}>
        <span className="icon-left">
          {changePassword && <IconCircleChangePassword />}
          {showIcon && IconLabel === "error" && <IconCircleExclamation />}
          {showIcon && IconLabel === "success" && <IconCheckCircle />}
        </span>
        <p className={`${titleClass ?? "m-0"}`} style={style}>
          {title}
        </p>
      </div>
    </div>
  );
}

export default MesssagToaster;
