import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addPassageApi,
  deletePassageApi,
  editPassageApi,
  fetchAllPassageApi,
  passageDetailApi,
} from "./passage-api";
import { passageActions } from "./passage-actions";
import AppConstants from "utils/constants/app-constants";

export const passageAdapter = createEntityAdapter();

export const addPassage = createAsyncThunk(
  passageActions.ADD_PASSAGE,
  async (options) => {
    const response = await addPassageApi(options);
    return response;
  }
);

export const editPassage = createAsyncThunk(
  passageActions.EDIT_PASSAGE,
  async (options) => {
    const response = await editPassageApi(options);
    return response;
  }
);

export const passageDetail = createAsyncThunk(
  passageActions.PASSAGE_DETAIL,
  async (options) => {
    const response = await passageDetailApi(options);
    return response;
  }
);

export const fetchAllPassage = createAsyncThunk(
  passageActions.FETCH_ALL_PASSAGE,
  async (options) => {
    const response = await fetchAllPassageApi(options);
    return response;
  }
);

export const deletePassage = createAsyncThunk(
  passageActions.DELETE_PASSAGE,
  async (options) => {
    const response = await deletePassageApi(options);
    return response;
  }
);

export const setPassageFlyoutMode = createAction(
  passageActions.PASSAGE_FLYOUT_MODE
);

export const setSelectedPassageId = createAction(
  passageActions.PASSAGE_SELECTED_ID
);

export const setSelectedPassage = createAction(passageActions.PASSAGE_SELECTED);

export const setConfirmationMode = createAction(passageActions.SET_FLYOUT_MODE);

export const initialPassageState = passageAdapter.getInitialState({
  loadingStatus: "not loaded",
  error: null,
  isLoading: false,
  flyoutMode: "",
  passageDetail: {},
  passageList: [],
  selectedPassageId: "",
  confirmationMode: "",
});

export const passageSlice = createSlice({
  name: passageActions.FEATURE_KEY,
  initialState: initialPassageState,
  reducers: {
    add: passageAdapter.addOne,
    remove: passageAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPassageFlyoutMode, (state, action) => {
        state.flyoutMode = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(setSelectedPassageId, (state, action) => {
        state.selectedPassageId = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(setSelectedPassage, (state, action) => {
        state.passageDetail = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addPassage.pending, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(addPassage.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(addPassage.rejected, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(editPassage.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(editPassage.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(editPassage.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(passageDetail.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(passageDetail.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.passageDetail = action?.payload?.data;
      })
      .addCase(passageDetail.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchAllPassage.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchAllPassage.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.passageList = action?.payload?.data;
      })
      .addCase(fetchAllPassage.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(deletePassage.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(deletePassage.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(deletePassage.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(setConfirmationMode, (state, action) => {
        state.confirmationMode = action?.payload;
      });
  },
});

export const passageReducer = passageSlice.reducer;

export const passageSliceActions = passageSlice.actions;

export const getPassageState = (rootState) =>
  rootState[passageActions.FEATURE_KEY];

export const getPassageDetail = createSelector(
  getPassageState,
  (state) => state.passageDetail
);

export const getPassageList = createSelector(
  getPassageState,
  (state) => state.passageList
);

export const getSelectedPassageId = createSelector(
  getPassageState,
  (state) => state.selectedPassageId
);

export const getPassageFlyoutMode = createSelector(
  getPassageState,
  (state) => state.flyoutMode
);

export const getConfirmationMode = createSelector(
  getPassageState,
  (state) => state.confirmationMode
);
