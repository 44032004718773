export const Strings = {
  USER_TYPE_REVIEWER: "Send For Review",
  POPUP_MODE_ADD: "Add",
  POPUP_MODE_VERIFY: "verify",
  POPUP_MODE_REJECT: "Reject",
  POPUP_MODE_MULTIPLE_DELETE: "Multiple delete",
  QUESTION_STATUS_APPROVE: "Approve",
  QUESTION_STATUS_REJECT: "Reject",
  QUESTION_STATUS_LANGUAGE_TRANSLATOR: "Send to Language Reviewer",
  FIELD_ERROR_CONFIRM_SECRET_KEY: "Secret keys do not match",
  FIELD_INVALID_VALUE: "Invalid value",
  FIELD_INVALID_SECRET_KEY: "Pattern for secret key is incorrect",
  SECRET_KEY_PATTERN_DESCRIPTION:
    "The secret key should contain at least one uppercase letter, one lowercase letter, one number, and length between 8 to 12 characters",
  FIELD_TITLE_REQUEST_NAME: "Request Name",
  FIELD_TITLE_SECRET_KEY: "Secret Key",
  FIELD_TITLE_CONFIRM_SECRET_KEY: "Confirm Secret Key",
  FIELD_TITLE_ENTER_KEY: "Enter Key",
  FIELD_PLACEHOLDER_SELECT: "Select",
  NAVBAR_TITLE_QUESTION_DETAILS: "Question Details",
  REJECTION_MODAL_TITLE: "Add Comment",
  CONFIRMATION_MSG: "Are you sure you want to delete this question?",
};
