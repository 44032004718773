import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import FlyoutHeader from "components/widgets/flyout-header/FlyoutHeader";
import Flyout from "components/widgets/flyout/Flyout";
import "./QuestionFilter.css";
import TextButton from "components/widgets/text-Button/TextButton";
import Switch from "components/widgets/switch/Switch";
import { convertData, convertListToOptions } from "utils/utility";
import { useForm } from "utils/customeHooks/UseForm";
import { questionFilterState } from "./QuestionFilterState";
import { useEffect, useState } from "react";
import { BtnTitles, Strings } from "utils/constants/constants";

const QuestionFilterFooter = (props) => {
  const { handleOnSubmit, clearStateSchema } = props;
  return (
    <>
      <div className="question__filter_footer question-footer-bottom">
        <TextButton
          title={BtnTitles.RESET}
          isPrimary={false}
          rounded={false}
          clickHandler={clearStateSchema}
        />
        <TextButton
          title={BtnTitles.APPLY}
          rounded={false}
          clickHandler={handleOnSubmit}
        />
      </div>
    </>
  );
};

export const QuestionFilter = (props) => {
  const {
    authorsList,
    typesList,
    levelsList,
    subjectCategoriesList,
    languageList,
    questionCreationStatusList,
    filterByAuthor,
  } = props;
  const [subjectWiseCategoriesList, setSubjectWiseCategoriesList] = useState(
    []
  );
  const [subjectWiseCategoriesOptions, setSubjectWiseCategoriesOptions] =
    useState([]);

  const languageOptions = convertListToOptions(languageList, "languageName");
  const subjectsOptions = convertListToOptions(
    subjectCategoriesList,
    "subjectName"
  );
  const typesOptions = convertListToOptions(typesList, "typeName");
  const authorsOptions =
    Array?.isArray(authorsList) && authorsList?.length > 0
      ? authorsList?.map(
          (author) => author?.autherFirstName + " " + author?.authorLastName
        )
      : [];
  const levelsOptions = convertListToOptions(levelsList, "difficultyLevel");
  const reviewQuestionFilterState = {
    authorId: {
      value: "",
      error: "",
    },
  };
  const questionCreationStatusOptions = [];
  if (questionCreationStatusList)
    for (const [key, value] of Object.entries(questionCreationStatusList)) {
      questionCreationStatusOptions.push(value);
    }
  const onChangeGroupPassageHandler = () => {
    setValues((prev) => ({
      ...prev,
      isGroupPassage: !values.isGroupPassage,
    }));
  };

  const onSubmit = () => {
    if (!error) {
      const sentParams = filterByAuthor
        ? { authorId: convertNameToId() }
        : {
            ...values,
            language: values?.language ? values?.language : undefined,
            subject: convertData(
              subjectCategoriesList,
              "subjectName",
              "subjectCode",
              values.subject
            ),
            category: convertData(
              subjectWiseCategoriesList,
              "categoryName",
              "categoryCode",
              values.category
            ),
            level: convertData(
              levelsList,
              "difficultyLevel",
              "difficultyCode",
              values.level
            ),
            type: convertData(typesList, "typeName", "typeCode", values.type),
            status: convertStatusTokey(),
            isGroupPassage:
              values?.isGroupPassage === ""
                ? undefined
                : values?.isGroupPassage,
          };
      props?.onChangeParams({ ...sentParams, page: 1 });
      props?.setFilterVisible(false);
    }
  };

  const convertStatusTokey = () => {
    let statusKey = "";
    Object.entries(questionCreationStatusList).map((status) => {
      if (status[1] === values.status) {
        statusKey = status[0];
      }
    });
    return statusKey ? statusKey : undefined;
  };

  const {
    values,
    errors,
    dirty,
    error,
    handleOnSubmit,
    clearStateSchema,
    setValues,
    handleOnSelection,
  } = useForm(
    filterByAuthor ? reviewQuestionFilterState : questionFilterState,
    onSubmit
  );

  const convertNameToId = () => {
    return (
      authorsList[
        authorsOptions.findIndex((author) => author === values?.authorId)
      ]?.authorId ?? undefined
    );
  };

  useEffect(() => {
    if (values.subject) {
      let subjectWiseCategoryList = [];
      subjectCategoriesList?.map((subject) => {
        if (subject?.subjectName === values.subject) {
          subjectWiseCategoryList = subject?.categories;
        }
      });
      setSubjectWiseCategoriesList(subjectWiseCategoryList);
      setSubjectWiseCategoriesOptions(
        convertListToOptions(subjectWiseCategoryList, "categoryName")
      );
    } else {
      setSubjectWiseCategoriesList([]);
      setSubjectWiseCategoriesOptions([]);
    }
  }, [values?.subject]);

  return (
    <>
      <Flyout open={props?.open}>
        <FlyoutHeader
          header={"FILTER"}
          closeHandler={() => {
            props?.setFilterVisible(false);
          }}
        />
        <div className="question__filter">
          {filterByAuthor ? (
            <DropDownField
              title={Strings.FIELD_TITLE_AUTHOR_NAME}
              options={authorsOptions}
              placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
              fieldName="authorId"
              data={values?.authorId}
              onChangeHandler={(e) => handleOnSelection(e, true)}
              hasError={errors?.authorId && dirty?.authorId}
              errorMessage={errors?.authorId}
            />
          ) : (
            <>
              <DropDownField
                title={Strings.FIELD_TITLE_SUBJECT}
                options={subjectsOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                fieldName="subject"
                data={values?.subject}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.subject && dirty?.subject}
                errorMessage={errors?.subject}
              />
              <DropDownField
                title={Strings.FIELD_TITLE_QP_LANGUAGE}
                classes={"mt-3"}
                options={languageOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                fieldName="language"
                data={values?.language}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.language && dirty?.language}
                errorMessage={errors?.language}
              />
              <DropDownField
                title={Strings.FIELD_TITLE_CATEGORY}
                classes={"mt-3"}
                options={subjectWiseCategoriesOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                fieldName="category"
                data={values?.category}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.category && dirty?.category}
                errorMessage={errors?.category}
              />
              <DropDownField
                fieldName="level"
                title={Strings.FIELD_TITLE_LEVEL}
                classes={"mt-3"}
                options={levelsOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                data={values?.level}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.level && dirty?.level}
                errorMessage={errors?.level}
              />
              <DropDownField
                title={Strings.FIELD_TITLE_TYPE}
                classes={"mt-3"}
                options={typesOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                fieldName="type"
                data={values?.type}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.type && dirty?.type}
                errorMessage={errors?.type}
              />
              <DropDownField
                title={Strings.FIELD_TITLE_STATUS}
                classes={"mt-3"}
                options={questionCreationStatusOptions}
                placeholder={Strings.FIELD_TITLE_PLACEHOLDER_ALL}
                fieldName="status"
                data={values?.status}
                onChangeHandler={(e) => handleOnSelection(e, true)}
                hasError={errors?.status && dirty?.status}
                errorMessage={errors?.status}
              />
              <Switch
                title={Strings.FIELD_TITLE_PART_OF_GROUP_QUE}
                isChecked={values?.isGroupPassage}
                titleClass={"question-part-of-group"}
                classes={"mt-3 flex-row"}
                onChangeHandler={onChangeGroupPassageHandler}
                data={values?.isGroupPassage}
              />
            </>
          )}
        </div>
        <QuestionFilterFooter
          handleOnSubmit={handleOnSubmit}
          clearStateSchema={clearStateSchema}
        />
      </Flyout>
    </>
  );
};
