import FlyoutHeader from "components/widgets/flyout-header/FlyoutHeader";
import { FlyoutFooter } from "components/laylouts/flyout-footer/FlyoutFooter";
import TextButton from "components/widgets/text-Button/TextButton";
import { FileInputField } from "components/widgets/file-input-field/FileInputField";
import "./ManageQuestionAddImport.css";
import { useDispatch } from "react-redux";
import { importQuestion } from "redux/manage-question/manage-question-slice";
import { getStorageItem, notifyError, notifySuccess, uploadFile } from "utils/utility";
import { useForm } from "utils/customeHooks/UseForm";
import {
  BtnTitles,
  Strings,
  importQuestionUrls,
} from "utils/constants/constants";
import { useEffect, useState } from "react";
import { Strings as ManageQuestionStrings } from "redux/manage-question/manage-question-static";
import { successStatusCodes } from "utils/constants/common";

export function ManageQuestionAddImport(props) {
  const { closeFlyoutDocument, selectedInstanceData, setImportFile } = props;
  const [fileName, setFileName] = useState("");
  const accessToken = getStorageItem("accessToken");
  const idtoken = getStorageItem("idToken");
  const dispatch = useDispatch();

  const importQuestionState = {
    doc: {
      value: "",
      error: "",
      required: true,
    },
  };

  useEffect(() => {
    setImportFile("");
  }, []);

  const onSubmit = (state, error) => {
    if (!error) {
      let requestParam = {
        instanceId: selectedInstanceData?._id,
        clientId: selectedInstanceData?.clientId,
      };
      const extension = values?.doc.split(".").pop();
      requestParam = {
        ...requestParam,
        fileUrl: values?.doc,
        fileType: extension,
        fileName: fileName?.split(".")?.[0],
      };
      dispatch(importQuestion(requestParam))
        .then((res) => {
          if (successStatusCodes.includes(res?.payload?.statusCode)) {
            notifySuccess(res?.payload?.message);
            closeFlyoutDocument();
          } else {
            notifyError(res?.payload?.data?.message);
          }
        })
        .finally(() => {});
    }
  };

  const {
    values,
    setValues,
    errors,
    dirty,
    handleOnSubmit,
    clearStateSchema,
    setNewErrorAndDirty,
  } = useForm(importQuestionState, onSubmit);

  const onUploadCsvFile = (e) => {
    let fileSelected = e;
    if (
      !fileSelected?.name?.match(".xlsx") &&
      !fileSelected?.name?.match(".zip")
    ) {
      notifyError(Strings?.INVALID_FILE_FORMAT);
      return;
    } else {
      uploadFile(fileSelected, "qpa")
        .then((response) => {
          if (response?.url) {
            setValues((prevState) => ({
              ...prevState,
              doc: response?.url,
            }));
            setFileName(fileSelected?.name);
            setImportFile(response?.url);
          }
          setNewErrorAndDirty("doc", "");
        })
        .catch((error) => {
          notifyError(error?.message);
        });
    }
  };

  const clearHandler = () => {
    clearStateSchema();
    setImportFile("");
  };

  return (
    <>
      <FlyoutHeader
        header={"Import Question"}
        closeHandler={(e) =>
          closeFlyoutDocument(
            e,
            values?.doc ? ManageQuestionStrings?.CONFIRM_CLOSE_IMPORT : ""
          )
        }
      />
      <div className="mq-add-import">
        <a
          href={
            importQuestionUrls.UPLOAD_FILE_PREFIX +
            importQuestionUrls.SAMPLE_URL
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {Strings.LINK_TITLE}
        </a>
        <FileInputField
          fieldName="doc"
          accepts={".xlsx, .zip"}
          title={Strings.FIELD_TITLE_ATTACH_DOCUMENTS}
          description={Strings.FIELD_DESCRIPTION_ZIP_XLSX}
          onChangeHandler={onUploadCsvFile}
          required={true}
          hasError={errors?.doc && dirty?.doc}
          errorMessage={errors?.doc}
          infoIcon={false}
          className={"mt-1"}
        />
        {values?.doc ? (
          <a
            href={
              importQuestionUrls.UPLOAD_FILE_PREFIX +
              values?.doc +
              "?token=" +
              accessToken +
              "&idtoken=" +
              idtoken
            }
            target="_blank"
          >
            {fileName}
          </a>
        ) : null}
      </div>
      <div className="mq-footer-add">
        <FlyoutFooter>
          <TextButton
            title={BtnTitles.CLEAR}
            rounded={false}
            isPrimary={false}
            clickHandler={clearHandler}
          />
          <TextButton
            title={BtnTitles.IMPORT}
            rounded={false}
            clickHandler={handleOnSubmit}
          />
        </FlyoutFooter>
      </div>
    </>
  );
}

export default ManageQuestionAddImport;
