import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export function RenderDetailField({
  label,
  inputField,
  className = "",
  keyClass,
  valuesClass = "",
  required = false,
  value = "",
}) {
  const [isLangTranslator, setIsLangTranslator] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/translate-question"){
      setIsLangTranslator(true);
    }
  }, [location?.pathname]);

  return (
    <div className={`d-flex flex-col w-20 ${className}`}>
      {label ? (
        <>
          <span className={`question-key ${keyClass}`}>
            {label}
            {required && !isLangTranslator ? (
              <span className="required-asterisk">*</span>
            ) : null}
          </span>
          <span className={` question-value ${valuesClass}`}>
            {value && isLangTranslator ? value : inputField}
          </span>
        </>
      ) : null}
    </div>
  );
}

export default RenderDetailField;
