import {
  IconDelete,
  IconEdit,
  IconEye,
  IconFilter,
  IconInfo,
} from "assets/svgs/Svgs";
import { Table } from "components/laylouts/table/Table";
import React, { useEffect, useState } from "react";
import "./ManageQuestionsListing.css";
import { QuestionFilter } from "components/laylouts/question-filter/QuestionFilter";
import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import { roleTypeNameStrings } from "utils/constants/constants";
import TextButton from "components/widgets/text-Button/TextButton";
import InputField from "components/widgets/input-field/InputField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import QuestionAnswerPreviewModal from "components/laylouts/question-answer-preview-modal/QuestionAnswerPreviewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getSecretKeyPopupMode,
  setPopupMode,
} from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-slice";
import { Strings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import { getUserDetails } from "redux/login/login-slice";
import {
  deleteManyQuestions,
  deleteQuestion,
  fetchQuestionDetail,
  fetchQuestionPreview,
  getAuthorsList,
  getCheckBoxState,
  getmanageQuestionState,
  setCheckBoxState,
  setFlyoutMode,
  setSelectedId,
  updateStatus,
} from "redux/manage-question/manage-question-slice";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import {
  convertData,
  hasAddEditAccess,
  hasTranslationAccess,
  notifyError,
  notifySuccess,
} from "utils/utility";
import {
  getAddQuestionState,
  getAllLanguages,
  getQuestionLoader,
} from "redux/add-question/add-question-slice";
import { Strings as SecretKeyPopupStrings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import { Strings as ManageQuestionsStrings } from "redux/manage-question/manage-question-static";
import { globalInstanceData } from "redux/profile/profile-slice";
import _ from "lodash";
import AddSecretKey from "components/laylouts/manage-secret-key/add-secret-key/AddSecretKey";
import { renderQuestionCreationStatus } from "./ManageQuestionsListingUtils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import RejectReasonModal from "components/laylouts/reject-reason-modal/RejectReasonModal";
import { redirectToAddUpdateQuestion } from "views/add-question/add-question-utility/AddQuestionUtility";
import {
  MANAGE_QUESTIONS_STRINGS,
  successStatusCodes,
} from "utils/constants/common";
import AppConstants from "utils/constants/app-constants";
import Loader from "components/widgets/loader/Loader";
import { regex } from "utils/constants/commonRegex";

const ManageQuestionsListing = React.forwardRef((props, ref) => {
  const {
    onChangeParams,
    loader,
    setLoader,
    isPreviewModalOpen,
    setPreviewModalOpen,
    questionParams,
    showRequestList,
    showQuestionList,
    getQuestionsListing,
    getRequestList,
    searchText,
    setSearchText,
    filterVisible,
    setFilterVisible,
    totalRecords,
    setQuestionParams,
    title,
  } = props;
  const [isShowAction, setIsShowAction] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [dropdownOptions] = useState([]);
  const [statusToUpdate, setStatusToUpdate] = useState({});
  const [isSinglePreview, setIsSinglePreview] = useState(false);
  const [statusParams, setStatusParams] = useState({});
  const [previewQuestionsData, setPreviewQuestionsData] = useState([]);
  const [actionsIds, setActionIds] = useState([]);
  const [approveModalOpen, setApproveModalOpen] = useState({
    open: false,
    data: null,
  });
  const userData = useSelector(getUserDetails);
  const selectedInstanceData = useSelector(globalInstanceData);
  const manageQuestionState = useSelector(getmanageQuestionState);
  const loadingStatus = useSelector(getQuestionLoader);
  const onlineAuthorsList = useSelector(getAuthorsList);
  const popupMode = useSelector(getSecretKeyPopupMode);
  const isCheckBoxChecked = useSelector(getCheckBoxState);
  const onlineMastersList = useSelector(getAddQuestionState);
  const onlineLanguageList = useSelector(getAllLanguages);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { flyoutMode, selectedId, selectedActions } = manageQuestionState;
  const { roles } = userData;
  const { roleName } = roles;
  const typesList = onlineMastersList?.typesList;
  const levelsList = onlineMastersList?.levelsList;
  const subjectCategoriesList = onlineMastersList?.subjectCategoriesList;
  const authorsList = onlineAuthorsList;
  const languageList = onlineLanguageList;
  const questionCreationStatusList =
    onlineMastersList?.questionCreationStatusList;
  const questionList = manageQuestionState?.questionList;
  const isManageQuestionScreen =
    location?.pathname === ROUTES_CONSTANTS.questionListing ||
    location?.pathname === ROUTES_CONSTANTS.home;

  useEffect(() => {
    setFilterVisible(false);
    dispatch(setCheckBoxState(false));
  }, [location?.pathname]);

  useEffect(() => {
    dispatch(setCheckBoxState(updateChildBoxState(actionsIds)));
  }, [questionList]);

  useEffect(() => {
    let countChecked = actionsIds?.length;
    setIsShowAction(countChecked > 1);
    updateChildBoxState(actionsIds);
  }, [actionsIds]);

  useEffect(() => {
    setActionIds([]);
  }, [title]);

  const statusCondition = (data) =>
    data?.status !== "PENDING" &&
    data?.status !== "LANGUAGE_REJECTED" &&
    data?.status !== "REJECTED";

  const updateChildBoxState = (idsList) => {
    const pendingQuestions =
      questionList?.length > 0 && idsList?.length > 0
        ? questionList?.filter((question) => !statusCondition(question))
        : [];
    const finalQuestionsList = isManageQuestionScreen
      ? pendingQuestions
      : questionList;
    return finalQuestionsList?.length > 0
      ? finalQuestionsList?.every((data) => idsList?.includes(data?._id))
      : false;
  };

  const getAuthorName = (id) => {
    let author = authorsList?.find((author) => author?.authorId === id);
    return author ? author?.autherFirstName + " " + author?.authorLastName : "";
  };

  const onChangeSearchHandler = (e) => {
    let searchValue = e?.target?.value;
    searchValue = searchValue?.replace(
      regex.EXCLUDE_SEARCH_STRING_SPECIAL_CHAR,
      ""
    );
    let foundSpecialChars = searchValue?.length === searchText?.length;
    if (!foundSpecialChars) {
      setSearchText(searchValue);
      if (!questionParams?.isInitialCall && selectedInstanceData) {
        if (showQuestionList) {
          getQuestionsListing(searchValue, 1);
        } else {
          getRequestList(showRequestList, searchValue, 1);
        }
      }
    }
  };

  const handleCheckboxClick = (data) => {
    let clonedArray = [...actionsIds];
    if (!clonedArray?.includes(data?._id)) {
      clonedArray?.push(data?._id);
    } else {
      let i = clonedArray?.findIndex((x) => x === data?._id);
      clonedArray?.splice(i, 1);
    }
    setActionIds(clonedArray);
    dispatch(setCheckBoxState(updateChildBoxState(clonedArray)));
  };

  const updateStatusCall = (optionsToSend) => {
    const options = { ...statusParams, ...optionsToSend };
    if (options?.actionsIds?.length === 0) {
      notifyError(ManageQuestionsStrings.SELECT_AT_LEAST_ONE_QUESTION);
      return;
    }
    dispatch(updateStatus(options))
      .then((res) => {
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          notifySuccess(res?.payload?.data?.message);
          onChangeParams(questionParams);
          setActionIds([]);
        } else {
          notifyError(res?.payload?.data?.message);
        }
      })
      .catch((error) => notifyError(error?.message))
      .finally(() => closeSecretKeyPopup());
  };

  const onChangeStatusHandler = (e, data, single = true) => {
    let option = selectedActions.find((x) => x?.label === e?.target?.value);
    if (option) {
      let statusOptions = {
        status: option.status,
      };
      let value = option?.requestor;
      if (value) {
        statusOptions[value] = true;
      }
      setStatusToUpdate(statusOptions);
      if (option?.showKeyPopup) {
        if (option?.label?.includes(ManageQuestionsStrings.REJECT)) {
          setStatusParams({
            status: option.status,
            instanceId: selectedInstanceData?._id,
            questionIds: single ? [data?._id] : actionsIds,
          });
          dispatch(setPopupMode(Strings.POPUP_MODE_REJECT));
        } else if (
          option?.label?.includes(ManageQuestionsStrings.FLYOUT_MODE_DELETE)
        ) {
          const options = {
            instanceId: selectedInstanceData?._id,
            questionIds: single ? [data?._id] : actionsIds,
          };
          setStatusParams(options);
          onConfirmDeleteHandler(true, options);
          setActionIds([]);
        } else {
          if (single) {
            setStatusToUpdate({ ...statusOptions, questionIds: [data?._id] });
          }
          dispatch(setPopupMode(Strings.POPUP_MODE_ADD));
        }
      } else {
        const optionsToSend = {
          requestName: data?.requestName,
          instanceId: selectedInstanceData?._id,
          questionIds: single ? [data?._id] : actionsIds,
          ...statusOptions,
        };
        if (option?.label === "Approve") {
          setApproveModalOpen({ open: true, data: optionsToSend });
          return;
        }
        updateStatusCall(optionsToSend);
      }
    }
  };

  const onConfirmApproveQuestion = () => {
    updateStatusCall(approveModalOpen?.data);
    setApproveModalOpen({ open: false, data: null });
  };

  const handleDeleteMultipleQuestions = () => {
    const options = {
      instanceId: selectedInstanceData?._id,
      questionIds: actionsIds,
    };
    setStatusParams(options);
    dispatch(setFlyoutMode({ mode: Strings.POPUP_MODE_MULTIPLE_DELETE }));
  };

  const onIconPreviewBtn = (e, ele, isPreview) => {
    e?.preventDefault();
    if (roleName !== roleTypeNameStrings.USER_ROLE_AUTHOR) {
      history.push(ROUTES_CONSTANTS.questionsDetail);
    } else {
      onChangePreviewVisibility(isPreview);
      setPreviewLoader(true);
      dispatch(
        fetchQuestionDetail({
          id: isPreview?._id,
          data: { instanceId: selectedInstanceData?._id },
        })
      )
        .then((res) => {
          if (successStatusCodes.includes(res?.payload?.statusCode)) {
            const question = res?.payload?.data;
            setIsSinglePreview(true);
            setPreviewQuestionsData([question]);
          }
        })
        .finally(() => {
          setPreviewLoader(false);
        });
    }
  };

  const onIconEdit = (e, val, id, data) => {
    e?.preventDefault();
    dispatch(setFlyoutMode({ mode: val, id: id }));
    if (location?.pathname === ROUTES_CONSTANTS.translateQuestionsList) {
      history.push({
        pathname: ROUTES_CONSTANTS.translateQuestion,
        state: { mode: val, id: id, item: data },
      });
    } else {
      redirectToAddUpdateQuestion(
        val,
        id,
        data,
        ROUTES_CONSTANTS.addQuestion,
        history,
        dispatch
      );
    }
  };

  const onIconDelete = (e, val, id) => {
    e?.preventDefault();
    dispatch(setFlyoutMode({ mode: val, id: id }));
  };

  const convertListElement = (data, listArr, key1, key2, code) => {
    const ele = convertData(
      listArr,
      code,
      key1,
      key1 === "difficultyCode" ? Number(data[key2]) : data[key2]
    );
    return (
      <span title={ele} className="text-wrap-ellipsis">
        {ele ? ele : "-"}
      </span>
    );
  };

  const onClickEditIconHandler = (e, data) => {
    if (
      !data?.isEditDisable ||
      (location?.pathname === ROUTES_CONSTANTS.translateQuestionsList &&
        data?.status === ManageQuestionsStrings.LANGUAGE_TRANSLATOR)
    ) {
      onIconEdit(e, ManageQuestionsStrings.FLYOUT_MODE_EDIT, data?._id, data);
    }
  };

  const onClickDeleteIconHandler = (e, data) => {
    if (
      location?.pathname === ROUTES_CONSTANTS.translateQuestionsList &&
      data?.status === ManageQuestionsStrings.LANGUAGE_TRANSLATOR
    )
      return;
    if (!data?.isEditDisable && !isShowAction) {
      onIconDelete(e, ManageQuestionsStrings.FLYOUT_MODE_DELETE, data?._id);
    }
  };

  const onViewGroupQuestionsHandler = (data) => {
    dispatch(setSelectedId(data?._id));
    dispatch(setPopupMode(SecretKeyPopupStrings.POPUP_MODE_VERIFY));
  };

  const onQuestionDetailView = (e, data) => {
    redirectToAddUpdateQuestion(
      undefined,
      data?._id,
      data,
      ROUTES_CONSTANTS.questionsDetail,
      history,
      dispatch
    );
  };

  let columns =
    showRequestList && !showQuestionList
      ? [
          {
            field: "requestName",
            header: ManageQuestionsStrings.REQUEST_NAME_TITLE,
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              return <span>{ele}</span>;
            },
          },
          {
            field: "authorId",
            header: ManageQuestionsStrings.AUTHOR_NAME_TITLE,
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              return <span>{getAuthorName(ele)}</span>;
            },
          },
          {
            field: "option",
            header: ManageQuestionsStrings.OPTIONS_TITLE,
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              return (
                <div className="author-list-option">
                  <span
                    className="manage-question-view"
                    onClick={() => onViewGroupQuestionsHandler(data)}
                  >
                    <IconEye />
                  </span>
                </div>
              );
            },
          },
        ]
      : [
          {
            field: "select",
            header: ManageQuestionsStrings.SELECT_TITLE,
            shouldRenderElement: true,
            actionInField: true,
            actionType: "checkbox",
            customHeaderWidth: "1",
            renderElement: (ele, index, data) => {
              const isDisabled =
                (isManageQuestionScreen && statusCondition(data)) ||
                data?.status === MANAGE_QUESTIONS_STRINGS.PUBLISHED ||
                (location?.pathname === ROUTES_CONSTANTS.reviewQuestionsList &&
                  data?.status !== MANAGE_QUESTIONS_STRINGS.IN_REVIEW) ||
                (location?.pathname ===
                  ROUTES_CONSTANTS.translateQuestionsList &&
                  data?.status !==
                    MANAGE_QUESTIONS_STRINGS.LANGUAGE_TRANSLATOR) ||
                (location?.pathname ===
                  ROUTES_CONSTANTS.translationReviewQuestionsList &&
                  data?.status !== MANAGE_QUESTIONS_STRINGS.TRANSLATOR_REVIEW);
              return (
                <>
                  <input
                    id={index}
                    name={ele + index}
                    type="checkbox"
                    checked={
                      isDisabled ? false : actionsIds?.includes(data?._id)
                    }
                    onChange={(e) => {
                      handleCheckboxClick(data);
                    }}
                    disabled={isDisabled}
                  />
                </>
              );
            },
          },
          {
            field: "questionId",
            header: ManageQuestionsStrings.ID,
            customHeaderWidth: "7",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              return (
                <span
                  className="manage-question-id"
                  onClick={(e) => onQuestionDetailView(e, data)}
                  title={
                    !data?.isSync && data?.isAdd
                      ? ManageQuestionsStrings.NOT_GENERATED
                      : data?.questionId
                  }
                  dangerouslySetInnerHTML={{
                    __html:
                      !data?.isSync && data?.isAdd
                        ? ManageQuestionsStrings.NOT_GENERATED
                        : ele,
                  }}
                />
              );
            },
            upperClass: "column-width-unset",
          },
          {
            field: "isGroupPassage",
            header: ManageQuestionsStrings.QUESTION_TYPE,
            customHeaderWidth: "10",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              const questionType = ele
                ? ManageQuestionsStrings.GROUP_QUESTION
                : ManageQuestionsStrings.NORMAL;
              return (
                <span
                  className="fs-14"
                  onClick={(e) => onQuestionDetailView(e, data)}
                  title={questionType}
                >
                  {questionType}
                </span>
              );
            },
          },
          {
            field: "language",
            header: ManageQuestionsStrings.LANGUAGE_TITLE,
            customHeaderWidth: "7",
            shouldRenderElement: true,
            renderElement: (ele) => (
              <span className="text-wrap-ellipsis" title={ele}>
                {ele ? ele : "-"}
              </span>
            ),
            upperClass: "manage-question-type",
          },
          {
            field: "subject",
            header: ManageQuestionsStrings.SUBJECT_TITLE,
            customHeaderWidth: "8",
            shouldRenderElement: true,
            renderElement: (ele, index, data) =>
              convertListElement(
                data,
                subjectCategoriesList,
                "subjectName",
                "subject",
                "subjectCode"
              ),
            upperClass: "manage-question-type",
          },
          {
            field: "category",
            header: ManageQuestionsStrings.CATEGORY_TITLE,
            customHeaderWidth: "8",
            shouldRenderElement: true,
            renderElement: (ele, index, data) =>
              convertListElement(
                data,
                Array.isArray(subjectCategoriesList) &&
                  subjectCategoriesList?.length > 0
                  ? subjectCategoriesList?.find(
                      (sub) => sub?.subjectCode === data?.subject
                    )?.categories
                  : [],
                "categoryName",
                "category",
                "categoryCode"
              ),
            upperClass: "manage-question-type",
          },
          {
            field: "level",
            header: ManageQuestionsStrings.LEVEL_TITLE,
            customHeaderWidth: "7",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              return convertListElement(
                data,
                levelsList,
                "difficultyLevel",
                "level",
                "difficultyCode"
              );
            },
            upperClass: "manage-question-type",
          },
          {
            field: "type",
            header: ManageQuestionsStrings.TYPE_TITLE,
            customHeaderWidth: "7",
            shouldRenderElement: true,
            renderElement: (ele, index, data) =>
              convertListElement(
                data,
                typesList,
                "typeName",
                "type",
                "typeCode"
              ),
            upperClass: "manage-question-type",
          },
          {
            field: "status",
            header: ManageQuestionsStrings.STATUS_TITLE,
            customHeaderWidth: "7",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              const statusName = renderQuestionCreationStatus(data?.status);
              return (
                <span
                  title={statusName?.toLowerCase()}
                  className="text-wrap-ellipsis"
                >
                  {statusName}
                </span>
              );
            },
            upperClass: "manage-question-type",
          },
          {
            field: "actions",
            header: ManageQuestionsStrings.ACTIONS_TITLE,
            customHeaderWidth: "10",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              const showDropdown =
                isManageQuestionScreen && statusCondition(data) ? false : true;
              return (
                <DropDownField
                  options={dropdownOptions}
                  showRequestList={
                    location?.state?.showRequestList || showRequestList
                  }
                  data={statusToUpdate?.status}
                  placeholder={"Select"}
                  className="dropdown_field"
                  onChangeHandler={(e) => onChangeStatusHandler(e, data)}
                  disabled={
                    isShowAction ||
                    !showDropdown ||
                    data?.status === MANAGE_QUESTIONS_STRINGS.PUBLISHED ||
                    (location?.pathname ===
                      ROUTES_CONSTANTS.reviewQuestionsList &&
                      data?.status !== MANAGE_QUESTIONS_STRINGS.IN_REVIEW) ||
                    (location?.pathname ===
                      ROUTES_CONSTANTS.translateQuestionsList &&
                      data?.status !==
                        MANAGE_QUESTIONS_STRINGS.LANGUAGE_TRANSLATOR)
                  }
                  showActionsList={true}
                />
              );
            },
          },
          {
            field: "option",
            header: ManageQuestionsStrings.OPTIONS_TITLE,
            customHeaderWidth: "10",
            shouldRenderElement: true,
            renderElement: (ele, index, data) => {
              const colorCondidtion =
                location?.pathname ===
                  ROUTES_CONSTANTS.translateQuestionsList &&
                data?.status === ManageQuestionsStrings.LANGUAGE_TRANSLATOR;
              const color =
                !data?.isEditDisable || colorCondidtion ? "" : "#d2d2d2";
              const deleteIconColor =
                (!data?.isEditDisable && !isShowAction) || colorCondidtion
                  ? ""
                  : "#d2d2d2";
              return (
                <div className="manage-question-view-container">
                  <span
                    className="manage-question-view"
                    onClick={(e) => onIconPreviewBtn(e, ele, data)}
                  >
                    <IconEye />
                  </span>
                  <>
                    {hasAddEditAccess() || hasTranslationAccess() ? (
                      <span
                        className="manage-question-edit-delete"
                        onClick={(e) => onClickEditIconHandler(e, data)}
                        title={
                          location?.pathname ===
                          ROUTES_CONSTANTS.translateQuestionsList
                            ? ManageQuestionsStrings.ADD_TRANSLATION
                            : ""
                        }
                      >
                        <IconEdit color={color} />
                      </span>
                    ) : null}
                    {hasAddEditAccess() ? (
                      <span
                        className="manage-question-edit-delete"
                        onClick={(e) => onClickDeleteIconHandler(e, data)}
                      >
                        <IconDelete color={deleteIconColor} />
                      </span>
                    ) : null}
                    {hasAddEditAccess() ? (
                      <span
                        className="manage-question-info"
                        onClick={(e) => onQuestionDetailView(e, data)}
                      >
                        <IconInfo />
                      </span>
                    ) : null}
                  </>
                </div>
              );
            },
          },
        ];

  const onChangePreviewVisibility = (isOpen) => {
    setPreviewModalOpen(isOpen);
  };

  const collasedRowDetailsToShow = () => {
    return <></>;
  };

  const onCancelDeleteHandler = () => {
    dispatch(setFlyoutMode({ mode: "" }));
  };

  const onConfirmMultipleDeleteHandler = () => {
    const options = {
      instanceId: selectedInstanceData?._id,
      questionIds: actionsIds,
    };
    setStatusParams(options);
    onConfirmDeleteHandler(true, options);
    setActionIds([]);
  };

  const onConfirmDeleteHandler = async (isMultipleDelete = false, options) => {
    let api;
    if (isMultipleDelete) {
      api = deleteManyQuestions(options);
    } else {
      api = deleteQuestion({
        id: selectedId,
        instanceId: selectedInstanceData?._id,
      });
    }
    setLoader(true);
    dispatch(api)
      .then((res) => {
        if (successStatusCodes?.includes(res?.payload?.statusCode)) {
          notifySuccess(res?.payload?.message);
          onCancelDeleteHandler();
          setLoader(false);
          if (questionList?.length === 1) {
            const page = props?.questionParams?.page;
            onChangeParams({
              ...props?.questionParams,
              page: page && page - 1 > 0 ? page - 1 : page,
            });
          } else {
            onChangeParams(props?.questionParams);
          }
        } else {
          notifyError(res?.payload?.data?.message);
        }
      })
      .finally(() => {
        setLoader(false);
        setStatusToUpdate({});
      });
  };

  const confirmHandler = () => {
    if (flyoutMode === ManageQuestionsStrings.FLYOUT_MODE_DELETE) {
      onConfirmDeleteHandler();
    } else {
      onConfirmMultipleDeleteHandler();
    }
  };

  const onClickPreviewHandler = (sentPage) => {
    let clonedParams = _.cloneDeep(questionParams);
    clonedParams.page = sentPage;
    clonedParams.offlineModeEnabled = false;
    onChangePreviewVisibility(true);
    setPreviewLoader(true);
    dispatch(fetchQuestionPreview(clonedParams))
      .then((res) => {
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          setIsSinglePreview(false);
          setPreviewQuestionsData(res?.payload?.data);
        } else {
          notifyError(res?.payload?.data?.message);
        }
      })
      .finally(() => {
        setPreviewLoader(false);
      });
  };

  const setQuestionListData = (list = [], key = "") => {
    const dataToSend = list
      ?.filter(
        (data) =>
          !actionsIds?.includes(data?.[key]) &&
          data?.status !== MANAGE_QUESTIONS_STRINGS.PUBLISHED
      )
      ?.map((ele) => ele?.[key]);
    setActionIds([...actionsIds, ...dataToSend]);
  };

  const handleHeaderCheckBox = (val) => {
    dispatch(setCheckBoxState(val));
    if (val) {
      if (location?.pathname === ROUTES_CONSTANTS?.questionListing) {
        const getPendingData = isManageQuestionScreen
          ? questionList?.filter((ele) => !statusCondition(ele))
          : questionList;
        if (getPendingData) {
          setQuestionListData(getPendingData, "_id");
        }
      } else if (location?.pathname !== ROUTES_CONSTANTS?.questionListing) {
        setQuestionListData(questionList, "_id");
      }
    } else {
      setActionIds(
        actionsIds?.filter(
          (id) => !questionList?.map((question) => question?._id)?.includes(id)
        )
      );
    }
  };

  const closeSecretKeyPopup = (e) => {
    dispatch(setPopupMode(""));
  };

  const onNoOfEntriesChange = (num) => {
    setQuestionParams((prev) => ({
      ...prev,
      perPage: num,
      page: 1,
    }));
  };

  const onQuestionFilter = (params) => {
    onChangeParams(params);
  };

  const shouldDisableCheckBox = () => {
    return (
      isManageQuestionScreen &&
      Array.isArray(questionList) &&
      questionList?.length > 0 &&
      questionList?.filter((question) => !statusCondition(question))?.length ===
        0
    );
  };

  const renderSearchFilter = () => {
    return (
      <div className="manage-question-search">
        <div className="mx-2">
          <InputField
            id="searchText"
            key="search-text"
            name="search-text"
            autocomplete="nope"
            type={"text"}
            placeholder={ManageQuestionsStrings.QUICK_SEARCH}
            fieldName={"headerTitleLan1"}
            isSearch={true}
            classes={"search-field"}
            data={searchText}
            onChangeHandler={onChangeSearchHandler}
            handleCancelSearch={(e) => setSearchText("")}
          />
        </div>
        <div>
          <div className="mx-3" onClick={() => setFilterVisible(true)}>
            <IconFilter />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`manage_questions_container ${
          isPreviewModalOpen ? "manage_question_z_index" : ""
        }`}
      >
        {isPreviewModalOpen ? (
          <QuestionAnswerPreviewModal
            isPreviewModalOpen={isPreviewModalOpen}
            onChangePreviewVisibility={onChangePreviewVisibility}
            manageQuestionListingData={questionList}
            questionParams={questionParams}
            loader={previewLoader}
            previewQuestionList={previewQuestionsData}
            onClickPreviewHandler={onClickPreviewHandler}
            totalRecords={isSinglePreview ? 1 : totalRecords}
          />
        ) : null}
        <div className={"manage-question-header"}>
          <div className="question_list">
            {hasAddEditAccess() ? (
              <>
                {!showRequestList ? (
                  <TextButton
                    title={ManageQuestionsStrings.CANDIDATE_SCREEN_PREVIEW}
                    rounded={false}
                    clickHandler={() => onClickPreviewHandler(1)}
                  />
                ) : null}
              </>
            ) : null}
          </div>
          <div className="manage-question-action">
            {isShowAction ? (
              <div className="multi-delete-conatiner">
                <TextButton
                  title={ManageQuestionsStrings.DELETE_QUESTIONS}
                  rounded={false}
                  clickHandler={handleDeleteMultipleQuestions}
                />
                <DropDownField
                  options={dropdownOptions}
                  placeholder={Strings.FIELD_PLACEHOLDER_SELECT}
                  className="dropdown_field"
                  onChangeHandler={(e) => onChangeStatusHandler(e, null, false)}
                  labelClass="d-none"
                  showRequestList={
                    location?.state?.showRequestList || showRequestList
                  }
                  showActionsList={true}
                />
              </div>
            ) : null}
          </div>
        </div>
        {loadingStatus === AppConstants.LOADING_STATUS.SYNC_INPROGRESS ? (
          <Loader textForLoader={AppConstants.LOADING_STATUS.SYNC_INPROGRESS} />
        ) : (
          <div className="manage-question-table">
            <Table
              width={showRequestList && !showQuestionList ? undefined : 120}
              data={questionList ?? []}
              numberOfEntriesToShow={questionParams?.perPage}
              columns={columns}
              isFromManageQuestions={true}
              collasedRowDetailsToShow={collasedRowDetailsToShow}
              isCollapsibleTable={true}
              setFilterVisible={setFilterVisible}
              filterVisible={filterVisible}
              childCheckBoxChecked={isCheckBoxChecked}
              setChildCheckBoxChecked={handleHeaderCheckBox}
              loader={loader}
              shouldDisableChildBox={shouldDisableCheckBox()}
              renderRightSideContent={renderSearchFilter}
              onNoOfEntriesChange={onNoOfEntriesChange}
            />
          </div>
        )}
      </div>
      <QuestionFilter
        open={filterVisible}
        setFilterVisible={setFilterVisible}
        onChangeParams={(param) => onQuestionFilter(param)}
        showRequestList={showRequestList}
        showQuestionList={showQuestionList}
        authorsList={authorsList}
        typesList={typesList}
        levelsList={levelsList}
        subjectCategoriesList={subjectCategoriesList}
        languageList={languageList}
        questionCreationStatusList={questionCreationStatusList}
        filterByAuthor={showRequestList && !showQuestionList}
      />
      {flyoutMode === ManageQuestionsStrings.FLYOUT_MODE_DELETE ||
      flyoutMode === Strings.POPUP_MODE_MULTIPLE_DELETE ? (
        <ConfirmationModal
          title={
            flyoutMode === ManageQuestionsStrings.FLYOUT_MODE_DELETE
              ? ManageQuestionsStrings.CONFIRM_DELETE
              : ManageQuestionsStrings.CONFIRM_MULTIPLE_DELETE
          }
          onCancel={onCancelDeleteHandler}
          confirmHandler={confirmHandler}
          btnText={ManageQuestionsStrings.FLYOUT_MODE_DELETE}
          show={
            flyoutMode === ManageQuestionsStrings.FLYOUT_MODE_DELETE ||
            flyoutMode === Strings.POPUP_MODE_MULTIPLE_DELETE
          }
        />
      ) : null}
      {approveModalOpen?.open ? (
        <ConfirmationModal
          title={ManageQuestionsStrings.CONFIRM_APPORVE_QUESTION}
          onCancel={() => setApproveModalOpen({ open: false, data: null })}
          confirmHandler={() => onConfirmApproveQuestion()}
          btnText={ManageQuestionsStrings.CONFIRM}
          show={approveModalOpen?.open}
        />
      ) : null}
      <AddSecretKey
        secretKeyModal={popupMode === Strings.POPUP_MODE_ADD}
        secretKeyModalClose={closeSecretKeyPopup}
        closeSecretKeyPopup={(e) => closeSecretKeyPopup(e)}
        questionCreationStatusList={questionCreationStatusList}
        onChangeParams={onChangeParams}
        actionsIds={actionsIds}
        statusToUpdate={statusToUpdate}
        setActionIds={setActionIds}
        questionParams={questionParams}
      />
      <RejectReasonModal
        title={ManageQuestionsStrings.ADD_COMMENT}
        show={popupMode === Strings.POPUP_MODE_REJECT}
        closeSecretKeyPopup={closeSecretKeyPopup}
        onSubmit={updateStatusCall}
        reasons={true}
      />
    </>
  );
});

export default ManageQuestionsListing;
