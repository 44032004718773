import React from "react";
import "./Modal.css";

const Modal = (props) => {
  const {
    show,
    closeHandler,
    className = "",
    modalContentClassName = "",
    overlay = "",
  } = props;

  return (
    <div className="modal-position">
      {show && (
        <div className={`common-modal ${className}`}>
          <div
            onClick={closeHandler}
            className={overlay ? overlay : "overlay"}
          ></div>

          <div className={`common-modal-content ${modalContentClassName}`}>
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
