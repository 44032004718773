import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import {
  allInstances,
  allInstancesNames,
  fetchNotification,
  globalInstanceData,
  setGlobalInstanceData,
  setInstanceNames,
  setInstances,
} from "redux/profile/profile-slice";
import "./NavHeader.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onMessageListener } from "firebase";
import { notificationDefaultParams } from "utils/constants/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getStorageItem,
  notifyNotification,
  saveItemToStorage,
} from "utils/utility";

export function NavHeader(props) {
  const { title, shouldDisplayInstanceSelection = false } = props;
  const [isInstanceUser, setIsInstanceUser] = useState(
    getStorageItem("isInstanceUser") || false
  );
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const selectedInstanceData = useSelector(globalInstanceData);
  const instances = useSelector(allInstances);
  const instanceNames = useSelector(allInstancesNames);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    const unsubscribe = onMessageListener((payload) => {
      if (payload) {
        renderNotification(payload);
        dispatch(fetchNotification(notificationDefaultParams));
      }
    });
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
      unsubscribe?.catch((err) => console.log("failed: ", err));
    };
  }, []);

  useEffect(() => {
    if (getStorageItem("globalInstanceData")) {
      setGlobalInstanceData(getStorageItem("globalInstanceData"));
    }
  }, [localStorage.getItem("globalInstanceData")]);

  useEffect(() => {
    if (getStorageItem("isInstanceUser")) {
      setIsInstanceUser(getStorageItem("isInstanceUser"));
    }
  }, [localStorage.getItem("isInstanceUser")]);

  useEffect(() => {
    if (getStorageItem("loggedInUserData")) {
      const loggedInUserData = getStorageItem("loggedInUserData");
      if (loggedInUserData.isInstanceUser) {
        saveItemToStorage("isInstanceUser", loggedInUserData?.isInstanceUser);
        if (!instances || instances?.length === 0) {
          if (loggedInUserData.roleDetails) {
            let roleDetails = loggedInUserData?.roleDetails;
            let instances = [];
            let instancesNames = [];
            roleDetails?.length > 0 &&
              roleDetails.forEach((ele) => {
                if (ele?.instanceId && ele?.instanceName) {
                  instances.push({
                    _id: ele?.instanceId,
                    name: ele?.instanceName,
                    isClientApproved: ele?.isClientApproved ?? false,
                    clientId: ele?.clientId ?? "",
                  });
                  instancesNames.push(ele?.instanceName);
                }
              });
            if (instancesNames?.length > 0) {
              dispatch(setInstances(instances));
              dispatch(setInstanceNames(instancesNames));
            }
          }
        }
      }
    }
  }, [localStorage.getItem("loggedInUserData")]);

  const renderNotification = (payload) => {
    const { title, body, data } = payload.notification;
    const notificationMessage = `${title}: ${body}`;
    const handleClick = () => {
      if (data?.redirectTo?.length > 0) {
        history.push(data?.redirectTo);
      }
    };
    return <div>{notifyNotification(notificationMessage, handleClick)}</div>;
  };

  const updateNetwork = () => {
    setIsOnline(window.navigator.onLine);
  };

  const handleOnChange = (e) => {
    let instanceToStore;
    if (e?.target?.value?.length === 0) {
      instanceToStore = instances?.[0];
    } else {
      instances?.forEach((ele) => {
        if (ele?.name === e?.target?.value) {
          instanceToStore = ele;
          dispatch(setGlobalInstanceData(instanceToStore));
          return false;
        }
      });
    }
  };

  return (
    <div
      className={
        "headCol d-flex justify-content-between align-items-center pr-2"
      }
    >
      <h1>
        <b>{title}</b>
      </h1>
      {isInstanceUser &&
      shouldDisplayInstanceSelection &&
      instanceNames?.length > 0 ? (
        <div className="w-25">
          <DropDownField
            fieldName="instance"
            placeholder="Select Instance"
            data={selectedInstanceData?.name}
            options={instanceNames}
            onChangeHandler={handleOnChange}
          />
        </div>
      ) : null}
    </div>
  );
}
