import { ApiCaller } from "utils/network/Network";
import AppConstants from "utils/constants/app-constants";

export async function fetchAllPassageApi(options) {
  return ApiCaller.post(AppConstants.passage.PASSAGE_LIST, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function addPassageApi(options) {
  return ApiCaller.post(AppConstants.passage.CREATE_PASSAGE, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function editPassageApi(options) {
  return ApiCaller.patch(
    `${AppConstants.passage.UPDATE_PASSAGE}${options?.passageId}`,
    options?.data
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function passageDetailApi(options) {
  return ApiCaller.get(
    `${AppConstants.passage.GET_PASSAGE}${options?.passageId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deletePassageApi(options) {
  return ApiCaller.delete(
    `${AppConstants.passage.DELETE_PASSAGE}${options?.passageId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
