import "./DrawerHeader.css";

export function DrawerHeader(props) {
  const { heading, name } = props;

  return (
    <div className="drawerHeaderDetails">
      <h1>{heading}</h1>
      <span>{name}</span>
    </div>
  );
}
