import { useCallback, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import "./InputEditor.css";
import $ from "jquery";

const jsDemoImagesTransform = document?.createElement("script");
jsDemoImagesTransform.type = "text/javascript";
jsDemoImagesTransform.src =
  "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
document?.head?.appendChild(jsDemoImagesTransform);

window.$ = $;
window.tinymce = require("tinymce");
require("@wiris/mathtype-tinymce6");

export function EtInputEditor(props) {
  const {
    key = "input_editor",
    onChange,
    style = {},
    title = "",
    data = "<p></p>",
    disabled = false,
    height = 200,
    required = false,
    allowResize = true,
    hasError = false,
    id = "",
  } = props;

  const [debouncedState, setDebouncedState] = useState("");
  const [content, setContent] = useState("");

  let editorRef = useRef();

  function handleChange(content) {
    debounce.cancel();
    onChange(editorRef?.current?.editor);
    setContent(content);
  }

  const debounce = useCallback(
    _.debounce((content) => {
      setDebouncedState(content);
      props.onChange(content);
    }, 1000),
    []
  );

  return (
    <div
      className={`field-group ${hasError ? "has-error " : ""}`}
      style={style && { borderColor: style["field-border-color"] }}
      key={title ? title : key}
    >
      <label
        className={`field-group_label`}
        style={style && { color: style["label-color"] }}
      >
        <span>
          {title}
          {required ? <span className="required-asterisk">*</span> : null}
        </span>
      </label>
      <Editor
        id={id}
        ref={editorRef}
        disabled={disabled}
        apiKey="y8h9a6yuifkda3dhzjcgdxn5ho94ys0l01blbqwmfvyscm6t"
        value={data}
        content={content}
        outputFormat="html"
        init={{
          resize: allowResize,
          height: { height },
          menubar: true,
          skin: `bootstrap`,
          promotion: false,
          selector: "textarea",
          font_formats:
            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Source Sans Pro=source sans pro; Source Sans Pro Semi-Bold=source sans pro semi-bold; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');",

          plugins: ["link", "lists", "table", "code", "preview"],
          external_plugins: {
            tiny_mce_wiris:
              "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
          },
          toolbar:
            "sizeselect | bold italic | styleselect  | fontselect |  fontsizeselect | image imagetools forecolor backcolor preview  a11ycheck addcomment showcomments checklist code formatpainter pageembed permanentpen undo redo | formatselect | \
            alignleft aligncenter alignright | \
            tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | \
            bullist outdent indent | table | code | help", //numlist
          htmlAllowedTags: [".*"],
          htmlAllowedAttrs: [".*"],
          extended_valid_elements: "*[.*]",
          toolbar_mode: "floating",
        }}
        onEditorChange={(content, editor) => {
          handleChange(content);
        }}
      />
    </div>
  );
}

export default EtInputEditor;
