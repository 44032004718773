export const AnswerOptions = ({ data }) => {
  return (
    <div className="d-flex flex-col mt-3 question-options">
      {data?.type !== "TB" &&
        data?.answerOptions?.map((option, index) => (
          <div className="d-flex flex-row radio-gap">
            <span>{index + 1}.</span>
            <div className="d-flex flex-col">
              {option?.optionName ? (
                <span
                  className="preview-option-name word-break-all"
                  dangerouslySetInnerHTML={{
                    __html: option?.optionName,
                  }}
                />
              ) : null}
              {option?.image?.value ? (
                <img src={option?.image?.value} width="100px" />
              ) : null}
            </div>
          </div>
        ))}
    </div>
  );
};

export default AnswerOptions;
