import { useRef } from "react";

export function Wrapper(props) {
  const wrapperRef = useRef(null);

  return (
    <div className="icon" ref={wrapperRef}>
      {props.children}
    </div>
  );
}
export default Wrapper;
