import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { secretKeyPopupActions } from "./secret-key-popup-visibility-actions";
import { Strings } from "./secret-key-popup-visibility-static";

export const secretKeyPopupAdapter = createEntityAdapter();

export const setPopupMode = createAction(
  secretKeyPopupActions.SECRET_KEY_POPUP_MODE
);

export const initialSecretKeyPopupState = secretKeyPopupAdapter.getInitialState(
  {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
    popupMode: "",
    user: Strings.USER_TYPE_REVIEWER,
  }
);

export const secretKeyPopupSlice = createSlice({
  name: secretKeyPopupActions.FEATURE_KEY,
  initialState: initialSecretKeyPopupState,
  reducers: {
    add: secretKeyPopupAdapter.addOne,
    remove: secretKeyPopupAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(setPopupMode, (state, action) => {
      state.popupMode = action.payload;
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const scretKeyPopupReducer = secretKeyPopupSlice.reducer;
export const scretKeyPopupAction = secretKeyPopupSlice.actions;

export const getSecretKeyPopupState = (rootState) =>
  rootState[secretKeyPopupActions.FEATURE_KEY];

export const getSecretKeyPopupMode = createSelector(
  getSecretKeyPopupState,
  (state) => state.popupMode
);

export const getUser = createSelector(
  getSecretKeyPopupState,
  (state) => state.user
);
