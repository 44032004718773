import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import {
  EyeCloseIcon,
  EyeOpenIcon,
  IconEduTest,
  LoginPageImage,
} from "assets/svgs/Svgs";
import FormGroup from "components/widgets/formgroup/FormGroup";
import { FieldGroup } from "components/widgets/fieldgroup/FieldGroup";
import InputField from "components/widgets/input-field/InputField";
import TextButton from "components/widgets/text-Button/TextButton";
import { useForm } from "utils/customeHooks/UseForm";
import { MesssagToaster } from "components/widgets/msg-toaster/MessageToaster";
import "./login.css";
import { PASSWORD_STATE } from "utils/constants/common";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import Loader from "components/widgets/loader/Loader";
import PasswordStrengthBar from "react-password-strength-bar";
import Image from "components/widgets/image/Image";
import {
  getStorageItem,
  notifyError,
  notifySuccess,
  saveItemToStorage,
} from "utils/utility";
import { loginInitialState } from "./LoginInitialState";
import { fetchPublicKey, userLoginAPI } from "redux/login/login-slice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BtnTitles, Strings } from "utils/constants/constants";
import { setGlobalInstanceData } from "redux/profile/profile-slice";

export const Login = (props) => {
  const [confirmPasswordMatched, setConfirmPasswordMatched] = useState(
    PASSWORD_STATE.NOT_STATED
  );
  const [forgotPasswordMode, setForgotPasswordMode] = useState({
    mode: "",
    code: false,
  });
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [pwdVisibility, setPwdVisibility] = useState({
    password: true,
    newPassword: true,
    confirmPassword: true,
  });
  const [loader, setLoader] = useState(true);
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const firebaseToken = getStorageItem("firebaseToken");

  useEffect(() => {
    dispatch(setGlobalInstanceData({}));
    saveItemToStorage("globalInstanceData", "");
    saveItemToStorage("firebaseToken", firebaseToken);
    const listener = (event) => {
      if (event?.code === "Enter" || event?.code === "NumpadEnter") {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document?.addEventListener("keydown", listener);
    if (props?.location?.state?.isFromLogoutButton) {
      localStorage.clear();
    } else {
      setLoader(true);
      props?.history?.goForward();
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    return () => {
      document?.removeEventListener("keydown", listener);
    };
  }, []);

  const loginHandler = (params, error) => {
    const { email = "", password = "", newPassword = "" } = values;
    if (!error) {
      saveItemToStorage("Username", email);
      signIn(email?.trim(), password, newPassword);
    }
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit, setValues } =
    useForm(loginInitialState, loginHandler);

  useEffect(() => {
    const { newPassword, confirmPassword } = values;
    if (newPassword && confirmPassword) {
      setConfirmPasswordMatched(
        newPassword === confirmPassword
          ? PASSWORD_STATE.PASSWORD_MATCH
          : PASSWORD_STATE.PASSWORD_NOT_MATCH
      );
    }
  }, [values, PASSWORD_STATE]);

  const forgotPasswordHandler = () => {
    setForgotPasswordMode((prev) => ({
      ...prev,
      mode:
        prev?.mode === Strings.MODE_FORGOT_PWD ? "" : Strings.MODE_FORGOT_PWD,
    }));
    setValues((prevState) => ({
      ...prevState,
      forgotPassword: "",
      forgotEmail: "",
    }));
  };

  const resetPasswordBackButtonHandler = () => {
    setForgotPasswordMode((prev) => ({
      ...prev,
      mode: Strings.MODE_FORGOT_PWD,
      code: false,
    }));
    setValues((prevState) => ({
      ...prevState,
      confirmPassword: "",
      newPassword: "",
      confirmationCode: "",
    }));
  };

  const handleForgotPasswordChange = async () => {
    try {
      setLoading(true);
      await Auth.forgotPassword(values?.forgotEmail)
        .then((data) => {
          setLoading(false);
          setForgotPasswordMode((prev) => ({
            ...prev,
            mode: Strings.MODE_NEW_PWD,
            code: true,
          }));
        })
        .catch((err) => {
          setLoading(false);
          notifyError(err?.message);
        });
    } catch (err) {
      notifyError(err);
    }
  };

  const handleForgotPasswordWithCodeSubmit = async () => {
    if (!errors?.newPassword && !errors?.confirmationCode) {
      try {
        setLoading(true);
        const { forgotEmail, confirmationCode, confirmPassword } = values;
        await Auth.forgotPasswordSubmit(
          forgotEmail,
          confirmationCode,
          confirmPassword
        )
          .then(() => {
            setLoading(false);
            notifySuccess("Password changed successfully");
            setForgotPasswordMode((prev) => ({
              ...prev,
              mode: "",
              code: false,
            }));
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err?.message);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getPublicKey = () => {
    dispatch(fetchPublicKey()).then((res) => {
      setLoading(false);
      if (res?.payload?.data?.publicKey) {
        history.push(ROUTES_CONSTANTS.myProfile);
      } else {
        notifyError(res?.payload?.data?.message);
      }
    });
  };

  const saveTokens = (accessToken, refreshToken, idToken) => {
    saveItemToStorage("isUserLoggedIn", true);
    saveItemToStorage("accessToken", accessToken);
    saveItemToStorage("refreshToken", refreshToken);
    saveItemToStorage("idToken", idToken);
  };

  async function signIn(email, password, newPassword) {
    try {
      setLoading(true);
      setLoginError("");
      await Auth.signIn(email, password).then((user) => {
        if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
          setForgotPasswordMode((prev) => ({
            ...prev,
            mode: Strings.MODE_NEW_PWD,
          }));

          Auth.completeNewPassword(user, newPassword)
            .then((response) => {
              const accessToken =
                response?.signInUserSession?.accessToken?.jwtToken;
              const refreshToken =
                response?.signInUserSession?.refreshToken?.token;
              const idToken = user?.signInUserSession?.idToken?.jwtToken;
              saveTokens(accessToken, refreshToken, idToken);
              dispatch(userLoginAPI());
              notifySuccess("Password changed successfully");
              window.location.href = ROUTES_CONSTANTS.myProfile;
            })
            .catch((e) => {
              console.log("error signing in", e);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          const accessToken = user?.signInUserSession?.accessToken?.jwtToken;
          const refreshToken = user?.signInUserSession?.refreshToken?.token;
          const idToken = user?.signInUserSession?.idToken?.jwtToken;
          saveTokens(accessToken, refreshToken, idToken);
          dispatch(userLoginAPI());
          getPublicKey();
        }
      });
    } catch (error) {
      setLoading(false);
      if (error?.name === Strings.LOGIN_ERR_NAME) {
        setLoginError(Strings.INVALID_QPA_LOGIN);
      } else if (error?.message === Strings.LOGIN_ERR_MSG1) {
        setLoginError(Strings.INCORRECT_USERNAME_PASS);
      } else if (error?.message === Strings.LOGIN_ERR_MSG2) {
        setLoginError(Strings.USER_ACCOUNT_DEACTIVATED);
      } else {
        setLoginError(error?.message);
      }
    }
  }

  const onPressEnter = (event) => {
    if (event?.key === "Enter") {
      handleOnSubmit(event);
    }
  };

  const onIconClickHandler = (origin) => {
    setPwdVisibility((prev) => ({
      ...prev,
      [origin]: !prev?.[origin],
    }));
  };

  const handleEmailChange = (e) => {
    const event = {
      target: {
        name: e?.target?.name,
        value: e?.target?.value?.trim(),
      },
    };
    handleOnChange(event);
  };

  return loader ? (
    <Loader />
  ) : (
    <div className="page">
      <div className="login-page-wrapper">
        <div className="login-graphics">
          {props?.extraData?.loginImagePath ? (
            <Image url={props?.extraData?.loginImagePath} width="320px" />
          ) : (
            <LoginPageImage />
          )}
          {props?.extraData?.loginImagePath ? null : (
            <p className="copyrights">
              <span>EduTest &copy; 2022</span> <span>V 1.2</span>
            </p>
          )}
        </div>
        <div className="login-wrapper">
          <div>
            <div className="login-header">
              <IconEduTest width="94" height="91" />
              <h5 className="edutest-login mt-1">EDUTEST</h5>
              <h1 className="branding ">ET-CBT</h1>
              <span className="brading-tag">CORE</span>
            </div>
            <div>
              {forgotPasswordMode?.mode !== Strings.MODE_FORGOT_PWD &&
              forgotPasswordMode?.mode !== Strings.MODE_NEW_PWD ? (
                <h2 className="user-login ">{Strings.LOGIN_HEADING}</h2>
              ) : null}
              {forgotPasswordMode?.mode === Strings.MODE_NEW_PWD ? (
                <div className={"reset-pwd-header-section"}>
                  <h2>{Strings.RESET_PWD_HEADING}</h2>
                </div>
              ) : null}
              <FormGroup className="login-fields">
                <FieldGroup colSpan={12}>
                  {forgotPasswordMode?.mode !== Strings.MODE_FORGOT_PWD &&
                  forgotPasswordMode?.mode !== Strings.MODE_NEW_PWD ? (
                    <>
                      <InputField
                        type={"text"}
                        fieldName={"email"}
                        placeholder={Strings.FIELD_PLACEHOLDER_EMAIL}
                        data={values?.email}
                        hasError={errors?.email && dirty?.email}
                        errorMessage={errors?.email}
                        onChangeHandler={(e) => handleEmailChange(e)}
                        onEnterHandler={handleOnSubmit}
                        required={true}
                      />
                      <div className="has-icon mt-2">
                        <InputField
                          type={pwdVisibility.password ? "password" : "text"}
                          iconClasses={"eye-icon-view"}
                          fieldName={"password"}
                          data={values?.password}
                          placeholder={Strings.FIELD_PLACEHOLDER_PWD}
                          hasError={errors?.password && dirty?.password}
                          errorMessage={errors?.password}
                          onChangeHandler={handleOnChange}
                          onEnterHandler={handleOnSubmit}
                          onKeyPress={onPressEnter}
                          hasIcon
                          icon={
                            pwdVisibility.password ? (
                              <EyeCloseIcon />
                            ) : (
                              <EyeOpenIcon />
                            )
                          }
                          iconClickHandler={() =>
                            onIconClickHandler("password")
                          }
                          isSearch={false}
                          required={true}
                          disableAutoFocus={true}
                        />
                      </div>
                      <MesssagToaster
                        IconLabel="error"
                        showToaster={loginError}
                        title={loginError}
                        className="login-msg-toaster"
                        changePassword={true}
                      />
                    </>
                  ) : null}

                  {forgotPasswordMode?.mode === Strings.MODE_NEW_PWD ? (
                    <>
                      {forgotPasswordMode?.code ? (
                        <InputField
                          type={"text"}
                          fieldName={"confirmationCode"}
                          placeholder={
                            Strings.FIELD_PLACEHOLDER_CONFIRAMTION_CODE
                          }
                          data={values?.confirmationCode}
                          hasError={
                            errors?.confirmationCode && dirty?.confirmationCode
                          }
                          errorMessage={
                            errors?.confirmationCode && dirty?.confirmationCode
                          }
                          onChangeHandler={handleOnChange}
                          onEnterHandler={handleOnSubmit}
                          hasIcon
                          iconEye={pwdVisibility.newPassword ? false : true}
                          iconEyeSlash={
                            pwdVisibility.newPassword ? true : false
                          }
                          onIconClickHandler={onIconClickHandler}
                          classes={"new-password-fields-width"}
                        />
                      ) : null}
                      <InputField
                        type={pwdVisibility.newPassword ? "password" : "text"}
                        fieldName={"newPassword"}
                        placeholder={Strings.FIELD_PLACEHOLDER_NEW_PWD}
                        iconClasses={"eye-icon-view"}
                        data={values?.newPassword}
                        hasError={errors?.newPassword && dirty?.newPassword}
                        errorMessage={
                          errors?.newPassword && dirty?.newPasswordd
                        }
                        onChangeHandler={handleOnChange}
                        onEnterHandler={handleOnSubmit}
                        hasIcon
                        icon={
                          pwdVisibility.newPassword ? (
                            <EyeCloseIcon />
                          ) : (
                            <EyeOpenIcon />
                          )
                        }
                        iconClickHandler={() =>
                          onIconClickHandler("newPassword")
                        }
                        classes={"new-password-fields-width"}
                      />

                      <PasswordStrengthBar
                        className={"password-strenth-bar-padding"}
                        password={values?.newPassword}
                        scoreWordClassName="password-strength-bar-score"
                      />

                      <InputField
                        type={
                          pwdVisibility.confirmPassword ? "password" : "text"
                        }
                        fieldName={"confirmPassword"}
                        placeholder={Strings.FIELD_PLACEHOLDER_CONFIRM_PWD}
                        iconClasses={"eye-icon-view"}
                        data={values?.confirmPassword}
                        hasError={
                          errors?.confirmPassword && dirty?.confirmPassword
                        }
                        errorMessage={
                          errors?.confirmPassword && dirty?.confirmPassword
                        }
                        onChangeHandler={handleOnChange}
                        onEnterHandler={handleOnSubmit}
                        hasIcon
                        icon={
                          pwdVisibility.confirmPassword ? (
                            <EyeCloseIcon />
                          ) : (
                            <EyeOpenIcon />
                          )
                        }
                        iconClickHandler={() =>
                          onIconClickHandler("confirmPassword")
                        }
                        classes={"new-password-fields-width"}
                      />

                      <MesssagToaster
                        IconLabel="error"
                        showIcon
                        showToaster={
                          confirmPasswordMatched ===
                          PASSWORD_STATE.PASSWORD_NOT_MATCH
                            ? true
                            : false
                        }
                        title={Strings.CONFIRMATION_MODE_PWD_MATCH}
                      />
                    </>
                  ) : null}

                  {forgotPasswordMode?.mode === Strings.MODE_FORGOT_PWD ? (
                    <>
                      <h2>{Strings.FORGOT_PWD_HEADING}</h2>
                      <div className="reset-pwd-info">
                        <p>{Strings.FORGOT_PWD_DESCRIPTION}</p>
                      </div>
                      <br />
                      <InputField
                        type={"text"}
                        fieldName={"forgotEmail"}
                        placeholder={Strings.FIELD_PLACEHOLDER_FORGOT_EMAIL}
                        data={values?.forgotEmail}
                        hasError={errors?.forgotEmail && dirty?.forgotEmail}
                        errorMessage={errors?.forgotEmail}
                        onChangeHandler={handleOnChange}
                      />
                    </>
                  ) : null}

                  <div className="login-btns">
                    {forgotPasswordMode?.mode === Strings.MODE_NEW_PWD &&
                    !forgotPasswordMode?.code ? (
                      <TextButton
                        innerRef={submitBtnRef}
                        title={
                          forgotPasswordMode?.mode === Strings.MODE_NEW_PWD
                            ? BtnTitles.SUBMIT
                            : BtnTitles.LOGIN
                        }
                        loadingTitle={Strings.LOGIN_LOADING_TITLE}
                        rounded={false}
                        loading={loading}
                        shouldDisable={
                          confirmPasswordMatched ===
                          PASSWORD_STATE.PASSWORD_MATCH
                            ? false
                            : true
                        }
                        clickHandler={handleOnSubmit}
                        className={"button-submit loading-btn"}
                      />
                    ) : null}

                    {forgotPasswordMode?.code ? (
                      <TextButton
                        title={BtnTitles.SUBMIT}
                        loadingTitle={Strings.SUBMIT_LOADING_TITLE}
                        rounded={false}
                        loading={loading}
                        shouldDisable={
                          confirmPasswordMatched ===
                            PASSWORD_STATE.PASSWORD_MATCH &&
                          values?.confirmationCode?.length > 0
                            ? false
                            : true
                        }
                        clickHandler={handleForgotPasswordWithCodeSubmit}
                      />
                    ) : null}

                    {forgotPasswordMode?.mode !== Strings.MODE_NEW_PWD ? (
                      <TextButton
                        innerRef={submitBtnRef}
                        title={
                          forgotPasswordMode?.mode === Strings.MODE_FORGOT_PWD
                            ? BtnTitles.SUBMIT
                            : BtnTitles.LOGIN
                        }
                        loadingTitle={Strings.LOGIN_LOADING_TITLE}
                        rounded={false}
                        loading={loading}
                        shouldDisable={loading}
                        className={"button-submit loading-btn"}
                        clickHandler={
                          forgotPasswordMode?.mode === Strings.MODE_FORGOT_PWD
                            ? handleForgotPasswordChange
                            : handleOnSubmit
                        }
                      />
                    ) : null}

                    {forgotPasswordMode?.mode !== Strings.MODE_NEW_PWD ? (
                      <button
                        className="btn password-forgot "
                        onClick={forgotPasswordHandler}
                      >
                        {forgotPasswordMode?.mode === Strings.MODE_FORGOT_PWD
                          ? BtnTitles.BACK
                          : BtnTitles.FORGOT_PWD}
                      </button>
                    ) : null}

                    {forgotPasswordMode?.code ? (
                      <button
                        className="btn password-forgot "
                        onClick={resetPasswordBackButtonHandler}
                      >
                        {BtnTitles.BACK}
                      </button>
                    ) : null}
                  </div>
                </FieldGroup>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
