import { useState, useEffect, useCallback } from "react";
import {
  get_prop_values,
  is_object,
  is_required,
  convertBase64,
} from "utils/utility";

/**
 * Custom hooks to validate your Form...
 *
 * @param {object} stateSchema model you stateSchema.
 * @param {object} stateValidatorSchema model your validation.
 * @param {function} submitFormCallback function to be execute during form submission.
 */
export const useForm = (
  stateSchema = {},
  submitFormCallback,
  specificValidationFields
) => {
  const [state, setStateSchema] = useState({ ...stateSchema });
  const [values, setValues] = useState(get_prop_values(state, "value"));
  const [errors, setErrors] = useState(get_prop_values(state, "error"));
  const [dirty, setDirty] = useState(get_prop_values(state));
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const REQUIRED_FIELD_ERROR = "This is required field";

  // Get a local copy of stateSchema
  useEffect(() => {
    setStateSchema(stateSchema);
    setDisable(true); // Disable button in initial render.
    setInitialErrorState();
  }, []); // eslint-disable-line

  const updateSchema = useCallback((schema) => {
    setStateSchema(schema);
  });

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateErrorState());
    }
  }, [errors, isDirty]); // eslint-disable-line

  const setRequired = (fieldName) => {
    if (!stateSchema[fieldName]) return;

    let field = stateSchema[fieldName];
    field.required = true;
  };

  const setValidator = (fieldName, validatorFunction) => {
    if (!stateSchema[fieldName]) return;

    let field = stateSchema[fieldName];
    field.validator = validatorFunction;
  };

  // Validate fields in forms
  const validateFormFields = useCallback(
    (name, value) => {
      // const validator = stateValidatorSchema;
      // Making sure that stateValidatorSchema name is same in
      // stateSchema

      if (!stateSchema[name]) return;

      const field = stateSchema[name];

      let error = "";
      error = is_required(value, field.required, REQUIRED_FIELD_ERROR);

      if (is_object(field["validator"]) && error === "") {
        if (value && value.length > 0) {
          const fieldValidator = field["validator"];
          // Test the function callback if the value is meet the criteria
          const testFunc = fieldValidator["func"];
          if (testFunc) {
            if (!testFunc(value, values)) {
              error = fieldValidator["error"];
            }
          } else {
            error = fieldValidator["error"];
          }
        }
      }

      return error;
    },
    [state, values]
  );

  // Validate microsite fields in forms
  const validateMicroSiteFormFields = (name, value) => {
    if (!stateSchema[name]) return;
    const field = stateSchema[name];
    let error = "";
    const isFieldToConsider = (name) => {
      if (specificValidationFields.length === 0) return true;
      else if (
        specificValidationFields.length > 0 &&
        specificValidationFields.includes(name)
      )
        return true;
      else return false;
    };
    if (isFieldToConsider(name)) {
      error = is_required(value, field.required, REQUIRED_FIELD_ERROR);
      if (is_object(field["validator"]) && error === "") {
        if (value && value.length > 0) {
          const fieldValidator = field["validator"];
          // Test the function callback if the value is meet the criteria
          const testFunc = fieldValidator["func"];
          if (testFunc) {
            if (field.type === "TELEPHONE_WITH_EXT") {
              if (Array.isArray(value)) {
                const newVal = value.join("");
                if (!testFunc(newVal, values)) {
                  error = fieldValidator["error"];
                }
              } else if (!testFunc(value, values)) {
                error = fieldValidator["error"];
              }
            } else {
              if (!testFunc(value, values)) {
                error = fieldValidator["error"];
              }
            }
          } else {
            error = fieldValidator["error"];
          }
        }
      }
    }
    return error;
  };

  // Set Initial Error State
  // When hooks was first rendered...
  const setInitialErrorState = useCallback(() => {
    Object.keys(errors).forEach((name) => {
      errors[name] = validateFormFields(name, values[name]);
      setErrors((prevState) => ({
        ...prevState,
        [name]: validateFormFields(name, values[name]),
      }));
    });
  }, [errors, values, validateFormFields]);

  const setInitialErrorsForForm = useCallback(
    (initialState) => {
      Object.keys(initialState).forEach((name) =>
        setErrors((prevState) => ({
          ...prevState,
          [name]: validateFormFields(name, values[name]),
        }))
      );
    },
    [errors, values, validateFormFields]
  );

  // Used to disable submit button if there's a value in errors
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component

  const validateErrorState = useCallback(
    () => Object.values(errors).some((error) => error),
    [errors]
  );

  // Event handler for handling changes in input.
  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);

      const name = event.target.name;
      const value = event.target.value;

      const error = validateFormFields(name, value);
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: error !== "" }));
    },
    [validateFormFields]
  );

  // Event handler for handling changes in input.
  // pass allowEmptyVal to allow selection of default values
  const handleOnSelection = useCallback(
    (event, allowEmptyVal = false) => {
      setIsDirty(true);

      const name = event.target.name;
      let value = event.target.value;
      if (value || allowEmptyVal) {
        const error = validateFormFields(name, value);
        setValues((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const handleOnFileUpload = useCallback(
    (event) => {
      setIsDirty(true);

      let stateName = "file";
      let stateValue = event;
      let error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({ ...prevState, [stateName]: stateValue }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));

      let sName = "documents";
      let sValue = event;
      let sError = validateFormFields(sName, sValue);

      setValues((prevState) => ({ ...prevState, [sName]: sValue }));
      setErrors((prevState) => ({ ...prevState, [sName]: sError }));
      setDirty((prevState) => ({ ...prevState, [sName]: true }));
    },
    [validateFormFields]
  );

  const handleOnDocumentUpload = useCallback(
    (file, content, name) => {
      setIsDirty(true);
      let fileData = {};
      if (content) {
        fileData.name = file.name ? file.name : "";
        fileData.lastModified = file.lastModified ? file.lastModified : "";
        fileData.size = file.size ? file.size : "";
        fileData.type = file.type ? file.type : "";
        fileData.lastModifiedDate = file.lastModifiedDate
          ? file.lastModifiedDate
          : "";

        if (!fileData.content) {
          fileData.content = content;
        }
      }

      let stateName = name;
      let stateValue = content ? fileData : file;
      let error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({ ...prevState, [stateName]: stateValue }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));
    },
    [validateFormFields]
  );

  const handleOnClearDocumentUpload = useCallback(
    (name) => {
      setIsDirty(true);

      let stateName = name;
      let stateValue = "";
      let error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({ ...prevState, [stateName]: stateValue }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));

      stateName = "Content-" + name;
      error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({ ...prevState, [stateName]: stateValue }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));
    },
    [validateFormFields]
  );

  const handleOnImageUpload = useCallback(
    (event) => {
      setIsDirty(true);

      const stateName =
        "file-" + event.target.name
          ? "file"
          : event.target.closest("input").name;

      const stateValue = event.target ? event.target.value : event;
      const error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({ ...prevState, [stateName]: stateValue }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));

      if (!error) {
        const name = event.target ? event.target.name : "logo";
        const file = event.target ? event.target.files[0] : event;

        convertBase64(file).then((data) => {
          setValues((prevState) => ({
            ...prevState,
            [name]: data,
          }));
          setErrors((prevState) => ({ ...prevState, [name]: "" }));
          setDirty((prevState) => ({ ...prevState, [name]: true }));
        });
      }
    },
    [validateFormFields]
  );

  const handleOnClearImageUpload = useCallback((name, value) => {
    setIsDirty(false);
    setValues((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: value }));
    setDirty((prevState) => ({ ...prevState, [name]: true }));

    const fileData = "file";

    setValues((prevState) => ({ ...prevState, [fileData]: value }));
    setErrors((prevState) => ({ ...prevState, [fileData]: value }));
    setDirty((prevState) => ({ ...prevState, [fileData]: true }));
  }, []);

  const handleOnSegment = useCallback((e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const error = validateFormFields(name, value);
    if (value) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnRadioChange = useCallback((e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.id;

    const error = validateFormFields(name, value);
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnMultipleSelection = useCallback(
    (name, selectedValues) => {
      setIsDirty(true);
      if (selectedValues) {
        const error = validateFormFields(name, selectedValues);
        setValues((prevState) => ({ ...prevState, [name]: selectedValues }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const setErrorsAndDirty = (keysToSet, errorOrReset, errorMessages = []) => {
    let errorObjToSet, dirtyObjectToSet;
    keysToSet.forEach((key, index) => {
      errorObjToSet = {
        ...errorObjToSet,
        [key]: errorOrReset === "error" ? errorMessages[index] : "",
      };
      dirtyObjectToSet = {
        ...dirtyObjectToSet,
        [key]: errorOrReset === "error",
      };
    });

    setErrors((prevState) => ({ ...prevState, ...errorObjToSet }));
    setDirty((prevState) => ({ ...prevState, ...dirtyObjectToSet }));
  };

  const handleOnCheckboxChange = useCallback(
    (e, selection, isDirect = false, directKey = "") => {
      if (isDirect) {
        setValues((prevState) => ({
          ...prevState,
          [directKey]: selection,
        }));
      } else {
        const name = e?.currentTarget?.name;
        const value = e?.currentTarget?.id;
        let checkedValues = [];
        if (selection) {
          if (Array.isArray(selection)) checkedValues = [...selection];
          else if (selection.length > 0) checkedValues = [selection];
        }

        if (checkedValues.includes(value)) {
          const index = checkedValues.indexOf(value);
          if (index > -1) {
            checkedValues.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          checkedValues = [...checkedValues, value];
        }

        const error = validateFormFields(name, value);
        if (value !== null || value !== undefined) {
          setValues((prevState) => ({
            ...prevState,
            [name]: checkedValues,
          }));
          setErrors((prevState) => ({ ...prevState, [name]: error }));
          setDirty((prevState) => ({ ...prevState, [name]: true }));
        }
      }
    }
  );

  const handleOnTelephoneExtChange = useCallback((e, phoneValue) => {
    setDirty(false);

    const name = e.currentTarget.name;

    const error = validateFormFields(name, phoneValue.join(""));
    if (phoneValue !== null || phoneValue !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: phoneValue }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnDateChange = useCallback((value, name) => {
    handleDateTimeChange(value, name);
  });

  const handleOnTimeChange = useCallback((value, name) => {
    handleDateTimeChange(value, name);
  });

  const handleDateTimeChange = (value, name) => {
    const error = validateFormFields(name, value);
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  };

  const handleOnMultiselectChange = useCallback((value, name) => {
    setDirty(false);

    const error = validateFormFields(name, value);
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnValueChange = useCallback((value, name) => {
    setDirty(false);

    const error = validateFormFields(name, value);
    if (!value) value = "";
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnClear = useCallback((object) => {
    setValues((prevState) => ({
      ...prevState,
      ...object,
    }));
  });

  const handleOnSwitch = useCallback(
    (e) => {
      setIsDirty(true);

      const name = e.currentTarget.name;
      const value = e.currentTarget.checked ? true : false;

      const error = validateFormFields(name, value);
      if (value !== null || value !== undefined) {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const handleOnEditorChange = useCallback((e, id) => {
    setDirty(false);

    const name = id;
    const value = e;

    const error = "validateFormFields(name, value)";
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  });

  const handleOnSubmit = useCallback(
    (event) => {
      event?.preventDefault();

      // Making sure that there's no error in the state
      // before calling the submit callback function
      setInitialErrorState();
      if (!validateErrorState()) {
        submitFormCallback(values, null);
      } else {
        Object.keys(errors).forEach((name) => {
          if (errors[name] && errors[name].length > 0) {
            setDirty((prevState) => ({
              ...prevState,
              [name]: true,
            }));
          }
        });
        submitFormCallback(null, errors);
      }
    },
    [validateErrorState, submitFormCallback, values, errors]
  );

  const clearStateSchema = () => {
    Object.keys(values).forEach((name) =>
      setValues((prevState) => ({
        ...prevState,
        [name]: "",
      }))
    );
    Object.keys(errors).forEach((name) =>
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }))
    );
    Object.keys(dirty).forEach((name) =>
      setDirty((prevState) => ({
        ...prevState,
        [name]: false,
      }))
    );
    setInitialErrorState();
  };

  const handleNewSchema = (newSchema) => {
    setStateSchema(newSchema);
    setValues(get_prop_values(newSchema, "value"));
    setErrors(get_prop_values(newSchema, "error"));
    setDirty(get_prop_values(newSchema));
  };

  // to clear the form fields and set error states
  const handleOnDataClear = (name, value = "", stateVal = true) => {
    const error = validateFormFields(name, value);
    setValues((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
    setDirty((prevState) => ({ ...prevState, [name]: stateVal }));
  };

  const handleDataClearOnCategoryChange = (nameArray) => {
    nameArray.forEach((name, i) => {
      const error = validateFormFields(name, "");
      setValues((prevState) => ({ ...prevState, [name]: "" }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: false }));
    });
  };

  const handleCandidateEligibilitySubmit = useCallback(
    (event) => {
      event.preventDefault();
      submitFormCallback(values, errors);
    },
    [submitFormCallback, values, errors]
  );

  const setNewErrorAndDirty = (stateName, errorMessage) => {
    if (errorMessage) {
      setErrors((prevState) => ({ ...prevState, [stateName]: errorMessage }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));
    } else {
      setErrors((prevState) => ({ ...prevState, [stateName]: "" }));
      setDirty((prevState) => ({ ...prevState, [stateName]: false }));
    }
  };

  const handleSubmitForm = useCallback(
    (event) => {
      setStateSchema(event);
      // Making sure that there's no error in the state
      // before calling the submit callback function
      setInitialErrorsForForm(event);

      if (!validateErrorState()) {
        submitFormCallback(values, null);
      } else {
        Object.keys(errors).forEach((name) => {
          if (errors[name] && errors[name].length > 0) {
            setDirty((prevState) => ({
              ...prevState,
              [name]: true,
            }));
          }
        });
        submitFormCallback(null, errors);
      }
    },
    [validateErrorState, submitFormCallback, values, errors]
  );

  const handleMicroSiteFormSubmit = (event) => {
    setStateSchema(event);
    const initialErrState = (event) => {
      let err = {};
      Object.keys(event).forEach((name) => {
        err[name] = validateMicroSiteFormFields(name, values[name]);
        setErrors((prevState) => ({
          ...prevState,
          [name]: err[name],
        }));
      });
      return err;
    };
    const _errListObj = initialErrState(event);
    const validError = (_errListObj) => {
      return () => Object.values(_errListObj).some((error) => error);
    };
    const CheckError = validError(_errListObj);
    if (!CheckError()) {
      submitFormCallback(values, null);
    } else {
      setErrors(_errListObj);
      Object.keys(_errListObj).forEach((name) => {
        if (_errListObj[name] && _errListObj[name].length > 0) {
          setDirty((prevState) => ({
            ...prevState,
            [name]: true,
          }));
        }
      });
      submitFormCallback(null, _errListObj);
    }
  };

  return {
    handleOnChange,
    handleOnSelection,
    handleOnMultipleSelection,
    handleOnSubmit,
    clearStateSchema,
    handleOnFileUpload,
    handleOnImageUpload,
    handleOnClearImageUpload,
    handleOnSegment,
    handleOnSwitch,
    handleOnRadioChange,
    handleOnCheckboxChange,
    handleOnTelephoneExtChange,
    handleOnDateChange,
    handleOnTimeChange,
    handleOnMultiselectChange,
    handleOnDocumentUpload,
    handleOnClearDocumentUpload,
    handleOnValueChange,
    handleOnEditorChange,
    handleOnClear,
    setRequired,
    setValidator,
    values,
    errors,
    disable,
    setValues,
    setErrors,
    setDirty,
    dirty,
    handleOnDataClear,
    handleDataClearOnCategoryChange,
    handleCandidateEligibilitySubmit,
    handleNewSchema,
    setNewErrorAndDirty,
    handleSubmitForm,
    handleMicroSiteFormSubmit,
    updateSchema,
    validateErrorState,
    setErrorsAndDirty,
  };
};
