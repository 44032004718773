import { EyeCloseIcon, IconEye } from "assets/svgs/Svgs";
import InputField from "components/widgets/input-field/InputField";
import Modal from "components/widgets/modal/Modal";
import TextButton from "components/widgets/text-Button/TextButton";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "utils/customeHooks/UseForm";
import "./CreateUpdatePin.css";
import MesssagToaster from "components/widgets/msg-toaster/MessageToaster";
import { regex } from "utils/constants/commonRegex";
import { useDispatch } from "react-redux";
import { createPin, fetchPublicKey, updatePin } from "redux/login/login-slice";
import { notifyError, notifySuccess } from "utils/utility";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchPinSetData } from "redux/profile/profile-slice";
import { BtnTitles, Strings } from "utils/constants/constants";

const CreateUpdatePin = ({ show, onClose, edit = false, mode }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [toggleVisibility, setToggleVisibility] = useState({
    showCurrentPin: true,
    showPin: true,
    showConfirmPin: true,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const submitBtnRef = useRef(null);
  const stateObj = {
    pin: "",
    currentPin: "",
    confirmPin: "",
  };

  const addSecretKeyState = {
    pin: {
      value: "",
      error: "",
      required: true,
      validator: {
        func: (value) => regex.PIN_NUMBER.test(value),
        error: "Please enter a 6 digit pin",
      },
    },
    confirmPin: {
      value: "",
      error: "",
      required: true,
    },
  };

  useEffect(() => {
    const listener = (event) => {
      if (event?.code === "Enter" || event?.code === "NumpadEnter") {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    };
    document?.addEventListener("keydown", listener);
    return () => {
      document?.removeEventListener("keydown", listener);
    };
  }, []);

  const changeSecretKeyState = {
    currentPin: {
      value: "",
      error: "",
      required: true,
      validator: {
        func: (value) => regex.PIN_NUMBER.test(value),
        error: "Please enter a 6 digit pin",
      },
    },
    ...addSecretKeyState,
  };

  const onSubmit = (state, error) => {
    if (!error) {
      if (values?.pin !== values.confirmPin) {
        return;
      }
      if (mode === "add" || showConfirm) {
        const api =
          mode === "edit"
            ? updatePin({
                currentPin: values?.currentPin,
                newPin: values?.pin,
              })
            : createPin({ pin: values?.pin });
        dispatch(api).then((res) => {
          if (
            res?.payload?.statusCode === 201 ||
            res?.payload?.statusCode === 200
          ) {
            notifySuccess(res?.payload?.message);
            dispatch(fetchPinSetData());
            if (mode === "add") {
              getPublicKey();
            }
            onClose();
          } else {
            notifyError(res?.payload?.data?.message);
          }
        });
        setShowConfirm(false);
      } else {
        if (mode === "edit") setShowConfirm(true);
      }
    }
  };

  const {
    values,
    errors,
    setErrors,
    setValues,
    clearStateSchema,
    handleOnChange,
    handleOnSubmit,
  } = useForm(
    mode === "edit" ? changeSecretKeyState : addSecretKeyState,
    onSubmit
  );

  useEffect(() => {
    if (show) {
      clearStateSchema();
    }
    setValues((prev) => ({
      ...prev,
      ...stateObj,
    }));
    setErrors((prev) => ({
      ...prev,
      ...stateObj,
    }));
  }, [show]);

  const getPublicKey = () => {
    dispatch(fetchPublicKey()).then((res) => {
      if (res?.payload?.data?.publicKey) {
        history.push(ROUTES_CONSTANTS.myProfile);
      } else {
        notifyError(res?.payload?.data?.message);
      }
    });
  };

  const handleVisibility = (name) => {
    setToggleVisibility({
      ...toggleVisibility,
      [name]: !toggleVisibility[name],
    });
  };

  return (
    <Modal show={show}>
      {mode === "edit" ? (
        <div className="my-1">
          <InputField
            fieldName="currentPin"
            data={values.currentPin}
            onChangeHandler={handleOnChange}
            type={toggleVisibility.showCurrentPin ? "password" : "text"}
            title={Strings.FIELD_TITLE_CURRENT_PIN}
            required={true}
            icon={
              toggleVisibility.showCurrentPin ? <EyeCloseIcon /> : <IconEye />
            }
            iconClickHandler={() => handleVisibility("showCurrentPin")}
            className="verify_key_input"
            hasError={errors?.currentPin}
            errorMessage={errors?.currentPin}
          />
        </div>
      ) : null}
      <div className={mode === "edit" ? "my-3" : "my-1"}>
        <InputField
          fieldName="pin"
          data={values.pin}
          onChangeHandler={handleOnChange}
          type={toggleVisibility.showPin ? "password" : "text"}
          title={
            edit ? Strings.FIELD_TITLE_NEW_PIN : Strings.FIELD_TITLE_ENTER_PIN
          }
          required={true}
          icon={toggleVisibility.showPin ? <EyeCloseIcon /> : <IconEye />}
          iconClickHandler={() => handleVisibility("showPin")}
          className="verify_key_input"
          hasError={errors?.pin}
          errorMessage={errors?.pin}
          disableAutoFocus={mode === "edit"}
        />
      </div>
      <div className="my-3">
        <InputField
          fieldName="confirmPin"
          data={values.confirmPin}
          onChangeHandler={handleOnChange}
          type={toggleVisibility.showConfirmPin ? "password" : "text"}
          title={Strings.FIELD_TITLE_RE_ENTER_PIN}
          required={true}
          icon={
            toggleVisibility.showConfirmPin ? <EyeCloseIcon /> : <IconEye />
          }
          iconClickHandler={() => handleVisibility("showConfirmPin")}
          className="verify_key_input"
          hasError={errors?.confirmPin}
          errorMessage={errors?.confirmPin}
          disableAutoFocus={true}
        />
      </div>
      <MesssagToaster
        IconLabel="error"
        showIcon
        showToaster={
          values?.pin && values.confirmPin && values?.pin !== values.confirmPin
        }
        title={edit ? "Pin doesn't match" : "Incorrect Pin"}
      />
      <ConfirmationModal
        show={showConfirm}
        title={Strings.PIN_CHANGE_CONFIRMATION}
        onCancel={() => {
          setShowConfirm(false);
        }}
        confirmHandler={() => {
          handleOnSubmit();
        }}
        btnText={"Save"}
      />
      <div className="pin-action-btn">
        {mode === "edit" ? (
          <TextButton
            title={BtnTitles.CANCEL}
            isPrimary={false}
            rounded={false}
            clickHandler={() => onClose()}
          />
        ) : null}
        <TextButton
          title={BtnTitles.SAVE}
          innerRef={submitBtnRef}
          rounded={false}
          clickHandler={handleOnSubmit}
        />
      </div>
    </Modal>
  );
};

export default CreateUpdatePin;
