export const manageQuestionActions = {
  FEATURE_KEY: "manage-questions",
  MANAGE_QUESTION_FLYOUT_MODE: "manage-question/flyout/mode",
  FETCH_QUESTION: "question/fetch",
  DELETE_QUESTION: "question/delete",
  DELETE_MANY_QUESTIONS: "question/delete/many",
  PREVIEW_QUESTION: "question/preview",
  FETCH_QUESTION_LIST: "question/list/fetch",
  FETCH_QUESTION_DETAIL_INSTANCE: "question/detail/fetch",
  UPDATE_QUESTION_STATUS: "question/status/update",
  FETCH_REQUEST_LISTING: "fetch/groupwise/listing",
  FETCH_AUTHORS_LISTING: "fetch/authors/listing",
  SET_SELECTED_ID: "set/selected/id",
  SET_SELECTED_ACTIONS: "set/selected/actions",
  VERIFY_SECRET_KEY: "verify/secret/key",
  IMPORT_QUESTION: "import/question",
  SELECTED_QUESTION: "set/selected/question",
  SET_CHECK_BOX_STATE: "set/check/box/state",
};
