export const loginInitialState = {
  email: {
    value: "",
    error: "",
    required: true,
    validator: {
      func: (value) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ),
      error: "Invalid email",
    },
  },
  forgotEmail: {
    value: "",
    error: "",
    validator: {
      func: (value) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ),
      error: "Invalid email",
    },
  },
  password: {
    value: "",
    error: "",
    required: true,
  },
  newPassword: {
    value: "",
    error: "",
  },
  confirmationCode: {
    value: "",
    error: "",
  },
  confirmPassword: {
    value: "",
    error: "",
  },
  forgotPassword: {
    value: "",
    error: "",
  },
};
