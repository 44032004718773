import "./loader.css";
import Image from "../image/Image";
import { IconEduTest } from "assets/svgs/Svgs";
import { END_POINTS_STATICS } from "utils/constants/common";

export function Loader(props) {
  return (
    <div className={`appLoader ${props?.className}`}>
      <div>
        {props?.isImage ? (
          <Image
            alt=""
            url={props.url}
            height={props?.height ?? "100px"}
            width={props?.width ?? "100px"}
            applicationName={
              props?.applicationName
                ? props.applicationName
                : END_POINTS_STATICS.ADMIN_KEY
            }
            style={{ objectFit: "fill" }}
          />
        ) : (
          <IconEduTest className="loader-class" height="100px" width="100px" />
        )}
      </div>
      <span className="loader-text">
        {props?.textForLoader ? props?.textForLoader : "Please wait..."}
      </span>
    </div>
  );
}
export default Loader;
