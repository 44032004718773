import { useEffect, useRef, useState } from "react";
import "./AddQuestion.css";
import _ from "lodash";
import { NavBar } from "components/laylouts/nav-bar/NavBar";
import { useForm } from "utils/customeHooks/UseForm";
import {
  convertData,
  convertListToOptions,
  getStorageItem,
  handleError,
  notifyError,
  setSelectedQuestionValues,
} from "utils/utility";
import { questionState } from "./questionState";
import AddSecretKey from "components/laylouts/manage-secret-key/add-secret-key/AddSecretKey";
import AddEditPassage from "./add-edit-passage/AddEditPassage";
import QuestionAnswerPreviewModal from "components/laylouts/question-answer-preview-modal/QuestionAnswerPreviewModal";
import ViewPassage from "./view-passage/ViewPassage";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuestionDetail,
  getSelectedQuestion,
} from "redux/manage-question/manage-question-slice";
import { getUserDetails } from "redux/login/login-slice";
import {
  getPassageDetail,
  getPassageFlyoutMode,
  getPassageList,
  passageDetail,
  setPassageFlyoutMode,
  setSelectedPassage,
  setSelectedPassageId,
  getConfirmationMode,
  setConfirmationMode,
} from "redux/passage/passage-slice";
import {
  fetchAllMastersList,
  fetchLanguages,
  getAddQuestionState,
  getQuestionLoader,
  setSelectedLanguage,
} from "redux/add-question/add-question-slice";
import Loader from "components/widgets/loader/Loader";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { reqParams } from "views/manage-questions/question-initial-state";
import { globalInstanceData } from "redux/profile/profile-slice";
import AddQuestionFooter from "./add-question-footer/AddQuestionFooter";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import { AddQuestionContainer } from "./add-question-container/AddQuestionContainer";
import {
  checkAllObjectValues,
  convertTranslationsToData,
  formatRequestData,
  onChangeQuestionEditor,
  onChangeTranslatorEditor,
  onClickViewHandlerUtil,
  onSubmitLangTranslator,
  onSubmitNewQuestion,
  onlineFetchPassage,
  resetValues,
  setAnswerOptionsValues,
} from "./add-question-utility/AddQuestionUtility";
import { CONFIRMATION_MODES, successStatusCodes } from "utils/constants/common";
import AppConstants from "utils/constants/app-constants";
import { nanoid } from "nanoid";
import { BtnTitles, Strings } from "utils/constants/constants";

const AddQuestion = () => {
  const [loader, setLoader] = useState(false);
  const [optionCount, setOptionCount] = useState(4);
  const [addSecretKeyModal, setAddSecretKeyModal] = useState(false);
  const [optionLabel, setOptionLabel] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ]);
  const [questionId, setQuestionId] = useState("");
  const [currectOption, setCurrectOption] = useState("Option 1");
  const [multiCurrectOption, setMultiCurrectOption] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(null);
  const [subjectWiseCategoryList, setSubjectWiseCategoryList] = useState([]);
  const [subjectWiseCategoryOptions, setSubjectWiseCategoryOptions] = useState(
    []
  );
  const [previewQuestionsData, setPreviewQuestionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languageTranslations, setLanguageTranslations] = useState([]);
  const [selectedLangArray, setSelectedLangArray] = useState([]);
  const [languageApiCalled, setLanguageApiCalled] = useState(false);
  const [mastersApiCalled, setMastersApiCalled] = useState(false);
  const [currentPassage, setCurrentPassage] = useState({});
  const [count, setCount] = useState(0);
  const [isLangTranslator, setIsLangTranslator] = useState(false);
  const [isLangReviewer] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [showConfirmClear, setShowConfirmClear] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [answerOptionNames, setAnswerOptionNames] = useState([]);
  const userData = useSelector(getUserDetails);
  const showPassageModal = useSelector(getPassageFlyoutMode);
  const addQuestionState = useSelector(getAddQuestionState);
  const selectedOnlineQuestion = useSelector(getSelectedQuestion);
  const selectedInstanceData = useSelector(globalInstanceData);
  const selectedPassage = useSelector(getPassageDetail);
  const loadingStatus = useSelector(getQuestionLoader);
  const confirmationMode = useSelector(getConfirmationMode);
  let passageList = useSelector(getPassageList);
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const questionSecureRequestId = getStorageItem(
    "questionSecureRequestId"
  );
  const {
    languageList = [],
    typesList = [],
    levelsList = [],
    subjectCategoriesList = [],
  } = addQuestionState;
  const selectedId = location?.state?.id;
  const manageQuestionFlyoutMode = location?.state?.mode;
  const selectedQuestion = selectedOnlineQuestion;
  const { roles } = userData;
  const { roleName } = roles;
  const languageOptions = convertListToOptions(languageList, "languageName");
  const subjectsOptions = convertListToOptions(
    subjectCategoriesList,
    "subjectName"
  );
  const typesOptions = convertListToOptions(typesList, "typeName");
  const levelsOptions = convertListToOptions(levelsList, "difficultyLevel");
  let passageOptions = convertListToOptions(passageList, "title");

  const getMasterAndLanguageList = () => {
    dispatch(fetchLanguages(reqParams)).then((res) => {
      setLanguageApiCalled(true);
      handleError(res, 201);
    });
    dispatch(fetchAllMastersList()).then((res) => {
      setMastersApiCalled(true);
      handleError(res, 200);
    });
  };

  const listener = (event) => {
    if (event?.code === "Enter" || event?.code === "NumpadEnter") {
      if (!showPassageModal) {
        event?.preventDefault();
        submitBtnRef?.current?.click();
      }
    }
  };

  const setInitialDataForEditMode = (question) => {
    if (manageQuestionFlyoutMode === "Edit") {
      setLoader(true);
    }
    if (question?.answerOptions?.length > 0) {
      setOptionsForSelectedQuestion(question);
      setMultiCurrectOption(
        question?.answerOptions?.map((option) => option?.correctValue)
      );
    }
    fetchPassage();
    const subjectsList = JSON.parse(JSON.stringify(subjectCategoriesList));
    const categoriesList =
      Array.isArray(subjectsList) && subjectsList?.length > 0
        ? subjectsList[
            subjectsList?.findIndex(
              (sub) => sub?.subjectCode === question?.subject
            )
          ]?.categories
        : [];
    setSubjectWiseCategoryList(categoriesList);
    setSubjectWiseCategoryOptions(
      convertListToOptions(categoriesList, "categoryName")
    );
    setTimeout(() => {
      setSelectedQuestionValues(
        setValues,
        question,
        subjectsList,
        categoriesList,
        levelsList,
        typesList,
        passageList,
        setLanguageTranslations,
        setSelectedLangArray
      );
    }, 1000);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const clearQuestionData = () => {
    if (showConfirmClear) {
      resetValues(setValues);
      setShowConfirmClear(false);
      setMultiCurrectOption([false]);
    } else {
      setShowConfirmClear(true);
    }
  };

  const onSubmit = () => {
    const errorsToCheck = {
      ...errors,
      file: "",
    };
    const allErrorsEmpty = checkAllObjectValues(errorsToCheck);
    if (isLangTranslator) {
      onSubmitLangTranslator(
        languageTranslations,
        values,
        selectedId,
        selectedInstanceData,
        history,
        dispatch,
        selectedQuestion,
        currentPassage
      );
    } else if (allErrorsEmpty) {
      onSubmitNewQuestion(
        multiCurrectOption,
        questionId,
        selectedInstanceData,
        values,
        subjectCategoriesList,
        subjectWiseCategoryList,
        levelsList,
        typesList,
        passageList,
        answerOptions,
        dispatch,
        history,
        manageQuestionFlyoutMode,
        selectedId,
        setLoading,
        currentPassage
      );
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    setValues,
    handleOnClearImageUpload,
    handleOnSelection,
    handleOnRadioChange,
  } = useForm(questionState, onSubmit);

  passageList = values?.language ? passageList : [];
  passageOptions = values?.language ? passageOptions : [];

  useEffect(() => {
    getMasterAndLanguageList();
    const ranodmId = nanoid();
    setQuestionId(ranodmId);
    if (location?.pathname === ROUTES_CONSTANTS.translateQuestion) {
      setIsLangTranslator(true);
    }
    document?.addEventListener("keydown", listener);
    return () => {
      document?.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const subjectsList = subjectCategoriesList;
    if (subjectsList?.length > 0 && languageApiCalled && mastersApiCalled) {
      if (manageQuestionFlyoutMode === "Edit") {
        setLoader(true);
        dispatch(
          fetchQuestionDetail({
            id: selectedId,
            data: { instanceId: selectedInstanceData?._id },
          })
        )
          .then((res) => {
            if (successStatusCodes.includes(res?.payload?.statusCode)) {
              const question = res?.payload?.data;
              setCurrentPassage(question?.groupPassage);
              setInitialDataForEditMode(question);
            } else {
              notifyError(res?.payload?.data?.message);
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    }
  }, [languageApiCalled, mastersApiCalled]);

  useEffect(() => {
    handleOnSelection({
      target: { name: "groupQuestion", value: selectedPassage?.title },
    });
  }, [passageList]);

  useEffect(() => {
    const passageId = convertData(
      passageList,
      "title",
      "_id",
      values?.groupQuestion
    );
    if (passageId) {
      dispatch(passageDetail({ passageId })).then((res) => {
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          setCurrentPassage(res?.payload?.data);
        } else {
          setCurrentPassage({});
        }
      });
    }
  }, [values?.groupQuestion]);

  useEffect(() => {
    if (!isLangTranslator) {
      setCount(count + 1);
      if (manageQuestionFlyoutMode !== "Edit" && count > 0) {
        setDataChanged(true);
      } else if (manageQuestionFlyoutMode === "Edit" && count > 2) {
        setDataChanged(true);
      }
    }
  }, [values]);

  useEffect(() => {
    if (isLangTranslator) {
      setCount(count + 1);
      if (count > 2) {
        setDataChanged(true);
      }
    }
  }, [languageTranslations]);

  useEffect(() => {
    setAnswerOptionsValues(values, multiCurrectOption, setAnswerOptions);
  }, [values?.answer, values?.optionFile, multiCurrectOption]);

  useEffect(() => {
    dispatch(setSelectedPassage(null));
    dispatch(setSelectedPassageId(""));
    fetchPassage();
    setValues((prev) => ({ ...prev, groupQuestion: "" }));
    setLoader(false);
  }, [values?.language]);

  useEffect(() => {
    if (values.subject) {
      let subjectWiseCategoryList = [];
      const subjectsList = subjectCategoriesList;
      subjectsList?.map((subject) => {
        if (subject?.subjectName === values.subject) {
          subjectWiseCategoryList = subject?.categories;
        }
      });
      setSubjectWiseCategoryList(subjectWiseCategoryList);
      setSubjectWiseCategoryOptions(
        convertListToOptions(subjectWiseCategoryList, "categoryName")
      );
    } else {
      setSubjectWiseCategoryList([]);
      setSubjectWiseCategoryOptions([]);
    }
  }, [values?.subject]);

  useEffect(() => {
    const optionNames = answerOptions?.map((item) => {
      const optionName = item?.optionName
        ? item?.optionName?.toString()?.replace(/<p>|<\/p>/g, "")
        : "";
      return optionName;
    });
    setAnswerOptionNames(optionNames);
  }, [answerOptions]);

  const fetchPassage = () => {
    if (
      Array.isArray(languageList) &&
      languageList?.length > 0 &&
      values?.language
    ) {
      dispatch(setSelectedLanguage(values?.language));
      onlineFetchPassage(dispatch, selectedQuestion, values, setValues);
    }
  };

  const onChangePreviewVisibility = (isPreview) => {
    setPreviewModalOpen(isPreview);
  };

  const openPreviewPopup = () => {
    if (!values?.type) {
      notifyError("Please select question type to preview the question");
      return;
    }
    if (!values?.questionDescription && !values?.questionImage) {
      notifyError("Please add question decsription or image to preview");
      return;
    }
    if (answerOptions?.length === 0) {
      notifyError("Please add at least one option to preview ");
      return;
    }
    let questionData = formatRequestData(
      questionId,
      selectedInstanceData,
      values,
      subjectCategoriesList,
      subjectWiseCategoryList,
      levelsList,
      typesList,
      passageList,
      answerOptions,
      currentPassage
    );
    if (isLangTranslator)
      questionData["languageTranslate"] = convertTranslationsToData(
        languageTranslations,
        "details"
      );
    setPreviewQuestionsData([questionData]);
    setPreviewModalOpen(true);
  };

  const tinyEditorHandler = (e, key, index = 0, languageIndex) => {
    if (isLangTranslator) {
      onChangeTranslatorEditor(
        key,
        languageTranslations,
        setLanguageTranslations,
        e,
        index,
        languageIndex
      );
    } else {
      onChangeQuestionEditor(key, e, values, setValues, index);
    }
    debouncedEditor(e);
  };

  const debouncedEditor = _.debounce(function (e) {}, 300);

  const addSecretKeyModalClose = () => {
    setAddSecretKeyModal(false);
  };

  const handleOnClearOptionImageUpload = (index, languageIndex, str = "") => {
    const emptyImage = {
      name: "",
      value: "",
    };
    if (isLangTranslator) {
      let clonedLanguageTranslations = _.cloneDeep(languageTranslations);
      if (str === "passage") {
        clonedLanguageTranslations[languageIndex]["passageDetails"]["image"] =
          emptyImage;
      } else if (str === "question") {
        clonedLanguageTranslations[languageIndex].details.questionImage =
          emptyImage;
      } else {
        clonedLanguageTranslations[languageIndex].details.answerOptions[
          index
        ].image = emptyImage;
      }
      setLanguageTranslations(clonedLanguageTranslations);
    } else {
      const updatedOptionFile = _.cloneDeep(values?.optionFile);
      updatedOptionFile[index] = emptyImage;
      setValues((prev) => ({ ...prev, optionFile: updatedOptionFile }));
    }
  };

  const addOption = (option) => {
    optionLabel.push("Option " + (optionLabel.length + 1));
    const updatedMultiCurrectOption = _.cloneDeep(multiCurrectOption);
    updatedMultiCurrectOption.push(option ? option?.correctValue : false);
    setMultiCurrectOption(updatedMultiCurrectOption);
  };

  const onClickAddAnswer = () => {
    setOptionCount(optionCount + 1);
    addOption();
  };

  const onClickMinusIcon = (index) => {
    dispatch(
      setConfirmationMode({ mode: CONFIRMATION_MODES.deleteAnswers, index })
    );
  };

  const handleConfirmDeleteAnswer = () => {
    if (!isLangTranslator) {
      setOptionCount(optionCount - 1);
      optionLabel.pop();
      setMultiCurrectOption(
        multiCurrectOption?.filter((_, i) => i !== confirmationMode?.index)
      );
      let updatedAnswer = values?.answer?.filter(
        (_, i) => i !== confirmationMode?.index
      );
      setValues((prev) => ({ ...prev, answer: updatedAnswer }));
    }
    dispatch(setConfirmationMode({ mode: "", index: "" }));
  };

  const onClickCheckboxHandler = (index) => {
    const updatedMultiCurrectOption = _.cloneDeep(multiCurrectOption);
    updatedMultiCurrectOption[index] = !updatedMultiCurrectOption[index];
    setMultiCurrectOption(updatedMultiCurrectOption);
  };

  const onClickViewHandler = () => {
    onClickViewHandlerUtil(dispatch, values, passageList);
  };

  const setOptionsForSelectedQuestion = (question) => {
    const size = question?.answerOptions?.length;
    setOptionCount(size);
    const dummyOptionLabel = Array.apply(0, new Array(size)).map((i, j) => {
      return `Option  ${j + 1}`;
    });
    const dummyMultiCorrectOptions = question?.answerOptions?.map(
      (option) => option?.correctValue
    );
    setOptionLabel(dummyOptionLabel);
    setMultiCurrectOption(dummyMultiCorrectOptions);
  };

  const onClickAddPassageHandler = () => {
    values?.language
      ? dispatch(setPassageFlyoutMode("Add"))
      : notifyError("Please select language first to add new group question");
  };

  const onLanguageChangeHandler = (languageData, languageIndex, e) => {
    let translations = _.cloneDeep(languageTranslations);
    translations[languageIndex]["language"] = e?.target?.value;
    setLanguageTranslations([...translations]);
    let clonedSeletedLangArray = _.cloneDeep(selectedLangArray);
    clonedSeletedLangArray[languageIndex] = e?.target?.value;
    setSelectedLangArray(clonedSeletedLangArray);
  };

  const getLanguageOptions = (languageIndex) =>
    languageOptions?.filter(
      (lang) =>
        lang !== values?.language &&
        !selectedLangArray
          .filter((_, index) => languageIndex !== index)
          .includes(lang)
    );

  const backHanlder = () => {
    if (showConfirmExit || !dataChanged) {
      if (isLangTranslator)
        history.push({
          pathname: ROUTES_CONSTANTS?.translateQuestionsList,
          state: {
            questionSecureRequestId: questionSecureRequestId,
            showRequestList: "forTranslator",
            title: "Translate Questions List",
          },
        });
      else {
        history.goBack();
      }
    } else if (dataChanged) {
      setShowConfirmExit(true);
    }
  };

  const confirmHandler = () => {
    if (showConfirmClear) {
      clearQuestionData();
    } else if (showConfirmExit) {
      backHanlder();
    } else if (confirmationMode?.mode === CONFIRMATION_MODES.deleteAnswers) {
      handleConfirmDeleteAnswer();
    }
  };

  const onCancel = () => {
    if (showConfirmClear) {
      setShowConfirmClear(false);
    } else if (showConfirmExit) {
      setShowConfirmExit(false);
    } else if (confirmationMode?.mode === CONFIRMATION_MODES.deleteAnswers) {
      dispatch(setConfirmationMode({ mode: "", index: "" }));
    }
  };

  return (
    <>
      <AddSecretKey
        secretKeyModal={addSecretKeyModal}
        secretKeyModalClose={addSecretKeyModalClose}
      />
      <NavBar
        isNotificationShow={true}
        showHome={true}
        isBack={true}
        title={
          isLangTranslator
            ? "Add Translation"
            : manageQuestionFlyoutMode === "Edit"
            ? "Edit Question"
            : "Add Question"
        }
        dirtyFieldData={{ question: true }}
        onBackHandler={backHanlder}
      />
      {isPreviewModalOpen ? (
        <QuestionAnswerPreviewModal
          isPreviewModalOpen={isPreviewModalOpen}
          onChangePreviewVisibility={onChangePreviewVisibility}
          previewQuestionList={previewQuestionsData}
        />
      ) : null}
      {loader ||
      loadingStatus === AppConstants?.LOADING_STATUS.SYNC_INPROGRESS ? (
        <Loader />
      ) : (
        <AddQuestionContainer
          selectedQuestion={selectedQuestion}
          questionId={questionId}
          values={values}
          setValues={setValues}
          errors={errors}
          isLangTranslator={isLangTranslator}
          handleOnSelection={handleOnSelection}
          subjectWiseCategoryOptions={subjectWiseCategoryOptions}
          levelsOptions={levelsOptions}
          languageOptions={languageOptions}
          passageOptions={passageOptions}
          handleOnChange={handleOnChange}
          tinyEditorHandler={tinyEditorHandler}
          roleName={roleName}
          handleOnClearImageUpload={handleOnClearImageUpload}
          handleOnRadioChange={handleOnRadioChange}
          languageTranslations={languageTranslations}
          isLangReviewer={isLangReviewer}
          getLanguageOptions={getLanguageOptions}
          onLanguageChangeHandler={onLanguageChangeHandler}
          handleOnClearOptionImageUpload={handleOnClearOptionImageUpload}
          optionLabel={optionLabel}
          multiCurrectOption={multiCurrectOption}
          onClickCheckboxHandler={onClickCheckboxHandler}
          answerOptionNames={answerOptionNames}
          currectOption={currectOption}
          setCurrectOption={setCurrectOption}
          onClickMinusIcon={onClickMinusIcon}
          dirty={dirty}
          optionCount={optionCount}
          onClickAddAnswer={onClickAddAnswer}
          subjectsOptions={subjectsOptions}
          typesOptions={typesOptions}
          onClickAddPassageHandler={onClickAddPassageHandler}
          onClickViewHandler={onClickViewHandler}
          selectedLangArray={selectedLangArray}
          setSelectedLangArray={setSelectedLangArray}
          setLanguageTranslations={setLanguageTranslations}
          isEditMode={manageQuestionFlyoutMode === "Edit"}
          currentPassage={currentPassage}
        />
      )}
      {!loader ||
      loadingStatus !== AppConstants.LOADING_STATUS.SYNC_INPROGRESS ? (
        <AddQuestionFooter
          openPreviewPopup={openPreviewPopup}
          clearQuestionData={clearQuestionData}
          handleOnSubmit={handleOnSubmit}
          loading={loading}
          isLangTranslator={isLangTranslator}
          submitBtnRef={submitBtnRef}
        />
      ) : null}
      {showPassageModal === "Add" || showPassageModal === "Edit" ? (
        <AddEditPassage
          heading={
            isLangTranslator
              ? selectedQuestion?.isGroupPassage
                ? "Yes"
                : "No"
              : `${showPassageModal} Group Question Passage`
          }
          isLangTranslator={isLangTranslator}
          mode={showPassageModal}
        />
      ) : null}
      {showPassageModal === "View" ? <ViewPassage show={true} /> : null}
      <ConfirmationModal
        show={
          showConfirmClear ||
          showConfirmExit ||
          confirmationMode?.mode === CONFIRMATION_MODES.deleteAnswers
        }
        title={
          showConfirmClear
            ? Strings.CONFIRM_CLEAR_MSG
            : showConfirmExit
            ? Strings.CONFIRM_EXIT_MSG
            : confirmationMode?.mode === CONFIRMATION_MODES.deleteAnswers
            ? Strings.CONFIRM_DELETE_MODE
            : ""
        }
        btnText={BtnTitles.CONFIRM}
        confirmHandler={confirmHandler}
        onCancel={onCancel}
      />
    </>
  );
};

export default AddQuestion;
