import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import Image from "components/widgets/image/Image";
import "./QuestionsDetails.css";
import AnswerOptions from "components/widgets/answer-options/AnswerOptions";
import AnswerOptionsTypes from "components/widgets/answer-options-types/AnswerOptionsTypes";

const QuestionDetailContainer = (props) => {
  const {
    showHistory,
    selectedData,
    languagesOptionsList,
    onlanguageChange,
    selectedLanguage,
    languageWiseData,
  } = props;

  return (
    <>
      {!showHistory ? (
        <div className="mx-5">
          <div>
            <DropDownField
              placeholder="Select language"
              className="preview_modal_dropdown"
              options={languagesOptionsList}
              onChangeHandler={onlanguageChange}
              data={selectedLanguage}
            />
          </div>
          {selectedData?.isGroupPassage ? (
            <div className="fs-12 my-3 passage-container">
              <p
                className="word-break-all"
                dangerouslySetInnerHTML={{
                  __html: selectedData?.groupPassage?.passage,
                }}
              />
              {selectedData?.groupPassage?.image?.value ? (
                <img
                  src={selectedData?.groupPassage?.image?.value}
                  alt="question"
                />
              ) : null}
            </div>
          ) : null}
          <div className="question-listing-header mt-3 ">
            Questions {selectedLanguage}
          </div>
          <div className="fs-12 my-3">
            <span
              className="word-break-all"
              dangerouslySetInnerHTML={{
                __html: languageWiseData[selectedLanguage]?.questionDescription,
              }}
            ></span>
          </div>
          <div className="fs-12 my-3">
            {selectedData?.questionImage?.value ? (
              <Image
                className="d-flex justify-content-start mt-2 mb-3"
                url={selectedData?.questionImage?.value}
                urlOnly={true}
                alt="photo"
                width="30%"
              />
            ) : null}
          </div>
          <AnswerOptions data={selectedData} />
          {selectedData?.type !== "TB" ? (
            <span className="qdetail-answer">ANSWER OPTIONS</span>
          ) : null}
          {selectedLanguage ? (
            <AnswerOptionsTypes data={selectedData} type={selectedData?.type} />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
export default QuestionDetailContainer;
