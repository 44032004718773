import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import AppConstants from "utils/constants/app-constants";
import { addQuestionActions } from "./add-question-actions";
import {
  addQuestionApi,
  editQuestionApi,
  fetchAllMastersListApi,
  fetchCategoriesApi,
  fetchDifficultyLevelsApi,
  fetchLanguagesApi,
  fetchSubjectsApi,
  fetchTypesApi,
  getAuhorListById,
  getQuestionCreationStatusListData,
  getQuestionHistoryById,
  languageTranslationApi,
} from "./add-question-api";

export const addQuestionAdapter = createEntityAdapter();

export const setSelectedLanguage = createAction(addQuestionActions.LANGUAGE);

export const fetchLanguages = createAsyncThunk(
  addQuestionActions.FETCH_ALL_LANGUAGE,
  async (options) => {
    const response = await fetchLanguagesApi(options);
    return response;
  }
);

export const fetchAllMastersList = createAsyncThunk(
  addQuestionActions.FETCH_ALL_MASTERS_LIST,
  async (options) => {
    const response = await fetchAllMastersListApi(options);
    return response;
  }
);

export const fetchSubjects = createAsyncThunk(
  addQuestionActions.FETCH_ALL_SUBJECT,
  async (options) => {
    const response = await fetchSubjectsApi(options);
    return response;
  }
);

export const fetchCategories = createAsyncThunk(
  addQuestionActions.FETCH_ALL_CATEGORY,
  async (options) => {
    const response = await fetchCategoriesApi(options);
    return response;
  }
);

export const fetchTypes = createAsyncThunk(
  addQuestionActions.FETCH_ALL_TYPE,
  async (options) => {
    const response = await fetchTypesApi(options);
    return response;
  }
);

export const fetchDifficultyLevels = createAsyncThunk(
  addQuestionActions.FETCH_ALL_DIFFICULTY_LEVEL,
  async (options) => {
    const response = await fetchDifficultyLevelsApi(options);
    return response;
  }
);

export const addQuestion = createAsyncThunk(
  addQuestionActions.ADD_QUESTION,
  async (options) => {
    const response = await addQuestionApi(options);
    return response;
  }
);

export const editQuestion = createAsyncThunk(
  addQuestionActions.EDIT_QUESTION,
  async (options) => {
    const response = await editQuestionApi(options);
    return response;
  }
);

export const getQuestionCreationHistory = createAsyncThunk(
  addQuestionActions.FETCH_HISTORY_QUESTION,
  async (options) => {
    const response = await getQuestionHistoryById(options);
    return response;
  }
);

export const fetchAuthorList = createAsyncThunk(
  addQuestionActions.FETCH_AUTHOR_LIST,
  async (options) => {
    const response = await getAuhorListById(options);
    return response;
  }
);

export const fetchQuestionCreationStatusList = createAsyncThunk(
  addQuestionActions.FETCH_QUESTIONSTATUS_LIST,
  async (options) => {
    const response = await getQuestionCreationStatusListData(options);
    return response;
  }
);

export const languageTranslation = createAsyncThunk(
  addQuestionActions.LANGUAGE_TRANSLATION,
  async (options) => {
    const response = await languageTranslationApi(options);
    return response;
  }
);

export const initialAddQuestionState = addQuestionAdapter.getInitialState({
  loadingStatus: "not loaded",
  error: null,
  isLoading: false,
  language: "",
  languageList: [],
  subjectsList: [],
  categoriesList: [],
  subjectCategoriesList: [],
  typesList: [],
  levelsList: [],
  eventHistoryList: [],
  authorList: [],
  questionCreationStatusList: {},
  allMastersList: {},
});

export const addQuestionSlice = createSlice({
  name: addQuestionActions.FEATURE_KEY,
  initialState: initialAddQuestionState,
  reducers: {
    add: addQuestionAdapter.addOne,
    remove: addQuestionAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedLanguage, (state, action) => {
        state.language = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchLanguages.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.languageList = action?.payload?.data;
      })
      .addCase(fetchLanguages.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchSubjects.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchSubjects.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.subjectsList = action?.payload?.data;
      })
      .addCase(fetchSubjects.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.categoriesList = action?.payload?.data;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchTypes.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.typesList = action?.payload?.data;
      })
      .addCase(fetchTypes.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchDifficultyLevels.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchDifficultyLevels.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.levelsList = action?.payload?.data;
      })
      .addCase(fetchDifficultyLevels.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(addQuestion.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(addQuestion.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(editQuestion.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(editQuestion.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(getQuestionCreationHistory.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(getQuestionCreationHistory.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.eventHistoryList = action?.payload?.data;
      })
      .addCase(getQuestionCreationHistory.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchAuthorList.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchAuthorList.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.authorList = action?.payload?.data;
      })
      .addCase(fetchAuthorList.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchQuestionCreationStatusList.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchQuestionCreationStatusList.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        state.questionCreationStatusList = action?.payload?.data;
      })
      .addCase(fetchQuestionCreationStatusList.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(fetchAllMastersList.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(fetchAllMastersList.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
        const all = action?.payload?.data;
        state.allMastersList = all;
        state.levelsList = all?.difficultyLevel;
        state.subjectCategoriesList = all?.subjectCateogry;
        state.typesList = all?.questionType;
        state.questionCreationStatusList = all?.questionStatus;
      })
      .addCase(fetchAllMastersList.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
      .addCase(languageTranslation.pending, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADING;
      })
      .addCase(languageTranslation.fulfilled, (state, action) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.LOADED;
      })
      .addCase(languageTranslation.rejected, (state) => {
        state.loadingStatus = AppConstants.LOADING_STATUS.ERROR;
      })
  },
});

export const addQuestionReducer = addQuestionSlice.reducer;
export const addQuestionAction = addQuestionSlice.actions;

export const getAddQuestionState = (rootState) =>
  rootState[addQuestionActions.FEATURE_KEY];

export const getSelectedLanguage = createSelector(
  getAddQuestionState,
  (state) => state.language
);

export const getAllLanguages = createSelector(
  getAddQuestionState,
  (state) => state.languageList
);

export const getAllSubjects = createSelector(
  getAddQuestionState,
  (state) => state.subjectsList
);

export const getAllCategories = createSelector(
  getAddQuestionState,
  (state) => state.categoriesList
);

export const getAllTypes = createSelector(
  getAddQuestionState,
  (state) => state.typesList
);

export const getAllLevels = createSelector(
  getAddQuestionState,
  (state) => state.levelsList
);

export const getEventHistoryData = createSelector(
  getAddQuestionState,
  (state) => state.eventHistoryList
);

export const getAuthorList = createSelector(
  getAddQuestionState,
  (state) => state.authorList
);

export const getQuestionStatusList = createSelector(
  getAddQuestionState,
  (state) => state.questionCreationStatusList
);

export const getAllMastersList = createSelector(
  getAddQuestionState,
  (state) => state.allMastersList
);

export const getQuestionLoader = createSelector(
  getAddQuestionState,
  (state) => state?.loadingStatus
)