import { useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  readAllNotification,
  readSingleNofication,
  setClearNotification,
  setNotificationData,
} from "redux/profile/profile-slice";
import { notifySuccess } from "utils/utility";
import {
  NOTIFICATION_TABS,
  notificationStaticLabels,
  successStatusCodes,
} from "utils/constants/common";
import Loader from "react-spinners/ClipLoader";

const NotificationList = ({
  isLoading,
  notificationList,
  containerRef,
  fetchNotificationData,
  handleOnClickOutside,
  page,
  setPage,
  shouldFetchNextPage,
}) => {
  const [activeTab, setActiveTab] = useState({ name: "All", id: 2 });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleScroll = () => {
    const container = containerRef?.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 20;
      if (isBottom && shouldFetchNextPage) {
        setPage(page + 1);
      }
    }
  };

  const handleOnRead = (data) => {
    if (!data?.isRead) {
      dispatch(readSingleNofication(data?._id)).then((res) => {
        if (
          res?.payload?.statusCode &&
          successStatusCodes?.includes(res?.payload?.statusCode)
        ) {
          notifySuccess(res?.payload?.message);
          let dataToModifyAll = _.cloneDeep(notificationList?.all);
          let dataToModifyUnread = _.cloneDeep(notificationList?.unRead);
          const indexToRemove = dataToModifyUnread?.findIndex(
            (obj) => obj?._id === data?._id
          );
          const indexToModify = dataToModifyAll?.findIndex(
            (obj) => obj?._id === data?._id
          );
          if (indexToRemove > -1) {
            dataToModifyUnread?.splice(indexToRemove, 1);
          }
          if (indexToModify > -1) {
            dataToModifyAll[indexToModify].isRead = true;
          }
          let finalTotalCount = notificationList?.totalCount - 1;
          if (finalTotalCount < 0) {
            finalTotalCount = 0;
          }
          dispatch(
            setNotificationData({
              all: dataToModifyAll,
              unRead: dataToModifyUnread,
              totalCount: finalTotalCount,
            })
          );
        }
      });
    } else {
      let finalTotalCount = notificationList?.totalCount - 1;
      if (finalTotalCount < 0) {
        finalTotalCount = 0;
      }
      dispatch(
        setNotificationData({
          ...notificationList,
          all: [],
          unRead: [],
          totalCount: finalTotalCount,
          clearNotification: true,
        })
      );
    }
    if (data?.redirectTo?.length > 1) {
      dispatch(setClearNotification(true));
      history?.push(data?.redirectTo);
    }
  };

  const Tab = ({ name, isSelected, clickHandler }) => {
    return (
      <div
        className={`notification-tab-content ${
          isSelected ? "notification-active-tab-content" : ""
        }`}
        onClick={clickHandler}
      >
        {name}
      </div>
    );
  };

  const markAllReadHandler = () => {
    dispatch(readAllNotification()).then((res) => {
      if (
        res?.payload?.statusCode &&
        successStatusCodes.includes(res?.payload?.statusCode)
      ) {
        fetchNotificationData(true);
        handleOnClickOutside();
      }
    });
  };

  const tabChangeHandler = (tab) => {
    setActiveTab(tab);
  };

  const renderSingleNotification = (ele, index) => {
    return (
      <div
        key={"notification-id-" + ele?.id}
        className={`notification-list-child ${
          !ele?.isRead ? " notification-list-child-unread" : ""
        } ${
          index !== notificationList?.all?.length - 1
            ? " notification-list-border"
            : ""
        }`}
      >
        <div
          className="notification-list-childs"
          onClick={() => handleOnRead(ele)}
        >
          <div>
            <span className="notification-list-child-header">
              {ele?.title ?? "-"}
            </span>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <span className="notification-list-child-span">
                {ele?.body ?? "-"}
              </span>
              <span className="notification-list-child-span">
                {moment(ele?.createdAt).format("DD MMM YYYY HH:mm")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="notification-list">
        <div className="notification-list-header">
          <div>
            <span className="notification-list-header-span">
              {notificationStaticLabels?.notifications}
            </span>
          </div>
          {notificationList?.unRead?.length > 0 ? (
            <div onClick={markAllReadHandler}>
              <span className="notification-list-header-mark-span">
                {notificationStaticLabels?.markAllRead}
              </span>
            </div>
          ) : null}
        </div>
        <div className="notification-tab-container">
          {NOTIFICATION_TABS?.length > 0
            ? NOTIFICATION_TABS?.map((ele) => {
                return (
                  <Tab
                    key={ele.id}
                    name={
                      ele?.name === "Unread"
                        ? ele?.name +
                          " (" +
                          notificationList?.unRead?.length +
                          ")"
                        : ele?.name
                    }
                    isSelected={activeTab?.id === ele?.id}
                    clickHandler={() => tabChangeHandler(ele)}
                  />
                );
              })
            : null}
        </div>
        {notificationList?.all?.length > 0 ? (
          <div
            className="notification-list-parent"
            ref={containerRef}
            onScroll={handleScroll}
          >
            {activeTab?.id === 1 ? (
              notificationList?.unRead?.length === 0 ? (
                <div className="p-2">
                  {notificationStaticLabels?.noUnreadLabel}
                </div>
              ) : (
                notificationList?.unRead?.map((notification, index) =>
                  renderSingleNotification(notification, index)
                )
              )
            ) : (
              notificationList?.all?.map((notification, index) =>
                renderSingleNotification(notification, index)
              )
            )}
          </div>
        ) : (
          <div className="notification-list-parent p-2">
            {notificationStaticLabels?.noNotificationFound}
          </div>
        )}
        {isLoading ? (
          <div className="notification-list-loader">
            <Loader />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NotificationList;
