import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { loginAction } from "./login/login-actions";
import { loginReducer } from "./login/login-slice";
import { manageQuestionActions } from "./manage-question/manage-question-actions";
import { manageQuestionReducer } from "./manage-question/manage-question-slice";
import { profileAction } from "./profile/profile-actions";
import { profileReducer } from "./profile/profile-slice";
import { scretKeyPopupReducer } from "./sceret-key-popup-visibility/secret-key-popup-visibility-slice";
import { secretKeyPopupActions } from "./sceret-key-popup-visibility/secret-key-popup-visibility-actions";
import { passageActions } from "./passage/passage-actions";
import { passageReducer } from "./passage/passage-slice";
import { addQuestionActions } from "./add-question/add-question-actions";
import { addQuestionReducer } from "./add-question/add-question-slice";

let middleware = [];
middleware = [thunkMiddleware];

export const store = configureStore({
  reducer: {
    [loginAction.MAIN]: loginReducer,
    [manageQuestionActions.FEATURE_KEY]: manageQuestionReducer,
    [profileAction.PROFILE_MAIN]: profileReducer,
    [secretKeyPopupActions.FEATURE_KEY]: scretKeyPopupReducer,
    [passageActions.FEATURE_KEY]: passageReducer,
    [addQuestionActions.FEATURE_KEY]: addQuestionReducer,
  },
  middleware,
  enhancers: [applyMiddleware(thunkMiddleware)],
  devTools: true,
});
