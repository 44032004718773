import "./Footer.css";

export default function Footer(props) {
  return (
    <div className={`btmRowHolder ${props.class ? props.class : ""}`}>
      <div className={`footerWithButtons mx-3 ${props?.className}`}>
        {props?.children}
      </div>
    </div>
  );
}
