const AppConstants = {
  LOGOUT: "/logout",
  LOADING_STATUS: {
    NOT_LOADED: "not loaded",
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error",
    SYNC_INPROGRESS: "Syncing in progress",
  },

  login: {
    GET_PUBLIC_KEY: "/api/v1/qp-authoring/author/keys",
    GET_PIN_SET: "/api/v1/qp-authoring/screen-unlock/pin-set",
    CREATE_PIN: "/api/v1/qp-authoring/screen-unlock/create",
    UPDATE_PIN: "/api/v1/qp-authoring/screen-unlock/update",
    GET_PIN: "/api/v1/qp-authoring/screen-unlock",
    FIREBASE_TOKEN: "/api/v1/user-service/firebasetoken",
    LIST: "api/v1/notify/notification/list",
    READ_SINGLE_NOTIFICATION: "api/v1/notify/notification/read",
    READ_ALL: "api/v1/notify/notification/readall",
    USER_LOGIN: "/api/v1/user-service/user/login",
    USER_LOGOUT: "/api/v1/user-service/user/logout",
  },

  profile: {
    GET_PROFILE: "/api/v1/user-service/user/profile",
    UPDATE_PROFILE: "/api/v1/user-service/user/update-profile",
    FETCH_ME: "/api/v1/user-service/me?QPProfile=true",
  },

  instances: {
    GET_ALL_INSTANCES: "/api/v1/common-service/instance/qp-instance-list",
  },

  image: {
    FILE_RENDER: "/api/v1/file-storage/render/admin/",
    ADMIN_KEY: "admin",
  },

  passage: {
    CREATE_PASSAGE: "/api/v1/qp-authoring/group-passage/create",
    UPDATE_PASSAGE: "/api/v1/qp-authoring/group-passage/update/",
    PASSAGE_LIST: "/api/v1/qp-authoring/group-passage/list",
    GET_PASSAGE: "/api/v1/qp-authoring/group-passage/get/",
    DELETE_PASSAGE: "/api/v1/qp-authoring/group-passage/delete/",
  },

  manageQuestion: {
    GET_LANGUAGES: "/api/v1/common-service/languages/list",
    GET_ALL_MASTERS_LIST: "/api/v1/qp-design/question-master/list",
    GET_SUBJECTS: "/api/v1/qp-design/question-master/subject/list",
    GET_CATEGORIES: "/api/v1/qp-design/question-master/category/list",
    GET_TYPES: "/api/v1/qp-design/question-master/type/list",
    GET_DIFFICULTY_LEVELS:
      "/api/v1/qp-design/question-master/difficulty-level/list",
    GET_AUTHORS_LIST: "/api/v1/user-service/user/qp-author-list",
    IMPORT_QUESTION: "/api/v1/qp-authoring/question-creation/import",
    CREATE_QUESTION: "/api/v1/qp-authoring/question-creation/create",
    UPDATE_QUESTION: "/api/v1/qp-authoring/question-creation/update/",
    QUESTION_DETAIL: "/api/v1/qp-authoring/question-creation/detail/",
    QUESTION_PREVIEW:
      "/api/v1/qp-authoring/question-creation/candidate/preview",
    DELETE_QUESTION: "/api/v1/qp-authoring/question-creation/delete/",
    DELETE_MANY_QUESTIONS: "/api/v1/qp-authoring/question-creation/delete-many",
    FETCH_QUESTION_LIST: "/api/v1/qp-authoring/question-creation/list",
    FETCH_QUESTION_HISTROY: "/api/v1/qp-authoring/question-creation/history/",
    FETCH_AUTHOR_LIST: "/api/v1/user-service/user/qp-author-list",
    UPDATE_STATUS: "/api/v1/qp-authoring/question-creation/status/update",
    FETCH_QUESTION_CREATION_STATUS_LIST:
      "/api/v1/qp-authoring/question-creation/status/list",
    GROUP_WISE_LISTING: "/api/v1/qp-authoring/question-creation/request/list",
    VERIFY_SECRET_KEY_API: "/api/v1/qp-authoring/question-creation/secure-key",
    LANGUAGE_TRANSLATE:
      "/api/v1/qp-authoring/question-creation/language/translate/",
  },
};

export default AppConstants;
