import { toast } from "react-toastify";
import { END_POINTS_STATICS } from "utils/constants/common";
import { ApiCaller } from "./network/Network";
import axios from "axios";
import * as forge from "node-forge";
import CryptoJS from "crypto-js";
import { passageStrings } from "./constants/constants";
import { CDN_URL_IMAGE } from "./constants/Routes/api";
const encryptionKey = 'qpa-encryption-admin';

/**
 *
 * @param {string} value
 * @param {boolean} isRequired
 */
export const is_required = (value, isRequired, error) => {
  if (Array.isArray(value)) {
    if (value.length === 0 && isRequired) return error;
  } else if (!value && isRequired) return error;
  return "";
};

export const encryptLocalData = (data, key) => {
  // Convert key to 16 bytes (128 bits)
  if(data && data !== '' && data !== "null"){
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return encrypted;
  }
  return data;
}

export const decryptLocalData = (encryptedData, key) =>  {
  if(encryptedData && encryptedData !== '' && encryptedData !== "null"){
    let bytes  = CryptoJS.AES.decrypt(encryptedData, key);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
  return encryptedData;
}

export const saveItemToStorage = (key, value, callback = null) => {
  let encryptedData = encryptLocalData(value, encryptionKey);
  localStorage.setItem(key, encryptedData);
  if (callback) callback();
};

export const getStorageItem = (key) => {
  let encryptedData = localStorage.getItem(key);
  let decryptedData = decryptLocalData(encryptedData, encryptionKey);
  return decryptedData;
};


/**
 * Determines a value if it's an object
 *
 * @param {object} value
 */
export const is_object = (value) => {
  return typeof value === "object" && value !== null;
};

/**
 *
 * @param {stateSchema} state object
 * @param {prop} property name
 */
export const get_prop_values = (stateSchema, prop) => {
  return Object.keys(stateSchema).reduce((accumulator, curr) => {
    accumulator[curr] = !prop ? false : stateSchema[curr][prop];

    return accumulator;
  }, {});
};

/**
 * Converts file to base64 format
 *
 * @param {file} file
 */
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const ToastTimer = 5000;

export const notifyError = (errorMsg) =>
  toast.error(errorMsg, {
    position: "bottom-center",
    autoClose: ToastTimer,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const notifySuccess = (message) =>
  toast.info(message, {
    position: "bottom-center",
    autoClose: ToastTimer,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const getInitials = (item) => {
  if (item?.firstName && item?.lastName) {
    return `${item?.firstName[0]?.toUpperCase()}${item?.lastName[0]?.toUpperCase()}`;
  } else if (item?.firstName) {
    return item?.firstName[0].toUpperCase();
  } else {
    return "-";
  }
};

export const END_POINTS = {
  [END_POINTS_STATICS["ADMIN_KEY"]]: {
    FILE_UPLOAD: "/api/v1/file-storage/admin/upload",

    FILE_UPLOADS: "/api/v1/file-storage/uploads",

    FILE_RENDER: "/api/v1/file-storage/render/admin/",

    EXAM_CITIES_FETCH_INSTANCE_WISE:
      "/api/v1/tenant-service/exam-city/city-list",
  },
  [END_POINTS_STATICS["CANDIDATE_KEY"]]: {
    FILE_UPLOAD: "/api/v1/file/candidate/upload",
    FILE_UPLOADS: "/api/v1/file-storage/uploads",
    FILE_RENDER: "/api/v1/file/render/candidate/",
    EXAM_CITIES_FETCH_INSTANCE_WISE:
      "/api/v1/candidate-register/candidate/exam-city/city-list",
  },
};

export const InstanceApiCaller = axios.create({
  baseURL: "https://tenant-api.cbtdev.brainvire.net",
  headers: {
    instanceid: getStorageItem("globalInstanceData")
      ? getStorageItem("globalInstanceData")?._id
      : "",
    // "ngrok-skip-browser-warning": "true",
  },
});

export async function uploadFile(
  file,
  moduleName,
  multiple = false,
  applicationName = END_POINTS_STATICS["ADMIN_KEY"],
  isInstance = false,
  newFileName = ""
) {
  let formData = new FormData();
  if (multiple) {
    file.forEach((file) => {
      formData.append("files", file);
    });
  } else {
    if (newFileName) {
      formData.append("file", file, newFileName);
    } else {
      formData.append("file", file);
    }
  }

  formData.append("moduleName", moduleName);
  let url = multiple
    ? END_POINTS[applicationName].FILE_UPLOADS
    : END_POINTS[applicationName].FILE_UPLOAD;

  let apiCaller = isInstance ? InstanceApiCaller : ApiCaller;

  return apiCaller
    .post(url, formData)
    .then((response) => {
      return response?.data?.metadata;
    })
    .catch((error) => {
      return error;
    });
}

export const countWord = (e) => {
  let content = e?.getContent({ format: "text" });
  var splits = content?.split(/(\s+)/);
  var words =
    Array.isArray(splits) && splits?.length > 0
      ? splits?.filter((x) => x?.replaceAll("&nbsp;", " ")?.trim()?.length > 0)
      : [];
  var count = words?.length;
  if (count > 1000) {
    notifyError(passageStrings.WORD_LIMIT_EXCEEDED);
  }
  return count;
};

export const countCharacters = (content) => {
  const str = content?.replaceAll("&nbsp;", " ")?.replace(/(<([^>]+)>)/gi, "");
  return str?.length;
};

export const convertListToOptions = (listArray, keyName) => {
  return Array.isArray(listArray) && listArray?.length > 0
    ? listArray?.map((level) => level[keyName])
    : [];
};

export const convertData = (listArray, key1, key2, data) => {
  return Array.isArray(listArray) &&
    listArray?.length > 0 &&
    listArray[listArray.findIndex((list) => list[key1] == data)]
    ? listArray[listArray.findIndex((list) => list[key1] == data)][key2]
    : undefined;
};

export const encryptData = (publicKey, data) => {
  // publicKey = "-----BEGIN PUBLIC KEY-----\r\nMIIEIjANBgkqhkiG9w0BAQEFAAOCBA8AMIIECgKCBAEA2b5ZU/0uEAByKu0ETBcf\r\nlhN1ntRf+q7axmHBXMrO1zoETn3ae+b16cAn+KR4dh9XSO1on9vvn6gp/9w/Jh3F\r\nTCb7JZ7wB+Z3pEhiUyFuNZozwKd9SoZ7xU8ZlvEqkMHA+9iXYAso7TsIyNmTUz0R\r\nYUx8HUchyZy0k5P996LpPQ62HzWWYXaOsg6zMBpO3Tlcnb7EujMAQ0uBNJ+IgUWC\r\ntxVkekDVQEKALO/IRSwW5ChjwuQDGBPb0oQdkxYJHmn6rO8EIE1WO6PU/CDKKzxz\r\nl+geEZa2KxotqDqxLInAxfuI33tbh5Ls5xxOhuWKrAlMCCSXVhM5DE+rlepxbWWG\r\n+bXcNu303UrrpSIQVXg69mI5A4EmPsf65YRZQ+9BO0cRKa5fdwRgk6iQUQKF8Y+s\r\nez612iNLg5tPphd7zr/GyGkzU8iPM1vUnsVYoB2SkrHV3ejCeRsu4atP5sgc6cYG\r\nhLnB3PFfjMdinOR1AG9IcPGY59ejiSOEXxBjMdcSJiYq7bBHsv4r1ZFESnvo4bj8\r\nu+6wrTjJ9oN0K5OsIWPyEIMP6pBKqRZkdSKZPmWfG6EqQrGVWrrJLr86Cd2I0/mb\r\n4qR+lkwlby494EA80hJxLgQ3fJgyXi5nj5C256oGO8HIxVcorFADf2cdcfRZxkuP\r\niFZcAfxKWQ2A9y5NBYUxp9eMgUVCLsRcwC1pPj9ipfE9aG7t2dqE78YUSL74Jzco\r\nKfrqa7HDKEyTOm/Ft1dKEjpmYakI9YdXJyj27+eSo3iroaxSXr7yFcOG+OTM/BsB\r\nrTiquQ/w7CMAMegfKUqhl/jY+f2oxHAXCNJBm/PqSgtOdT1cvMgeGCtXEBx+2ZkQ\r\nAqlJShi1DId/xrH++tVJYE1Af53hDJ5dO72Q8j3PkfIggQSZiqyAKTl9rp6pY01w\r\n0zu5q97p+93nyxbWmjwRWYhtRnvi0Hoe9V7ljNw+Fwp11yQnd7vup66hTkMSlZR5\r\n5J0qR23e7I8XjXcp0A3wxnrpPMWjZwU0O8CFBwV9tYLkL47dszoq4LduUbGYyMYx\r\nzgBFXw6ITWWOnIvmC/W9GbmFtmTD2lbYsKCuYZolqfjegJZsevo4uIHYoci6G8r0\r\nnU8a4Dzw4WWGsmwHIqxqmwhF+9iPNbEbekT3sAaE0Ob97tnnIV2CbW2ccvLbUz06\r\niNUw0Y3Wy5YQig2EZ62LZ6Upp2LZv9vIJtiI0nf+qC6jZkYDM1YwqTvHueRP97l7\r\nmM7+yycLbtgeiFcRxUoDWJxHWVQK3dTk4VfoNeqTgBaLRuwuLYhfqaeclwkBCo9L\r\nM5hUEpGjSCk8zOJkxnoJ/8V/JNAI0cWybl/hy4F+TEEdnxQHeYJ2dRHKcWSpOzqO\r\n5wIDAQAB\r\n-----END PUBLIC KEY-----\r\n"
  // const _publicKey = forge.pki.publicKeyFromPem(publicKey.replace(/\\n/g, "\n"));
  const _publicKey = forge.pki.publicKeyFromPem(publicKey);

  // console.log(publicKey);

  const encrypted = _publicKey.encrypt(
    forge.util.encodeUtf8(data),
    "RSA-OAEP",
    {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha256.create(),
      },
    }
  );
  // return encrypted.toString('base64');
  return forge.util.encode64(encrypted);
};

export const setSelectedQuestionValues = (
  setValues,
  question,
  subjectCategoriesList,
  categoriesList,
  levelsList,
  typesList,
  passageList,
  setLanguageTranslations,
  setSelectedLangArray
) => {
  setValues({
    language: question?.language,
    subject: convertData(
      subjectCategoriesList,
      "subjectCode",
      "subjectName",
      question.subject
    ),
    category: convertData(
      categoriesList,
      "categoryCode",
      "categoryName",
      question.category
    ),
    level: convertData(
      levelsList,
      "difficultyCode",
      "difficultyLevel",
      question.level
    ),
    type: convertData(typesList, "typeCode", "typeName", question.type),
    marks: question?.marks,
    negativeMark: question?.negativeMark,
    duration: question?.duration,
    groupQuestion: convertData(
      passageList,
      "_id",
      "title",
      question?.groupPassageId
    ),
    questionDescription: question?.questionDescription,
    questionImage: question?.questionImage,
    optionType:
      question?.answerOptions?.length > 0 &&
      question?.answerOptions.filter((ans) => ans?.image?.value)?.length > 0
        ? "Text & Image"
        : "Text",
    answer: question?.answerOptions?.map((ans) => ans?.optionName),
    optionFile: question?.answerOptions?.map((ans) => ans?.image),
  });
  let languageTranslateArray = [];

  if (question?.languageTranslate) {
    Object.keys(question?.languageTranslate).forEach((langKey) => {
      let languageObj = {
        language: langKey,
        details: question?.languageTranslate[langKey],
        passageDetails: {
          passage:
            question?.isGroupPassage &&
            question?.groupPassage["languageTranslate"]
              ? question?.groupPassage["languageTranslate"][langKey]?.passage
              : "",
          image:
            question?.isGroupPassage &&
            question?.groupPassage["languageTranslate"]
              ? question?.groupPassage["languageTranslate"][langKey]?.image
              : "",
        },
      };
      languageTranslateArray.push(languageObj);
    });
  }
  setLanguageTranslations(languageTranslateArray);
  setSelectedLangArray(
    languageTranslateArray?.map((translation) => translation?.language)
  );
};

export const handleError = (res, statusCode) => {
  if (res?.payload?.statusCode !== statusCode) {
    notifyError(res?.payload?.data?.message);
  }
};

export const hasAccess = (accessLevel) => {
  let accessList = getStorageItem("accessList")
    ? getStorageItem("accessList")
    : [];
  return accessList.includes(accessLevel) ? true : false;
};

export const hasListingAccess = () => {
  return hasAccess("QPRD") || hasAccess("QPAE");
};

export const hasAddEditAccess = () => {
  return hasAccess("QPAE");
};

export const hasReviewAccess = () => {
  return hasAccess("QPR");
};

export const hasTranslationAccess = () => {
  return hasAccess("QPT");
};

export const hasTranslationReviewAccess = () => {
  return hasAccess("QPL");
};

export const hasApproveAccess = () => {
  return hasAccess("QPA");
};

export const hasImportAccess = () => {
  return hasAccess("QPI");
};

export const getActionsList = (requestListValue) => {
  let actionsList = [];
  switch (requestListValue) {
    case "forReviewer":
      actionsList.push(
        {
          label: "Send For Translation",
          status: "LANGUAGE_TRANSLATOR",
          showKeyPopup: true,
          requestor: "forTranslator",
        },
        {
          label: "Reject",
          status: "REJECTED",
          showKeyPopup: true,
        }
      );
      break;
    case "forTranslator":
      actionsList.push({
        label: "Send For Translation Review",
        status: "TRANSLATOR_REVIEW",
        showKeyPopup: true,
        requestor: "forTranslatorApprover",
      });
      break;
    case "forTranslatorApprover":
      actionsList.push(
        {
          label: "Send For Approval",
          status: "PUBLISHER",
          showKeyPopup: true,
          requestor: "forPublisher",
        },
        {
          label: "Reject Translation",
          status: "LANGUAGE_REJECTED",
          showKeyPopup: true,
        }
      );
      break;
    case "forPublisher":
      actionsList.push(
        {
          label: "Approve",
          status: "PUBLISHED",
          // showKeyPopup: true,
        },
        {
          label: "Reject",
          status: "DECLINED",
          // showKeyPopup: true,
        }
      );
      break;
    default:
      actionsList.push({
        label: "Send For Review",
        status: "IN_REVIEW",
        showKeyPopup: true,
        requestor: "forReviewer",
      });
      break;
  }
  return actionsList;
};

export const getRequestListValue = (location) => {
  let requestListValue = "";
  switch (location?.pathname) {
    case "/":
    case "/question-listing":
      requestListValue = "forReviewer";
      break;
    case "/question-listing/review/questions":
    case "/question-listing/review":
      requestListValue = "forReviewer";
      break;
    case "/question-listing/translate/questions":
    case "/question-listing/translate":
      requestListValue = "forTranslator";
      break;
    case "/question-listing/translate-review/questions":
    case "/question-listing/translate-review":
      requestListValue = "forTranslatorApprover";
      break;
    case "/question-listing/approve/questions":
    case "/question-listing/approve":
      requestListValue = "forPublisher";
      break;

    default:
      break;
  }
  return requestListValue;
};

export const getDataFromLocalStorage = (listName) =>
  getStorageItem(listName) &&
  getStorageItem(listName) !== "undefined"
    ? getStorageItem(listName)
    : [];

export const filterEmptyValues = (data) => {
  let requestData = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== "" && data[key] !== undefined && data[key] !== null)
      requestData[key] = data[key];
  });
  return requestData;
};

export const aes256Encrypt = (data, key, iv) => {
  const cIv = CryptoJS.enc.Utf8.parse(iv);
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: cIv,
  });
  return cipher.toString();
};

export const aes256Decrypt = (encrypted, key, iv) => {
  const cIv = CryptoJS.enc.Utf8.parse(iv);
  const cipher = CryptoJS.AES.decrypt(encrypted, key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: cIv,
  });

  return JSON.parse(cipher.toString(CryptoJS.enc.Utf8));
};

export const imageUrlToBase64 = async (url) => {
  const data = await fetch(url);
  const blob = await data?.blob();
  return convertBase64(blob);
};

export const notifyNotification = (message, onClick) =>
  toast.info(message, {
    position: "top-right",
    autoClose: ToastTimer,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    onClick: onClick ? onClick : null,
  });

export const findValueFromList = (
  list = [],
  value,
  keyNameToMatch,
  keyValueToReturn
) => {
  const filteredData = list?.find((obj) => obj?.[keyNameToMatch] === value);
  if (filteredData?.[keyValueToReturn]?.length > 0) {
    return filteredData?.[keyValueToReturn];
  }
  return "";
};

export const replaceHostNameInUrl = (url) => {
  const originalHost = "cbt-uploaded-files.s3.ap-south-1.amazonaws.com";
  const newHost = CDN_URL_IMAGE;
  if (url?.includes(originalHost)) {
    return url?.replace(originalHost, newHost);
  }
  return url;
};

export const removeHtmlContent = (data) => {
  const regex = /(&nbsp;|<([^>]+)>)/gi;
  const body = data ? data?.toString() : "";
  const result = body?.replace(regex, "");
  return result;
};
