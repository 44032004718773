import { Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import Login from "views/login/Login";
import AddQuestion from "views/add-question/AddQuestion";
import ManageQuestions from "views/manage-questions/ManageQuestions";
import QuestionsDetails from "views/question-details/QuestionsDetails";
import Profile from "views/profile/Profile";
import ChangePassword from "views/change-password/ChangePassword";
import Logout from "views/logout/Logout";
import { requestPermission } from "firebase";
import { getStorageItem } from "utils/utility";

const MainRoutes = () => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("../firebase-messaging-sw.js", { scope: "/" })
        .then(function (registration) {
          requestPermission();
        })
        .catch(function (err) {
          console.log("Service worker Registration failed, error:", err);
        });
    }
    requestPermission();
  }, []);

  const isPinSet = () => {
    try {
      const pinSet = getStorageItem("pinSet");
      return pinSet ? pinSet : false;
    } catch (e) {
      return false;
    }
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isPinSet() ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES_CONSTANTS.myProfile} />
        )
      }
    />
  );

  return (
    <Switch>
      <Route path={ROUTES_CONSTANTS.login} exact={true} component={Login} />
      <Route path={ROUTES_CONSTANTS.logout} exact={true} component={Logout} />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.changePassword}
        exact={true}
        component={ChangePassword}
      />
      <Route
        path={ROUTES_CONSTANTS.myProfile}
        exact={true}
        component={Profile}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.addQuestion}
        component={AddQuestion}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.translateQuestion}
        component={AddQuestion}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.questionListing}
        exact={true}
        component={ManageQuestions}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.questionListing + "/:type"}
        exact={true}
        component={ManageQuestions}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.questionListing + "/:type/questions"}
        exact={true}
        component={ManageQuestions}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.home}
        exact={true}
        component={ManageQuestions}
      />
      <ProtectedRoute
        path={ROUTES_CONSTANTS.questionsDetail}
        exact={true}
        component={QuestionsDetails}
      />
    </Switch>
  );
};

export default MainRoutes;
