import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./NavActions.css";
import {
  IconEduTest,
  IconNotification,
  IconProfileHover,
  IconProfileMenuArrow,
} from "assets/svgs/Svgs";
import Wrapper from "components/widgets/wrapper/Wrapper";
import {
  fetchNotification,
  fetchUserProfile,
  getDrawerLoadingData,
  getNotificationListData,
  removeFireBaseToken,
  resetNotificationData,
  setClearNotification,
} from "redux/profile/profile-slice";
import { getInitials, getStorageItem } from "utils/utility";
import Image from "components/widgets/image/Image";
import { Badge, Popover } from "antd";
import NotificationList from "./NotificationList";
import {
  notificationDefaultParams,
  NOTIFICATION_TABS,
} from "utils/constants/common";
import { ROUTES_CONSTANTS } from "routes/route-constants";

export function NavActions(props) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [page, setPage] = useState(1);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const notificationList = useSelector(getNotificationListData);
  const isLoading = useSelector(getDrawerLoadingData);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const shouldFetchNextPage =
    notificationList?.all?.length % 10 === 0 &&
    notificationList?.all?.length > 10 * (page - 2);

  const localProfileData = getStorageItem("loggedInUserData");

  useEffect(() => {
    if (isPopUpOpen) {
      fetchNotificationData(false);
    }
  }, [page]);

  useEffect(() => {
    dispatch(setClearNotification(true));
    if (isPopUpOpen) {
      fetchNotificationData(false, true);
    } else {
      dispatch(resetNotificationData());
    }
  }, [isPopUpOpen]);

  useEffect(() => {
    fetchNotificationData(true);
    document.addEventListener("visibilitychange", () => {
      if (!document?.hidden) {
        if (getStorageItem("loggedInUserData")) {
          setProfileData(getStorageItem("loggedInUserData"));
        }
      }
    });
  }, []);

  useEffect(() => {
    setShowMenu(showDropDown);
  }, [showDropDown]);

  useEffect(() => {
    if (!getStorageItem("loggedInUserData")) {
      fetchProfileInfo();
    } else {
      setProfileData(getStorageItem("loggedInUserData"));
    }
  }, [localStorage.getItem("loggedInUserData")]);

  const handleDropDown = (e) => {
    e?.preventDefault();
    setShowDropDown(!showDropDown);
  };

  const closeDropdown = () => {
    setShowDropDown(false);
  };

  const fetchProfileInfo = () => {
    dispatch(fetchUserProfile()).then((response) => response);
  };

  const fetchNotificationData = (callDefault, togglePopup = false) => {
    if (shouldFetchNextPage || togglePopup) {
      dispatch(
        fetchNotification({
          ...notificationDefaultParams,
          page: callDefault ? 1 : page,
          filterBy: callDefault
            ? {
                isRead: false,
              }
            : undefined,
        })
      );
    }
  };

  const dropdownMenuClickhandle = async (e) => {
    e?.preventDefault();
    const clickEvent = e?.currentTarget?.getAttribute("id");
    if (clickEvent === "Logout") {
      const firebaseToken = getStorageItem("firebaseToken");
      if (firebaseToken !== "null" && firebaseToken !== null) {
        await dispatch(
          removeFireBaseToken({
            firebaseToken,
          })
        );
      }
      history.push({
        pathname: ROUTES_CONSTANTS.logout,
        state: {
          isFromLogoutButton: true,
        },
      });
    }
    if (clickEvent === "Profile") {
      history.push(ROUTES_CONSTANTS.myProfile);
    }
    if (clickEvent === "Change Password") {
      history.push(ROUTES_CONSTANTS.changePassword);
    }
  };

  const handleOnClickOutside = () => {
    setIsPopUpOpen(false);
    setPage(1);
    dispatch(setClearNotification(true));
  };

  const renderNotificationList = () => {
    return (
      <NotificationList
        {...{
          isLoading,
          notificationList,
          containerRef,
          NOTIFICATION_TABS,
          fetchNotificationData,
          handleOnClickOutside,
          page,
          setPage,
          shouldFetchNextPage,
        }}
      />
    );
  };

  const CustomPopoverContent = ({ children }) => {
    return (
      <div className="notification-custom-popover">
        <div className="custom-popover-content">{children}</div>
      </div>
    );
  };

  return (
    <div className="icnsCol d-flex">
      <div className="allIcons align-items-center">
        <div className="icon d-flex flex-column justify-content-center align-items-center">
          <Badge count={notificationList?.totalCount}>
            <Popover
              open={isPopUpOpen}
              placement="bottomRight"
              rootClassName="notification-popover-container"
              className="notification-popover-container"
              title={null}
              content={
                <CustomPopoverContent>
                  {isPopUpOpen ? renderNotificationList() : null}
                </CustomPopoverContent>
              }
              trigger="click"
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              onOpenChange={() => (isPopUpOpen ? handleOnClickOutside() : null)}
              style={{ borderRadius: "10px" }}
            >
              <span
                className="notification-bell-icon"
                onClick={() => {
                  setIsPopUpOpen(!isPopUpOpen);
                }}
              >
                <IconNotification
                  isUnread={notificationList?.unRead?.length > 0}
                />
              </span>
            </Popover>
          </Badge>
        </div>
        <Wrapper onClickOutside={closeDropdown}>
          <div className={`icon dropdown ${showMenu && "drop-hover"}`}>
            <a
              href="##"
              className="profileImgHolder dropdown-toggle"
              onClick={(e) => handleDropDown(e)}
            >
              {profileData?.avatar?.length > 0 && profileData?.avatar ? (
                <Image
                  alt="online-img"
                  url={profileData?.avatar || localProfileData?.avatar}
                  className="profile-img"
                  width="130px"
                />
              ) : (
                <span className="profile-img-initials profile-initials">
                  {profileData && getInitials(profileData)}
                </span>
              )}
              {<IconProfileHover className="profile-arrow-svg" />}
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {props.isActionDropdown || showMenu
                ? props.actionDropDownOptions.map((data, index) => {
                    return (
                      <div key={index} className="dropdown-menu-item">
                        <a
                          href="##"
                          className="dropdown-item"
                          onClick={(e) => dropdownMenuClickhandle(e)}
                          id={data}
                        >
                          <span>{data}</span>
                          <span>{<IconProfileMenuArrow />}</span>
                        </a>
                        <span className="dropdown-divider"></span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </Wrapper>
        <div className="icon">
          <a
            className="icon"
            href="https://edu-test.in/"
            target="_blank"
            rel="noreferrer"
          >
            {<IconEduTest />}
          </a>
        </div>
      </div>
    </div>
  );
}
