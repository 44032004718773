import { API_URL } from "utils/constants/Routes/api";
import AppConstants from "utils/constants/app-constants";
import { getStorageItem, replaceHostNameInUrl } from "utils/utility";

function Image(props) {
  const accessToken = getStorageItem("accessToken");
  const idtoken = getStorageItem("idToken");
  const url = replaceHostNameInUrl(props?.url);
  let defaultAppRenderUrl = AppConstants.image.FILE_RENDER;
  let appendedUrl =
    API_URL + defaultAppRenderUrl + props?.url + "?token=" + accessToken;
  if (idtoken) {
    appendedUrl += `&idtoken=${idtoken}`;
  }

  return (
    <img
      src={props?.urlOnly ? url : url?.includes("blob") ? url : appendedUrl}
      alt={props?.alt ? props?.alt : ""}
      width={props?.width ? props?.width : ""}
      height={props?.height ? props?.height : ""}
      style={props?.style ? props?.style : {}}
      className={props?.className ? props?.className : ""}
      crossOrigin={props?.urlOnly ? null : ""}
    />
  );
}

export default Image;
