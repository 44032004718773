import { IconHamburger } from "assets/svgs/Svgs";
import { useDispatch } from "react-redux";
import { openDrawer } from "redux/profile/profile-slice";

export function HamburgerMenu() {
  const dispatch = useDispatch();

  return (
    <div className="menuCol" onClick={() => dispatch(openDrawer())}>
      <a
        href="##"
        className="openCloseMenu"
        onClick={(e) => {
          e?.preventDefault();
        }}
      >
        {<IconHamburger />}
      </a>
    </div>
  );
}
