import MainRoutes from "routes";
import "./utils/styles/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Amplify } from "aws-amplify";
import awsconfig from "aws-exports";

function App() {
  Amplify.configure(awsconfig);
  return <MainRoutes />;
}

export default App;
